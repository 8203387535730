import React from "react";
import IntroduccionClub from "./secciones-club/introduccion-club";
import PlanLectura from "./secciones-club/plan-de-lecturas";
import MakeMakeVivo from "./secciones-club/makemake-en-vivo";
import ComunidadLectora from "./secciones-club/comunidad-lectora";
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";
import { useDefaultLangByCountry } from "../hooks/use-default-lang-by-country";
import { useTranslations } from "../hooks/use-translations";

/**
 *  Componente que no recibe propiedades y renderiza las secciones que conforman el club MakeMake.
 * @returns Club
 */

const Club = () => {
	const lang = useDefaultLangByCountry({ country: "us", defaultLang: "en" });
	const textosClub = useTranslations({ section: "club_makemake", lang: lang });
	return (
		<>
			<div className="container-fluid overflow-hidden px-0">
				<div className="main-title club-bg">
					<div className="container d-flex align-items-center h-100">
						<div className="col-lg-6">
							<h1 className="text-title onsecondary--color">
								{textosClub?.introduccion?.titulo?.uno || "Club"}{" "}
								<br className="d-none d-lg-block" />{" "}
								{textosClub?.introduccion?.titulo?.dos || "MakeMake"}
							</h1>
						</div>
						<div
							className="col-6 d-none d-lg-block club-img"
							style={{
								backgroundImage: `url('/img/club-makemake/back-title-act3.png')`,
							}}
						></div>
					</div>
				</div>

				<IntroduccionClub traducciones={textosClub.introduccion} />
				<PlanLectura traducciones={textosClub.plan_lecturas} />
				<MakeMakeVivo traducciones={textosClub.make_vivo} />
				<ComunidadLectora traducciones={textosClub.comunidad_lectora} />
			</div>
		</>
	);
};

export default Club;
