import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { SearchInput } from "./search-input";
import { useAdvancedSearch } from "../../hooks/advanced-search/use-advanced-search";
import { LanguageSelector } from "./language-selector";
import { Icon } from "@iconify/react";
import useObtenerSubsecciones from "../../js/translations/useObtenerSubsecciones";
import _ from "lodash";
import { store } from "../Store";

/**
 * Componente que se implementa como el header del menú de búsqueda
 * actualmente muestra el input de búsqueda, algunos botones a otras secciones y el selector de idioma.
 * @returns {React.Component}
 */
export const Header = () => {
    const advancedSearchManager = useAdvancedSearch();
    const {textosSubSecciones:textos} = useObtenerSubsecciones('busqueda_avanzada');
    const globalState = useContext(store);
    const isAuthenticadedUser = globalState?.state?.sesionActiva === 'SI';
    const hide = () => {
        advancedSearchManager.setVisible(false);
    }
    return (<section className="search-header">
        <div className="w-100 d-flex justify-content-center align-items-center">
            <button
                className="close-button"
                aria-label="Cerrar menú de búsqueda/ close search menu"
                onClick={() => {
                    advancedSearchManager.setVisible(false)
                }}>
                <Icon icon="ion:arrow-back-sharp" width={35} />
            </button>
        </div>
        <SearchInput />
        <div className="w-100 py-1  flex-wrap d-flex flex-column flex-sm-row justify-content-between">
            <div className="d-flex flex-column flex-sm-row">
                {
                    isAuthenticadedUser &&
                    <Link to={'/ayuda'} onClick={() => hide()} className="focusable-primary search-header-button">{_.get(textos, `header.boton.ayuda`) || 'Ayuda'}</Link>
                }
                <Link to={'/club-makemake'} onClick={() => hide()} className="focusable-primary search-header-button">{_.get(textos, `header.boton.club`) || 'Club MakeMake'}</Link>
                <Link to={'/blog'} onClick={() => hide()} className="focusable-primary search-header-button">{_.get(textos, `header.boton.blog`) || 'Blog'}</Link>
            </div>
            <div className="d-flex">
                <LanguageSelector />
            </div>
        </div>
    </section>)
}
