import React, { useContext, useEffect } from "react";
import useObtenerSubsecciones from "../../js/translations/useObtenerSubsecciones";
import SkeletonHomeIndividual from "../../skeletons/SkeletonHome/SkeletonHomeIndividual";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { FlipBook } from "./flip-book";
import { useHistory } from "react-router-dom";
import { ASSETS_URL, store } from "../../components/Store";
import { Book } from "../../components/shared/book";
import { useUserBooks } from "../../hooks/useUserBooks";
import { useFavoritesBooks } from "../../hooks/useFavoritesBooks";

/**
 * Componente que muestra la página de inicio del usuario individual con pocos libros.
 * @param {Object} props - Props del componente.
 * @param {Object} props.data - Datos de la página de inicio del usuario.
 * @returns {JSX.Element} Elemento JSX
 */
export const IndividualUserHome = () => {
	const userBooksData = useUserBooks();
	const { textosSubSecciones: textosHome } = useObtenerSubsecciones("home");
	const textos = textosHome?.individual || {};
	const globalState = useContext(store);
	const isAuth = globalState?.state?.sesionActiva === "SI";
	const books = Array.isArray(userBooksData?.books) ? userBooksData?.books : [];
	const loaded = userBooksData?.loadCompleted;
	const fetching = userBooksData?.loading;
	const username = globalState?.state?.username || "N/A";
	const history = useHistory();
	const user = globalState.state.username;
	const favorites = useFavoritesBooks({ userName: user });
	console.log(books);

	useEffect(() => {
		if (loaded && isAuth && books?.length <= 0) {
			history.push("/mi-panel/mi-coleccion");
		}
	}, [books, isAuth, loaded]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<section>
			{fetching ? (
				<SkeletonHomeIndividual />
			) : (
				<>
					<section className="individual-header" id="individual-home">
						<div className="individual-header-container">
							<p
								tabIndex={0}
								className="focusable-secondary individual-header-description"
							>
								{textos?.bienvenido}{" "}
								<span
									className="individual-header-course una-linea-texto d-block"
									id="individual-username"
								>
									{username}
								</span>
							</p>
							<img
								role={"presentation"}
								alt="Pesonaje Blop de bienvenido a tu biblioteca"
								className="individual-header-image"
								src={"/img/img-course.png"}
							/>
						</div>
					</section>

					<section
						tabIndex={0}
						aria-label={`libros, ${books?.length} resultados`}
						className="focusable-secondary individual-books"
					>
						<div className="container">
							<div className="row  d-flex align-items-center justify-content-center">
								{books?.map((book, i) => {
									return (
										<div
											key={i}
											className="col-9 col-md-6 col-lg-5 p-0 my-5 d-flex justify-content-center align-items-center"
										>
											<div className="w-100 h-100" style={{ maxWidth: 280 }}>
												<Book
													libro={{
														...book,
														portada: `${ASSETS_URL}${book?.portada}`,
														title: book?.title || book?.titulo,
													}}
													alSeleccionar={() => history.push(book?.webUrl)}
													ocultarOpciones={{
														checkbox: true,
														bookType: false,
														botonFavoritos: false,
													}}
													// alAgregarFav={(book) => agregarRemoverAFavoritos(book, true)}
													// alRemoverFav={(book) => agregarRemoverAFavoritos(book, false)}
													clasesPersonalizadas={{
														titulo: "individual-book-title",
													}}
													estadoInicial={{
														esFavorito: favorites.includes(
															book?.idLibro
														),
													}}
												/>
											</div>
										</div>
									);
								})}
							</div>
						</div>

						{/* <div className="row">
							<LazyLoadComponent threshold={0.5}>
								{books?.map((book, i) => {
									return (
										<div
											key={i}
											className="col"
											style={{ width: 200, height: 200 }}
										>
											<Book
												libro={{
													...book,
													portada: `${ASSETS_URL}${book?.portada}`,
													title: book?.title || book?.titulo,
												}}
												alSeleccionar={() => history.push(book?.webUrl)}
												ocultarOpciones={{
													checkbox: true,
													bookType: false,
													botonFavoritos: false,
												}}
												// alAgregarFav={(book) => agregarRemoverAFavoritos(book, true)}
												// alRemoverFav={(book) => agregarRemoverAFavoritos(book, false)}
												clasesPersonalizadas={{
													contenedor: "m-4 w-100 h-100",
												}}
												estadoInicial={{
													esFavorito: favorites.includes(book?.idLibro),
												}}
											/>
										</div>
									);
								})}
							</LazyLoadComponent>
						</div> */}
					</section>
				</>
			)}
		</section>
	);
};
