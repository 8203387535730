import { useEffect, useState } from "react"

/**
 * El hook de conexión verifica cada ciertos segundos el estado actual de la conexión a internet, devuelve una propiedad 'online' de tipo boolean que indica si hay internet o no.
 * @returns {{
 * online:boolean
 * }} - Un objeto que contiene el estado de conexión.
 * @property {boolean} online - Indica si el dispositivo está conectado a Internet.
 */
export const useConexion = ()=>{
    // const [online, setOnline] = useState(undefined);

    // useEffect(()=>{
    //     window.onload = ()=>{
    //         setOnline(navigator.onLine);
    //     }

    //     setInterval(()=>{
    //         setOnline(navigator.onLine);
    //     },1000);

    // },[])


    return {
        online:navigator.onLine
    }
}