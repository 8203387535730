import React from "react";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { Icon } from "@iconify/react";
import { SesionAPI } from "../../services/api/Sesion";
import { toast } from "react-toastify";
import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../hooks/use-translations";
/**
 * Componente de formulario de autenticación con credenciales cuando el tipo de acceeso de un insttuto es de tipo 'contraseña'
 * @param {Object} props - Las props del componente.
 * @returns {JSX.Element} Elemento JSX que representa el formulario de autenticación con credenciales.
 */
export const CredentialsAuthForm = () => {
	const lang = useDefaultLangByCountry({ country: "us", defaultLang: "en" });
	const textos = useTranslations({ section: "autenticacion_institucional", lang: lang });

	const {
		register,
		handleSubmit,
		getValues,
		watch,
		formState: { errors },
	} = useForm({ mode: "all" });
	const [sendingForm, setSendingForm] = useState(false);

	const onSubmit = async (data) => {
		if (sendingForm) {
			return;
		}
		setSendingForm(true);
		await SesionAPI.defaultSignIn({
			abortSignal: null,
			data: {
				username: data?.user,
				password: data?.password,
			},
		})
			.then((auth) => {
				if (auth === true) {
					window.location.href = "/home";
				}
			})
			.catch((error) => {
				if (error?.message?.toLowerCase().includes("suscripcion caducada")) {
					toast.error(
						textos?.iniciar_sesion?.formulario?.notificacion
							?.error_suscripcion_caducada ||
							"Suscripción caducada, comunícate con ventas para renovar el período de la suscripción o escríbenos a contacto@makemake.com.co"
					);
				} else {
					toast.error(
						textos?.iniciar_sesion?.formulario?.notificacion?.error_de_autenticacion ||
							"No pudimos iniciar la sesión. Las credenciales no son correctas o hubo un problema interno, inténtalo nuevamente."
					);
				}
			})
			.finally(() => {
				setSendingForm(false);
			});
	};

	return (
		<form
			className="col-12 col-sm-10 col-md-6 d-flex flex-column px-3 mb-3"
			onSubmit={handleSubmit(onSubmit)}
		>
			<label className="row text-center justify-content-center mb-3" htmlFor="mce-EMAIL">
				<span className="col-12 text-left onsecondary--color px-0 py-0 pb-2">
					{textos?.iniciar_sesion?.formulario?.input?.label_correo || "Correo"}
				</span>
				<input
					className="col-12 text-field form-control focusable-primary"
					placeholder={
						textos?.iniciar_sesion?.formulario?.input?.placeholder_correo ||
						"Escribe tu usuario"
					}
					{...register("user", { min: 1, required: true })}
					value={watch("user", "")?.trim()}
				/>
				{errors?.user && (
					<>
						<p className="text-left w-100 form-field-error">
							{textos?.iniciar_sesion?.formulario?.error?.campo_vacio ||
								"El campo no puede estar vacío."}
						</p>
					</>
				)}
			</label>
			<label className="row text-center justify-content-center">
				<span className="col-12 text-left onsecondary--color px-0 py-0 pb-2">
					{textos?.iniciar_sesion?.formulario?.input?.label_password || "Contraseña"}
				</span>
				<input
					type="password"
					className="col-12 text-field form-control focusable-primary"
					placeholder={
						textos?.iniciar_sesion?.formulario?.input?.placeholder_password ||
						"Escribe tu contraseña"
					}
					{...register("password", { min: 1, required: true })}
					value={watch("password", "")?.trim()}
				/>
				{errors?.password && (
					<>
						<p className="text-left w-100 form-field-error">
							{textos?.iniciar_sesion?.formulario?.error?.campo_vacio ||
								"El campo no puede estar vacío."}
						</p>
					</>
				)}
			</label>
			<div className="w-100 d-flex justify-content-center">
				<button
					className="primary-button my-4  px-5 w-100 focusable-red"
					style={{ maxWidth: 250 }}
					type="submit"
					disabled={sendingForm}
				>
					{sendingForm ? (
						<Icon icon="fa:spinner" width={30} className={`spinner-rotation`} />
					) : (
						textos?.iniciar_sesion?.formulario?.boton?.iniciar_sesion ||
						"Iniciar sesión"
					)}
				</button>
			</div>
		</form>
	);
};
