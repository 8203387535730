import React, { useState, useEffect } from "react";
import { scrollTo } from "../js/functions/scrollTo";
import { Filtro } from "./filtro";
import { SeccionLibros } from "./seccion-libros";
import { BusquedaAPI } from "../services/api/Busqueda.js";
import { CancelRequestController } from "../util/cancel-request-controller";
import { DropdownOrdenamiento } from "./dropdown-ordenamiento";
import { useImmer } from "use-immer";
import FiltrosBusqueda from "./filtros-busqueda";
import { FilterCardsContainer } from "./current-filter-buttons";
import { useUserBooks } from "../hooks/useUserBooks";

let abortController = new CancelRequestController();

/**
 * Este es el componente 'index' de Búsqueda, se encarga de realizar toda la lógica de filtrado, fetching de libros y de ordenamiento para ser plasmado en los componentes de presentación como el Filtro, entre otros. 
 * @returns 
 */
const Busqueda = () => {
  const [loading, setLoading] = useState({
    libros: false
  });
  const [libros, setLibros] = useState([]);
  const [currentFilters, setCurrentFilters] = useImmer([]);
  const [filters, setFilters] = useImmer({ ...FiltrosBusqueda });
  const [sortMethods, setSortMethods] = useState({});
  const [isFiltering, setIsFiltering] = useState(false);
  const [booksAwarded, setBooksAwarded] = useState(false);
  const [assistedBooks, setAssistedBooks] = useState(false);
  const userBooksData = useUserBooks();
  const userBooks = userBooksData?.books;

  const currentFiltersToAPIFormat = (_filters)=>{
    let result = {}
    if(Array.isArray(_filters)){
      _filters.forEach(fil=>{
        if(fil?.renderType==='checkbox-selector'){
          if(fil?.id === 'dificultadLectora'){
            result[fil?.id] = fil?.items?.map(el=>el?.value).join(';');
          }else{
            result[fil?.id] = fil?.items?.map(el=>el?.id).join(';');
          }
        }
        if(fil?.renderType==='checkbox'){
          result[fil?.id] = 1;
        }
        if(fil?.renderType==='input-text'){
          result[fil?.id] = fil?.value;
        }
      })
    }
    return result;
  }
  const search = async ()=>{
    if(abortController?.signal?.aborted){
      abortController = new CancelRequestController();
    }else{
      // abortController.abort();
    }
    setIsFiltering(true);
    scrollTo(0,0);
    setLoading({
      ...loading,
      libros:true
    })
    // console.log('filtros finales',filters)
    await BusquedaAPI
    .obtenerLibros({abortSignal:abortController.signal, data:{...currentFiltersToAPIFormat(currentFilters), ...sortMethods}})
    .then(res=>{
      setLibros(res);
      setLoading({
        ...loading,
        libros: false
      })
    })
    .catch(err => console.log(err))
  }
  
  useEffect(() => {
    if(userBooks){
      const hasAwardedBooks = userBooks?.some(book => book.hasOwnProperty('libroPremiado') && book.libroPremiado === 1);
      const hasAssistedBooks = userBooks?.some(book => book.hasOwnProperty('libroAccesible') && book.libroPremiado === 1);
      setBooksAwarded(hasAwardedBooks);
      setAssistedBooks(hasAssistedBooks);
    }
  }, [userBooks])

  useEffect(()=>{
    search()
    return ()=>{
      setLoading({
        libros:true
      })
      abortController.abort();
    }
  },[JSON.stringify({...currentFilters, ...sortMethods})])



  return (
    <>
      <div className="main-container busqueda-container" >
        <div
          className="main-content container">
          <div className="layout">
            <Filtro
              filters={filters}
              setFilters={setFilters}
              booksAwarded={booksAwarded}
              assistedBooks={assistedBooks}
              onChange={(filtros) => {
                setCurrentFilters(filtros);
              }}
              onDeleteFilters={(fil)=>{
                console.log('current delete')
                setCurrentFilters([])
                setIsFiltering(false);
              }}
              loadingData={loading.libros}
            />
            <div className="seccion-libros">
              <div className="w-100 h-100 justify-content-center align-items-center">
                <div className="d-flex justify-content-end">
                  <DropdownOrdenamiento onChange={(method) => setSortMethods(method)} />
                </div>
                {
                  isFiltering &&
                  <FilterCardsContainer 
                    filters={filters} 
                    setFilters={setFilters}
                    currentFilters={currentFilters}
                    setCurrentFilters={setCurrentFilters} />
                }
                <SeccionLibros
                  books={libros}
                  isLoading={loading.libros}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  
  )
};

export default Busqueda;
