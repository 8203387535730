
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState, useEffect, useContext } from "react"
import { NavLink, Link, useLocation } from 'react-router-dom';
import { FocusScope } from "react-aria";

import { ASSETS_URL, store } from "../Store";
import useIsMobile from './../../js/utilities/useIsMobile';
import useObtenerSubsecciones from "../../js/translations/useObtenerSubsecciones";
import { ModoPWAContext } from "../../contexts/ModoPwaContext";
import { AccesibilidadContext } from "../../contexts/AccesibilidadContext";

import { useConexion } from "../../hooks/useConexion";
import { useLogout } from "../../hooks/useLogout";

import MenuResponsive from "../menu/MenuResponsive";
import { BookDownloadingPopUp } from "../book-downloading-popup";
import { MenuAccesibilidad } from "../MenuAccesibilidad";
import { ExplorarYbuscar } from "./explorar-buscar";
import PropTypes from 'prop-types';

/**
 * Este componente renderiza el header de cliente instituciona. A diferencia del Header individual, este incluye las función de búsqueda avanzada y descarga. Su principal función es mostrar los botones correspondientes según el estado de la sesión.Este hace uso de los componentes MenuResponsive,  BookDownloadingPopUp, MenuAccesibilidad y ExplorarYbuscar.
 * @returns HeaderGeneral
 */
const HeaderGeneral = (props) => {
  const { isLogged, textosInterfaz, linkInstitucional, infoInstitucional } = props;
  const { menu, descargas, panel, cerrar_sesion, iniciar_sesion, accesibilidad, mi_cuenta, que_es, favoritos } = textosInterfaz;
  const globalState = useContext(store);
  const { state } = globalState;
  const { data } = infoInstitucional;
  const country = state?.pais?.toLowerCase();

  const [isShowMenu, setIsShowMenu] = useState(false);
  const [isLoadingMenu, setIsLoadingMenu] = useState(true);
  const [esTemaClasico, setEsTemaClasico] = useState(true);
  const refHeader = useRef(null);
  const [miCuentaMenu, setMiCuentaMenu] = useState(false);
  const [mostrarWA, setMostrarWA] = useState(false);
  const [queEs, setQueEs] = useState(false);
  const [familias, setFamilias] = useState(false);
  const [instituciones, setInstituciones] = useState(false);
  const isMobile = useIsMobile()
  const pwaState = useContext(ModoPWAContext);
  const internetConnection = useConexion();
  const location = useLocation();
  const logoutHandler = useLogout();

  const accebilidadState = useContext(AccesibilidadContext);

  const { isReady, textosSubSecciones: textosInterfazMenu } = useObtenerSubsecciones('menu');

  /**
   * Actualiza el estado de EsTemaClasico. 
   */
  useEffect(() => {
    let tema = accebilidadState?.temaActual !== 'clasico' ? false : true;
    setEsTemaClasico(tema);
  }, [accebilidadState])

  useEffect(() => {
    const queEsActivo = location.pathname === '/que-es' ? true : false;
    setQueEs(queEsActivo);

    const paraFamilias = location.pathname === '/tienda/individual' || location.pathname === '/tienda/cursos' ? true : false;
    setFamilias(paraFamilias);

    const paraInstituciones = location.pathname === '/tienda/institucional' || location.pathname === '/tienda/demo' ? true : false;
    setInstituciones(paraInstituciones);
  }, [location])

  /**
   * Esta función actualiza el estado isShowMenu para ocultar el menu responsive
   */
  const ocultarMenu = () => setIsShowMenu(false);

  /**
   * Esta función actualiza el estado de isLoadingMenu
   */
  const loadMenu = () => {
    // let img = new Image();
    // img.onload = function () {
    setIsLoadingMenu(false);
    // }
    // img.src = '/img/icons/spriteMenu.png'
  }

  //cierra las opciones de mi cuenta
  const cerrarMiCuenta = ()=> setMiCuentaMenu(false);

  /**
   * Renderiza los botones de favoritos, descargas, mi panel y cerrar sesion
   * @returns menuMiCuenta
   */
  const menuMiCuenta = () => {
    return (
      <>
        <Link
          to="/favoritos"
          className={isMobile ? "btn-responsive" : "btn-menu-cuenta"}
          onClick={() => { setMiCuentaMenu(false); setIsShowMenu(false); }}
        >
          <div className="icon-cuenta i-favoritos order-lg-2"></div>
          <span className="order-lg-1">{favoritos ? favoritos : 'Favoritos'}</span>
        </Link>
        {
          pwaState?.clienteHabilitado && pwaState?.compatible &&
          <Link
            className={`focusable-secondary ${isMobile ? "btn-responsive" : "btn-menu-cuenta"}`}
            onClick={() => { ocultarMenu(); setMiCuentaMenu(false); setIsShowMenu(false); }}
            to={"/descargados"}
          >
            <div className="icon-cuenta i-descargas order-lg-2"></div>
            <span className="order-lg-1">{descargas ? descargas : 'Descargas'}</span>

          </Link>
        }

        {state.userType === 'Cliente' &&
          <Link
            to="/mi-panel/mi-cuenta"
            className={`focusable-secondary ${isMobile ? "btn-responsive" : "btn-menu-cuenta"}`}
            onClick={() => { setMiCuentaMenu(false); setIsShowMenu(false); }}
          >
            <div className="icon-cuenta i-panel order-lg-2"></div>
            <span className="order-lg-1">{panel ? panel : 'Mi panel'}</span>
          </Link>
        }

        <hr className="d-none d-lg-block" />

        {location.pathname.includes('ezproxy') === false &&
          pwaState?.appInstalada === false &&
          <button
            tabIndex={0}
            className={`focusable-secondary ${isMobile ? "btn-responsive" : "btn-cerrar-sesion"}`}
            onClick={e => {
              e.preventDefault();
              logoutHandler.logout();
              setIsShowMenu(false);
            }}>
            {cerrar_sesion || 'Cerrar sesión'} {logoutHandler?.loading && <i className="fas fa-spinner fa-pulse"></i>}
          </button>
        }
      </>
    )
  }

  /**
   * Esta función se encarga de mostrar los botones accesibilidad y mi cuenta, en el caso de que la sesión se encuentre activa. De no estar activa la sesión, muestra los botones de planes, accesibilidad e iniciar sesión
   * @returns planAccLogin
   */
  const planAccLogin = () => {
    if (isLogged && (state.userType === 'Cliente' || state.userType === "Subcliente")) {
      return (
        <div className="d-lg-flex mx-3 mx-lg-0">
          <button
            className={`focusable-secondary ${isMobile ? "btn-responsive" : `btn-header-gris mr-3 ${mostrarWA && 'active'}`}`}
            onClick={() => {
              setIsShowMenu(false);
              setMostrarWA(!mostrarWA);
              setMiCuentaMenu(false)
            }}
          >
            <i className="fa fa-universal-access mr-2"></i>
            {accesibilidad ? accesibilidad : 'Accesibilidad'}
          </button>

          {!isMobile &&
            <>
              <button
                className={`position-relative focusable-secondary ${isMobile ? "btn-responsive" : `btn-header-cuenta d-flex align-items-center ${miCuentaMenu && 'active-cuenta'}`}`}
                onClick={() => {
                  setMiCuentaMenu(!miCuentaMenu);
                  setMostrarWA(false);
                }}
              >
                <div
                  className="mr-1 mr-lg-3 btn-avatar"
                  style={
                    { backgroundImage: `url('${isLogged ? `${ASSETS_URL}${state.datosHeader?.avatar}` : '/img/profile.png'}')` }
                  }
                ></div>
                <span>{mi_cuenta}</span>
                <i className="fas fa-chevron-down fa-sm ml-2 arrow"></i>
              </button>


              <div 
                onClick={(e) => e.target === e.currentTarget && cerrarMiCuenta()} 
                className={`wrapper-mi-cuenta ${miCuentaMenu ? 'visible' : 'hidden'}`}
              >
                <div
                  className={`menu-mi-cuenta ${miCuentaMenu ? 'visible' : 'hidden'}`}
                  aria-hidden={miCuentaMenu ? false : true}
                >
                  {menuMiCuenta()}
                </div>
              </div>
            </>

          }

          {/* <button className={`focusable-secondary ${isMobile ? "btn-responsive" : "btn-buzon ml-3"}`}>
              <div className="icon-buzon"></div>
              <p className="d-lg-none ml-2">Buzón</p>
          </button> */}
        </div>
      )
    } else {
      return (
        <div className="d-lg-flex justify-content-end w-md-100 mx-3 mx-lg-0">
          {
            location.pathname !== '/institucional/formulario' &&
            <>
            {
              country !== 'us'&&
              <Link
                to="/tienda/individual"
                onClick={() => { setIsShowMenu(false); }}
                className={`focusable-secondary px-2 una-linea-texto ${isMobile ? "btn-responsive" : `btn-header-amarillo mr-3 ${familias && 'active'}`}`}
              >
                {textosInterfaz?.familias || 'Para familias'}
              </Link>
            }
              <Link
                to="/tienda/institucional/demo"
                onClick={() => { setIsShowMenu(false);  }}
                className={`focusable-secondary px-2 una-linea-texto ${isMobile ? "btn-responsive" : `btn-header-amarillo mr-3 ${instituciones && 'active'}`}`}
              >
                {textosInterfaz?.institutos || 'Para instituciones'}
              </Link>

              <Link
                to="/que-es"
                onClick={() => { setIsShowMenu(false); }}
                className={`focusable-secondary ${isMobile ? "btn-responsive" : `btn-header-gris mr-3 ${queEs && 'active'}`}`}
              >
                {que_es ? que_es : '¿Qué es?'}
              </Link>
            </>
          }

          <button
            className={`focusable-secondary ${isMobile ? "btn-responsive" : `btn-header-gris mr-3 ${mostrarWA && 'active'}`}`}
            onClick={() => { setIsShowMenu(false); setMostrarWA(!mostrarWA); }}
          >
            <i className={`fa fa-universal-access ${isMobile && 'mr-2'}`}></i>
            { isMobile ? accesibilidad ? accesibilidad : 'Accesibilidad' : ''}
          </button>

          {
            location.pathname !== '/institucional/formulario' &&

            <Link
              className={`focusable-secondary ${isMobile ? "btn-responsive" : `btn-header-gris d-flex align-items-center ${location.pathname === '/login' && 'active'}`}`}
              to="/login"
            >
              {iniciar_sesion}
            </Link>
          }

        </div>
      )
    }
  }

  return (
    <>
      <div
        ref={refHeader}
        className={'altura-header px-3'}
        style={
          {
            backgroundColor: isLogged ? esTemaClasico ? state.datosHeader?.colorHeader : 'var(--background-primary)' : linkInstitucional ? esTemaClasico ? data?.colorHeader : 'var(--background-primary)' : 'var(----background-color)',
            color: isLogged ? esTemaClasico ? state.datosHeader?.colorLetra : 'var(--onbackground-primary)' : linkInstitucional ? esTemaClasico ? data?.colorLetra : 'var(--onbackground-primary)' : 'var(--onbackground-primary)',
            fontSize: '0.8em'
          }
        }>

        <div className="d-lg-none">
          <button
            onClick={() => {
              if (internetConnection.online) {
                if (isLoadingMenu) {
                  loadMenu();
                }
                setIsShowMenu(true)
              }
            }}
            className="btn-menu focusable-secondary p-0 py-1 p-md-2 d-flex aling-items-center "
          >
            <i className="fas fa-bars mr-2 mt-1"></i>
            {menu ? menu : 'Menú'}
          </button>
        </div>


        {!isMobile && <ExplorarYbuscar
          isLogged={isLogged}
          colorLetra={isLogged ? 
            esTemaClasico ? 
              state.datosHeader?.colorLetra 
              : 
              'var(--onbackground-primary)' 
            : 
            linkInstitucional ? 
              esTemaClasico ? 
                data?.colorLetra 
                : 
                'var(--onbackground-primary)' 
              : 
              'var(--secondary-inverse)'
            }
          onClickExplore={() => {
            setIsShowMenu(false);
            setMiCuentaMenu(false);
          }}
          onClickSearch={() => {
            setIsShowMenu(false);
            setMiCuentaMenu(false);
          }}

        />}
        <div className="d-flex justify-content-end justify-content-lg-center">
          <NavLink
            to={isLogged ? '/home' : '/'}
            onClick={() => {
              setIsShowMenu(false);
              setMiCuentaMenu(false);
            }}
            className={'focusable-secondary header-logo'}
          >
            <img 
              alt="Logo institución"
              src={isLogged && state.datosHeader?.logo != null ? `${ASSETS_URL}${state.datosHeader?.logo}` : linkInstitucional ? `${ASSETS_URL}${data?.logo}` : '/img/logo-makemake.png'} 
            />
          </NavLink>
        </div>

        <div className={`${location.pathname !== '/institucional/formulario' ? 'width-options' :'width-options2'} h-100 d-none d-lg-flex justify-content-end align-items-center`}>
          {!isMobile && planAccLogin()}
        </div>
      </div>

      <div className={`${isLogged ? "menu-accesibilidad-flotante" : "w-100"}`}>
        <MenuAccesibilidad setVisible={setMostrarWA} visible={mostrarWA} />
      </div>

      {
        state.descargando &&
        <FocusScope contain>
          <BookDownloadingPopUp />
        </FocusScope>
      }

      {
        isShowMenu &&
        <div className="close-container-menu" onClick={() => ocultarMenu()} id="close-container-menu" />
      }

      {isReady && isShowMenu && isMobile &&
        <MenuResponsive
          isShowMenu={isShowMenu}
          ocultarMenu={ocultarMenu}
          menuMiCuenta={menuMiCuenta}
          isLogged={isLogged}
          textosMenu={textosInterfazMenu}
          planAccLogin={planAccLogin}
        />
      }
    </>
  )
}

export default HeaderGeneral;

HeaderGeneral.propTypes = {
  /**
   * Booleano que indica si hay una sesion activa
   */
  isLogged: PropTypes.bool,
  /**
   * Es un objeto que trae los textos de la interfaz dependiendo del idioma activo
   */
  textosInterfaz: PropTypes.object.isRequired,
  /**
   * Booleano que indica si la url contiene un parametro inst
   */
  linkInstitucional: PropTypes.bool,
  /**
   * Objeto que contiene la información institucional
   */
  infoInstitucional: PropTypes.object
}