import React, { useEffect, useState } from "react";
import { TIENDA_MAKEMAKE_DATA } from "../data/data";
import { FichaAcademia, FeaturesList, PriceDescription, ExtraInformation } from "./FichaAcademia";

/**
 * En este componente va todo el flujo de funcionamiento para la compra de cursos.
 */
export const TiendaCursos = () => {
    const [tarjetas, setTarjetas] = useState([]);

    useEffect(() => {
        const cursos = TIENDA_MAKEMAKE_DATA.cursos;
        const misTarjetas = [];

        cursos.map((curso) => {
            misTarjetas.push(
                <div key={curso.id}>
                    <FichaAcademia
                        generalDescription={curso.descripcion}
                        nombreCurso={curso.nombre}
                        autor={curso.autor}
                        trailer={curso.trailer}
                        epaycoData={curso.epayco}
                        cardColor={curso.className}
                        image={curso.imgUrl}
                    >
                        <FeaturesList list={curso.detalles} />
                        <ExtraInformation info={curso.extraInformation} activado={curso.extraInformation.activado}/>
                        <PriceDescription price={curso.precio} descuento={curso.descuento} preventa={curso.preventa}/>
                    </FichaAcademia>
                </div>

            )
        })
        setTarjetas(misTarjetas)
    }, [])

    return (
        <div className="cursos-section py-5">
            {/* <h1 className="onbackground-primary--color">Tienda cursos</h1> */}
            <div className="container">
           
                <div className="cards-container">
                    {tarjetas}
                </div>
            </div>
        </div>
    )
}