import React, { useRef } from "react";
import { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { store } from "../../components/Store";
import { BaseSelect } from "../../components/shared/base-select";
import { Icon } from "@iconify/react";
import { useLibrarySearch } from "../hooks/use-library-search";
import { useTranslations } from "../../hooks/use-translations";
import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country";

/**
 * Componente de selección de bibliotecas, cuando el usuario selecciona una biblioteca el componente guarda en el estado global la información completa de la biblioteca seleccionada 
   para que pueda ser manipulada por otros componentes como el de autenticación (InstitutionalAuth), la información en el estado global esta asociada al objeto 'institutionalAuth' especificamente las propiedades 'institutionalAuth.isSelectedLibrary' y 'institutionalAuth.selectedLibrary'
 * @returns {JSX.Element} Elemento JSX que representa el componente de selección de bibliotecas.
 */
export const LibrarySelect = ({ onExpand, onCollapse }) => {
	const lang = useDefaultLangByCountry({ country: "us", defaultLang: "en" });
	const textos = useTranslations({ section: "autenticacion_institucional", lang: lang });

	const storeContext = useContext(store);
	const appState = storeContext?.state;
	const history = useHistory();
	const [expanded, setExpanded] = useState(false);
	const [currentSearchText, setCurrentSearchText] = useState("");
	const librarySearch = useLibrarySearch({ text: currentSearchText });
	const libraries = librarySearch?.matches;
	const fetching = appState?.institutionalAuth?.fetchCompleted ? false : true;
	const inputContainerRef = useRef(null);

	const typingHandler = ({ text }) => {
		setCurrentSearchText(text);
		setExpanded(true);
	};

	const handleSelection = (library) => {
		setExpanded(false);
		storeContext.dispatch({
			type: "CHANGE",
			path: "institutionalAuth.selectedLibrary",
			value: library,
		});
		storeContext.dispatch({
			type: "CHANGE",
			path: "institutionalAuth.isSelectedLibrary",
			value: true,
		});
		history.push("/institutional-auth/search/auth");
	};

	useEffect(() => {
		if (expanded) {
			onExpand && onExpand();
		} else {
			onCollapse && onCollapse();
		}
	}, [expanded]);

	useEffect(() => {
		// cuando se haga click en el contenedor del input, la pagina hará scroll hasta el input para que siempre este correctamente visible.
		const containerRef = inputContainerRef.current;
		if (containerRef) {
			containerRef.onclick = () => {
				const yOffset = 80;
				const elementPosition = containerRef.getBoundingClientRect().top + window.scrollY;
				window.scrollTo({
					top: elementPosition - yOffset,
				});
			};
		}
	}, [inputContainerRef.current]);

	return (
		<>
			{fetching ? (
				<Icon
					icon="fa:spinner"
					width={40}
					className={`spinner-rotation onsecondary--color`}
				/>
			) : (
				<div className="p-0 col-lg-7 library-select">
					<BaseSelect.Container expanded={expanded} setExpanded={setExpanded} zIndex={1}>
						<BaseSelect.Button>
							<div
								ref={inputContainerRef}
								className="text-field py-4 dark-input-wrapper focusable-by-children-primary form-control overflow-hidden p-0 m-0 d-flex justify-content-center align-items-center"
							>
								<div className="flex-grow-1 px-2 overflow-hidden">
									<input
										className="w-100 p-0 m-0"
										style={{ marginRight: 5 }}
										placeholder={
											textos?.busqueda?.input?.placeholder ||
											"Ingresa el nombre de tu institución"
										}
										onFocus={() => setExpanded(true)}
										onClick={() => setExpanded(true)}
										onChange={(e) => typingHandler({ text: e?.target?.value })}
									/>
								</div>

								<div className="d-flex justify-content-center align-items-center px-2 flex-grow">
									<Icon
										icon={"material-symbols:search"}
										className="search-icon"
									/>
									<Icon
										icon={"fe:arrow-down"}
										className="select-icon"
										onClick={() => setExpanded(!expanded)}
									/>
								</div>
							</div>
						</BaseSelect.Button>
						<BaseSelect.List maxHeight={"45vh"}>
							<ul className="library-list">
								<LazyLoadComponent>
									{libraries?.length <= 0 && (
										<li className="library-item">
											{textos?.busqueda?.input?.sin_resultados ||
												"No se encontraron coincidencias en la búsqueda de la institución."}
										</li>
									)}
									{libraries?.map((library, i) => {
										return (
											<li
												key={i}
												className="library-item"
												onClick={() => handleSelection(library)}
											>
												<div className="d-flex flex-column">
													<span className="institution-name ellipsis-text">
														{library?.instituto?.nombre}
													</span>
													<span className="library-name ellipsis-text">
														{library?.nombreSede}
													</span>
													<span className="library-address pt-1 ellipsis-text">
														{library?.direccionSede}
													</span>
													<span className="library-city-country ellipsis-text">
														{library?.ciudad} -{" "}
														{library?.instituto?.pais || "N/A"}{" "}
													</span>
													<hr />
												</div>
											</li>
										);
									})}
								</LazyLoadComponent>
							</ul>
						</BaseSelect.List>
					</BaseSelect.Container>
				</div>
			)}
		</>
	);
};
