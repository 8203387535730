import React, { useRef, useState } from "react";

import { CancelRequestController } from "../../../../util/cancel-request-controller";
import { useDefaultLangByCountry } from "../../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../../hooks/use-translations";
import { SolicitudesDemoAPI } from "../../../../services/api/SolicitudesDemo";

import { GENERAL_URL_API } from "../../../../components/Store";
import { Img } from "../../../../components/shared/img";

/**
 * Componente que devuelve el formulario de Brochure, de la tienda para instituciones
 * @returns {JSX.Element} Brochure
 */
export const Brochure = () => {
    const lang = useDefaultLangByCountry({ country: "us", defaultLang: "en" });
    const textos = useTranslations({ section: "tienda_makemake", lang: lang });

    const refFormulario = useRef(null);
    const refNombre = useRef(null);
    const refTelefono = useRef(null);
    const refCorreo = useRef(null);
    const refEdad = useRef(null);

    const [check, setCheck] = useState(false);
    const [notificationAge, setNotificationAge] = useState(false);
    const [preloader, setPreloader] = useState(false);

    /**
     * Esta función retorna una alerta en caso de que se ejecute un evento de tipo copiar o pegar en un campo determinado.
     * @param {Event} e
     * @returns {alert}
     */
    const alertaEvento = (e) => {
        e.preventDefault();
        return alert("Está acción no está permitida.");
    };

    /**
     * Esta función valida que la edad sea mayor a 18 años.
     * También actualiza el estado notificationAge para mostrar la
     * notificación y bloquear el botón de enviar
     */
    const validacionEdad = () => {
        let isLess = check === false ? true : false;
        setNotificationAge(isLess);
    };

    /**
     * Esta función valida que los campos del formulario esten diligenciados. De ser asi envia la información correspondiente haciendo uso del sevicio SolicitudesDemoAPI.solicitarBrochure. Si la respuesta del API es exitosa, esta función se descarga el brochure
     * @param {Event} e
     */
    const enviarFormulario = async (e) => {
        e.preventDefault();
        validacionEdad();

        if (refFormulario.current.reportValidity()) {
            setPreloader(true);
            let abortController = new CancelRequestController();

            const propiedades = {
                nombreP: refNombre.current.value,
                telefonoP: refTelefono.current.value,
                correoP: refCorreo.current.value,
                edadP: check ? "Mayor de edad" : "N/A",
            };

            await SolicitudesDemoAPI.solicitarBrochure({
                abortSignal: abortController.signal,
                dataForm: propiedades,
            })
                .then((response) => {
                    const data = response;
                    const error = data.status === 0;
                    if (!error) {
                        let docHref =
                            lang === "es"
                                ? "/document/brochure/school-library-mode-es.pdf"
                                : "/document/brochure/school-library-mode-en.pdf";

                        let alink = document.createElement("a");
                        alink.href = docHref;
                        alink.setAttribute("download", "school-library-mode.pdf");
                        document.body.appendChild(alink);
                        alink.click();
                        alink.remove();

                        refNombre.current.value = "";
                        refTelefono.current.value = "";
                        refCorreo.current.value = "";
                        setCheck(false);
                    } else {
                        console.log("error de datos");
                    }
                    setPreloader(false);
                })
                .catch((error) => console.log(error));

            return () => {
                abortController.abort();
            };
        }
    };

    return (
        <div className="subsection">
            <div className={`d-flex pt-4 pb-2 px-3 justify-content-end`}>
                <div>
                    <Img src="/img/tienda-makemake/icons/brochure.png" alt="icono brochure" />
                </div>
            </div>
            <div className="col-xl-10 mt-3 mx-auto">
                <p className="text-center onsecondary--color ">
                    {textos?.institucional?.brochure?.descripcion2 ||
                        "Conoce más sobre MakeMake, sus características y servicios."}
                </p>
                <p className="text-center onsecondary--color ">
                    {textos?.institucional?.brochure?.descripcion3 ||
                        "Por favor déjanos tus datos para descargar la información: "}
                </p>

                <form
                    id="contact-form"
                    name="contactoForm"
                    className="pt-3 pb-5 compra-informacion-institucional-form"
                    action={`${GENERAL_URL_API}/assets/enviarCorreoDemoNuevo`}
                    method="POST"
                    ref={refFormulario}
                >
                    <div className="pt-4">
                        <div className="mb-4">
                            {/* <p className="onbackground-primary--color">
                                <strong>
                                    {textos?.institucional?.brochure?.nombre || "Nombre completo*:"}
                                </strong>
                            </p> */}
                            <label htmlFor="inputName" className="sr-only">
                                {textos?.institucional?.brochure?.nombre || "Nombre completo*:"}
                            </label>
                            <input
                                ref={refNombre}
                                type="text"
                                className={"focusable-secondary form-control"}
                                id="inputName"
                                /* placeholder={
                                    textos?.institucional?.brochure?.nombre_placeholder ||
                                    "Escribe tu nombre y apellido aquí"
                                } */
                                placeholder={textos?.institucional?.brochure?.nombre || "Nombres y apellidos"}
                                name="nombreP"
                                required
                            />
                        </div>

                        <div className="mb-4">
                            {/* <p className="onbackground-primary--color">
                                <strong>
                                    {textos?.institucional?.brochure?.telefono || "Teléfono*:"}
                                </strong>
                            </p> */}
                            <label htmlFor="inputPhone" className="sr-only">
                                {textos?.institucional?.brochure?.telefono || "Teléfono*:"}
                            </label>
                            <input
                                ref={refTelefono}
                                type="text"
                                className={"focusable-secondary form-control"}
                                id="inputPhone"
                                /* placeholder={
                                    textos?.institucional?.brochure?.telefono_placeholder ||
                                    "Escribe tu teléfono"
                                } */
                                placeholder={textos?.institucional?.brochure?.telefono || "Teléfono"}
                                name="telefonoP"
                                required
                            />
                        </div>
                    </div>

                    <div className="mb-4">
                        {/* <p className="onbackground-primary--color">
                            <strong>
                                {textos?.institucional?.brochure?.correo ||
                                    "Correo electrónico*:"}
                            </strong>
                        </p> */}
                        <label htmlFor="inputEmail" className="sr-only">
                            {textos?.institucional?.brochure?.correo || "Correo electrónico*:"}
                        </label>
                        <input
                            onPaste={(e) => {
                                alertaEvento(e);
                            }}
                            onCopy={(e) => {
                                alertaEvento(e);
                            }}
                            className={`focusable-secondary form-control `}
                            id="inputEmail"
                            name="correoP"
                            /* placeholder={
                                textos?.institucional?.brochure?.correo_placeholder ||
                                "Escribe tu correo electrónico"
                            } */
                            placeholder={textos?.institucional?.brochure?.correo || "Correo electrónico"}
                            ref={refCorreo}
                            required
                            type="email"
                        />
                    </div>

                    <div className="row mb-4 px-3">
                        <label
                            className="d-flex align-items-center w-100 focusable-red pointer"
                            tabIndex={0}
                            role="checkbox"
                            checked={check}
                            aria-checked={check}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    setCheck(!check);
                                }
                            }}
                        >
                            <input
                                type="checkbox"
                                className="demo-checkbox"
                                ref={refEdad}
                                checked={check}
                                onChange={(e) => {
                                    setNotificationAge(false);
                                    setCheck(e?.target.checked);
                                }}
                                required
                            />
                            <i className="check"></i>
                            <p
                                className="ml-2 onsecondary--color"
                                style={{
                                    fontSize: '0.9em'
                                }}
                            >
                                {textos?.institucional?.brochure?.edad || "Soy mayor de edad"}
                            </p>
                        </label>

                        <p>
                            {notificationAge && (
                                <span className="validacion-campo-error primary-inverse--color">
                                    {textos?.institucional?.brochure?.edad_mensaje ||
                                        "Es necesario que seas mayor de edad"}
                                </span>
                            )}
                        </p>
                    </div>

                    <div className="row mt-xl-5">
                        <div className="col-lg-8 col-xl-9 my-3 my-lg-0">
                            <p
                                className="onsecondary--color"
                                style={{
                                    fontSize: '.8em'
                                }}
                            >
                                {textos?.condiciones?.al_ingresar ||
                                    "Al ingresar tus datos estás aceptando"}{" "}
                                <a
                                    className="focusable-secondary onsecondary--color"
                                    href="/terminos-y-condiciones"
                                >
                                    <u>{textos?.condiciones?.terminos ||
                                        "Términos y condiciones"}</u>
                                </a>{" "}
                                {textos?.condiciones?.y || "y"}{" "}
                                <a
                                    className="focusable-secondary onsecondary--color"
                                    href="/politica-de-privacidad"
                                >
                                    <u>{textos?.condiciones?.politica ||
                                        "Política de privacidad"}</u>
                                </a>
                            </p>
                        </div>

                        <div className="col-lg-4 col-xl-3">
                            <button
                                className="focusable-primary btn-solicitar d-block w-100"
                                type="submit"
                                id="btn-enviar-datos"
                                onClick={enviarFormulario}
                                disabled={notificationAge}
                            >
                                {preloader ? (
                                    <i className="fas fa-spinner fa-pulse"></i>
                                ) : (
                                    textos?.institucional?.brochure?.boton || "Descargar"
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}