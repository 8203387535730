import { TIENDA_MAKEMAKE_DATA } from "./data"

const MAIN_STORE_DATA = { ...TIENDA_MAKEMAKE_DATA };
const INDIVIDUAL = MAIN_STORE_DATA.individual;
const CURSOS = MAIN_STORE_DATA.cursos;

/**
 * Este arreglo copia la estructura original de los datos de la tienda y exporta un nuevo arreglo con los datos que tienen la estructura necesaria para mostrar las tarjetas de los planes.
 */

export const STORE_PLANS_CARDS_DATA = {
    individual: {
        planes: [
            {
                ...INDIVIDUAL.planes.trimestral.find(plan => plan?.id.includes('coleccion')),
            },
            {
                ...INDIVIDUAL.planes.semestral.find(plan => plan?.id.includes('favorito')),
            },
            {
                ...INDIVIDUAL.planes.semestral.find(plan => plan?.id.includes('lecturas')),
            },
            {
                ...INDIVIDUAL.planes.estatico.find(plan => plan?.id.includes('personalizado')),
            }

        ]
    },
    cursos: CURSOS
}