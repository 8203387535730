import React from "react";
import { Route, Switch } from "react-router";
import Error404 from "../../404";
import { LecturasMakeMake } from "./lecturas-makemake";
import { PlanLibroFavorito } from "./libro-favorito";
import { PlanMiColeccion } from "./mi-coleccion";
import { Planes } from "./planes";

/**
 * En este componente va todo el flujo de funcionamiento para la compra de planes del cliente individual.
 */
export const TiendaClienteIndividual = () => {
    return (<div className="individual-section">
        <Switch>
            <Route exact path="/tienda/individual" component={Planes} />
            <Route exact path="/tienda/individual/lecturas-makemake" component={LecturasMakeMake} />
            <Route exact path="/tienda/individual/libro-favorito" component={PlanLibroFavorito} />
            <Route exact path="/tienda/individual/mi-coleccion" component={PlanMiColeccion} />
            <Route component={Error404} />
        </Switch>
    </div>)
}
