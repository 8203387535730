import React from "react";
import { MEDIA_DATA } from "../data/media-data";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Mousewheel, Navigation, Pagination } from "swiper";
import { Img } from "../../components/shared/img";
import * as _ from "lodash";
import useIsMobile from "../../js/utilities/useIsMobile";
import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../hooks/use-translations";

export const MediaDataResponsive = () => {
	const lang = useDefaultLangByCountry({ country: "us", defaultLang: "en" });
	const textos = useTranslations({ section: "que_es_medios", lang: lang });
	const mediaTexts = textos?.medios;
	return (
		<Swiper
			slidesPerView={"auto"}
			watchSlidesProgress={true}
			centeredSlides={true}
			loop={true}
			autoplay={{
				delay: 5000,
				disableOnInteraction: false,
			}}
			mousewheel={{
				forceToAxis: true,
			}}
			pagination={{
				clickable: true,
				enabled: true,
				bulletActiveClass: "secondary-inverse--bg",
			}}
			modules={[Autoplay, Pagination, Navigation, Mousewheel]}
		>
			{MEDIA_DATA.map((data, i) => {
				return (
					<SwiperSlide
						key={i}
						className="d-flex justify-content-center align-items-center p-0"
						style={{ width: 300, height: 300 }}
					>
						<a
							href={data?.link || "/"}
							target="_blank"
							className="d-md-flex aling-items-center justify-content-center  p-2"
						>
							<div className="img-medio">
								<Img
									src={data?.img || "/img/no-imagen3.png"}
									className="img-fluid"
									alt={
										_.get(mediaTexts, data?.title?.translationPath) ||
										data?.title?.default ||
										"N/A"
									}
								/>
							</div>
						</a>
					</SwiperSlide>
				);
			})}
		</Swiper>
	);
};

export const MediaData = () => {
	const lang = useDefaultLangByCountry({ country: "us", defaultLang: "en" });
	const textos = useTranslations({ section: "que_es_medios", lang: lang });
	const mediaTexts = textos?.medios;
	return (
		<Swiper
			style={{ overflowX: "hidden" }}
			slidesPerView={1}
			slidesPerGroup={1}
			breakpoints={{
				526: {
					slidesPerView: 2,
				},
				1000: {
					slidesPerView: 3,
				},
				1200: {
					slidesPerView: 4,
				},
			}}
			autoplay={{
				delay: 5000,
				disableOnInteraction: false,
			}}
			mousewheel={{
				forceToAxis: true,
			}}
			pagination={{
				clickable: true,
				enabled: true,
				bulletActiveClass: "red--bg",
			}}
			modules={[Autoplay, Pagination, Navigation, Mousewheel]}
		>
			{MEDIA_DATA.map((data, i) => {
				return (
					<SwiperSlide
						key={i}
						className="d-flex justify-content-center align-items-center p-0"
						style={{ height: 300 }}
					>
						<a
							href={data?.link || "/"}
							target="_blank"
							className="d-md-flex aling-items-center justify-content-center  p-2"
						>
							<div className="img-medio">
								<Img
									src={data?.img || "/img/no-imagen3.png"}
									className="img-fluid"
									alt={
										_.get(mediaTexts, data?.title?.translationPath) ||
										data?.title?.default ||
										"N/A"
									}
								/>
							</div>
						</a>
					</SwiperSlide>
				);
			})}
		</Swiper>
	);
};

export const MakeMakeMedia = ({ background }) => {
	const isMobile = useIsMobile();
	const lang = useDefaultLangByCountry({ country: "us", defaultLang: "en" });
	const textos = useTranslations({ section: "que_es_medios", lang: lang });

	return (
		<>
			<div className={`py-5 ${background ? background : ""}`}>
				<div className="container">
					<h1 className="focusable-secondary secondary-font font-weight-bold onprimary--color">
						{textos?.medios?.titulo || "MakeMake en los medios"}
					</h1>

					{isMobile ? <MediaDataResponsive /> : <MediaData />}
				</div>
			</div>
		</>
	);
};
