import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * Componente que recibe los textos de interfaz del formulario. Este renderiza el formulario de suscripcion al boletín.
 * @returns FormularioSuscripcion
 */
const FormularioSuscripcion = (props) => {
  const { textosBlog } = props;
  const formularioBlog = useRef(null);
  const inputCorreo = useRef(null);
  const history = useHistory();

  const [notificacionCorreo, setNotificacionCorreo] = useState(false);
  return (
    <section>
      <form className="my-4 blog-mailchimp p-4" ref={formularioBlog}>
        <label className="text-center" htmlFor="mce-EMAIL">{textosBlog?.boletin?.descripcion || 'Suscríbete a nuestro boletín para recibir recomendaciones de lectura y actividades de nuestros libros.'}</label>
        <input
          ref={inputCorreo}
          type="email"
          className="email col-12 mb-1 mb-lg-2 footer-email surface--bg  onbackground-fourth--bordered focusable-red"
          placeholder={textosBlog?.boletin?.placeholder || "Escribe tu email"}
          required={true}
          id={"mce-EMAIL"}
          onChange={() => setNotificacionCorreo(false)}
        />
        <span role={"alert"} aria-live="assertive" className="notificacion-form">
          {notificacionCorreo ? textosBlog?.boletin?.alert || 'Para suscribirte en el boletín debes ingresar un correo' : ''}
        </span>
        <div className="col-10 mx-auto mb-lg-2 px-0 px-lg-1 mt-3">
          <button className="col-12 footer-subscribe btn btn-secondary focusable-red" onClick={(e) => {
            if (formularioBlog.current.checkValidity()) {
              e.preventDefault()
              history.push(`/boletin/ingresar-datos?email=${inputCorreo.current.value}`)
            } else {
              setNotificacionCorreo(true);
            }
          }}>
            {textosBlog?.botones?.suscribirse || 'Subscribirse'}
          </button>
        </div>
      </form>
    </section>

  )
}

export default FormularioSuscripcion;

FormularioSuscripcion.propTypes = {

  /**
   *  Es un objeto que trae los textos de la interfaz dependiendo del idioma activo.
   */
  textosBlog: PropTypes.object.isRequired
}