import React, { useEffect, useRef, useContext } from 'react';
import { store } from "../Store";
import { ExplorarYbuscar } from '../header/explorar-buscar';
import PropTypes from 'prop-types';

/**
 * Este componente se encarga de renderizar el menu en dispositivos como tablets y celulares. 
 */
const MenuResponsive = (props) => {
  const { isShowMenu, ocultarMenu, planAccLogin, textosMenu, menuMiCuenta, isLogged } = props;

  const globalState = useContext(store);
  const { state } = globalState;

  const { titulo } = textosMenu;
  const ref = useRef(null);
  const advancedSearchButtonRef = useRef(null);

  useEffect(() => {
    if (advancedSearchButtonRef.current) {
      globalState.dispatch({
        type: 'CHANGE',
        path: 'advancedSearch.triggerButton',
        value: advancedSearchButtonRef
      })
    }
  }, [advancedSearchButtonRef])

  useEffect(() => {
    if (isShowMenu) {
      ref.current.focus();
    }
  }, [isShowMenu])

  return (
    <nav
      role="menu"
      aria-label="Menu lateral"
      aria-expanded={isShowMenu}
      className={`focusable-secondary container-menu nuevo-menu ${isShowMenu ? 'appear-container-menu' : ''}`}
      id="container-menu"
    >
      <div className="background--bg  row justify-content-between align-items-center mx-0 py-3 px-4" id="explorar-menu">
        <h2 className={"secondary-inverse--color"}>{titulo?.explorar || 'Explorar'}</h2>
        <button
          className='focusable-secondary'
          aria-label='Cerrar menu lateral'
          ref={ref}
          tabIndex={0}
          onClick={() => ocultarMenu()}
        >
          <i className="secondary-inverse--color secondary-item fas fa-times fa-2x pointer" id="menu-close"></i>
        </button>
      </div>
      <div className="scroll-menu mt-3 ">
        <ExplorarYbuscar onClickExplore={() => ocultarMenu()} onClickSearch={() => {
          setTimeout(() => {
            ocultarMenu()
          }, 200);
        }} />

        {planAccLogin()}

        {isLogged && (state.userType === 'Cliente' || state.userType === "Subcliente") &&
          <div className='mx-3'>
            {menuMiCuenta()}
          </div>
        }

      </div>

    </nav>
  )
}

export default MenuResponsive;

MenuResponsive.propTypes = {
  /**
   * Booleano que indica si el menu debe ser visible
   */
  isShowMenu: PropTypes.bool,
  /**
   * Función que actualiza el estado isShowMenu para ocultar el menu responsive
   */
  ocultarMenu: PropTypes.func,
  /**
   * Funcion que se encarga de mostrar los botones correspondientes al header
   */
  planAccLogin: PropTypes.func,
  /*
  * Es un objeto que trae los textos de la interfaz dependiendo del idioma activo
  */
  textosMenu: PropTypes.object,
  /**
   * Función que retorna los botones de mi panel y cerrar sesión
   */
  menuMiCuenta: PropTypes.func,
  /**
   * Booleano que indica si hay una sesion activa
   */
  isLogged: PropTypes.bool
}