/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useEffect, useRef } from "react";
import { store } from "../Store";
import SkeletonHeader from "../../skeletons/skeleton-header.js";
import SkeletonHeaderSinLogin from "../../skeletons/skeleton-header-sinLoguin.js";
import { useLocation, useHistory } from "react-router-dom";
import HeaderIndividual from "./HeaderIndividual.js";
import HeaderGeneral from "./HeaderGeneral";
import { useQueryParams } from "../../hooks/useQueryParams";
import { DatosFormInstitutoAPI } from "../../services/api/LoginInstitucional";
import { CancelRequestController } from "../../util/cancel-request-controller";
import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country.js";
import { useTranslations } from "../../hooks/use-translations.js";

/**
 * Este componente renderiza el header del proyecto. Su principal función es identificar si la sesión esta activa. A partir de esto, asocia el tipo de usuario para mostrar el Header correspondiente. Este  hace uso de los componentes HeaderIndividual y HeaderGeneral
 * @returns Header
 */
const Header = () => {
	const globalState = useContext(store);
	const { dispatch, state } = globalState;
	const [isLoading, setIsLoading] = useState(true);
	/* const [height, setHeight] = useState(63); */
	const isLogged = state.sesionActiva !== null && state.sesionActiva === "SI";
	const location = useLocation();

	const [linkInstitucional, setLinkInstitucional] = useState(false);
	const [infoInstitucional, setInfoInstitucional] = useState({});

	const refHeader = useRef(null);
	const history = useHistory();

	//Hooks personalizados
	const lang = useDefaultLangByCountry({ country: "us", defaultLang: "en" });
	const textosInterfaz = useTranslations({ section: "header", lang: lang, array: true });
	const params = useQueryParams();

	useEffect(() => {
		if (state?.datosHeader) {
			setIsLoading(false);
			dispatch({ type: "CHANGE_VALUE", property: "isHeaderShow", value: true });
		}
	}, [state?.datosHeader]);

	/**
	 * Si la ruta contiene el param inst se dirige al acceso de la institución, sino se obtiene la información de dicha institución.
	 */
	useEffect(() => {
		const institucion = params.get("inst");
		let abortController = new CancelRequestController();

		if (!!institucion) {
			setLinkInstitucional(true);
			const instituto = decodeURIComponent(institucion);
			const datosInstitucionales = async () => {
				let datos = {};
				await DatosFormInstitutoAPI.obtenerDatos({
					abortSignal: abortController.signal,
					data: instituto,
				})
					.then((respuesta) => {
						if (respuesta.redirect === 1) {
							history.push(respuesta.url);
						} else {
							for (const key in respuesta) {
								const value = respuesta[key];
								datos[key] = value === "null" || value === "" ? null : value;
							}
							setInfoInstitucional({
								...datos,
								instituto: instituto,
							});
						}
					})
					.catch((error) => console.log(error));
			};
			datosInstitucionales();
		} else {
			setLinkInstitucional(false);
		}
	}, [params]);

	return (
		<>
			{isLoading && isLogged && <SkeletonHeader />}

			{!isLogged && isLoading && <SkeletonHeaderSinLogin />}

			<header ref={refHeader}>
				{state.sesionActiva === "SI" && state.cantidadLibros <= 40 && (
					<HeaderIndividual textosInterfaz={textosInterfaz} />
				)}
				{!isLoading &&
					!location.pathname.includes("/visor") &&
					(state.cantidadLibros !== "NULL"
						? state.cantidadLibros > 40
							? true
							: false
						: true) && (
						<HeaderGeneral
							isLogged={isLogged}
							textosInterfaz={textosInterfaz}
							linkInstitucional={linkInstitucional}
							infoInstitucional={infoInstitucional}
						/>
					)}
			</header>
		</>
	);
};

export default Header;
