import React from "react";
import { Img } from "../../../../components/shared/img";
import { FormDemoInstitucional } from "../../../../components/FormDemoInstitucional";

/**
 * Componente que devuelve el contenido del formulario Demo -"Solicitar información", de la tienda para instituciones
 * @returns {JSX.Element} Demo
 */
export const Demo = () => {
    return (
        <div className="subsection">
            <div className={`d-flex pt-4 pb-2 px-3 justify-content-end`}>
                <div>
                    <Img src="/img/tienda-makemake/icons/demo.png"  alt="icono demo"/>
                </div>
            </div>
            
            <FormDemoInstitucional />
        </div>
    )
}