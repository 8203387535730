import React from "react";
import { Icon } from "@iconify/react";


export const SocialMedia = () => {
  const iconSize = 30;

  return (<section className="d-flex justify-content-center align-items-center p-0 m-0 my-4">
    <ul className="row footer-icons justify-content-center justify-content-xl-start m-0">
      <li>
        <a className="focusable-secondary mr-3" aria-label="App Store link" href="https://apps.apple.com/us/app/makemake-app/id6469707768?platform=iphone" target="_blank" rel="noopener noreferrer" >
          <Icon className="social-media-button appstore" icon="fluent:app-store-24-filled" width={iconSize}/>
        </a>
      </li>
      <li>
        <a className="focusable-secondary mr-3" aria-label="Play Store link" href="https://play.google.com/store/apps/details?id=com.makemake.app&pli=1" target="_blank" rel="noopener noreferrer" >
          <Icon className="social-media-button playstore" icon="ion:logo-google-playstore" width={iconSize} />
        </a>
      </li>
      <li>
        <a className="focusable-secondary mr-3" aria-label="Facebook link" href="https://www.facebook.com/Bookmakemake/" target="_blank" rel="noopener noreferrer" >
          <Icon className="social-media-button facebook" icon="ic:baseline-facebook" width={iconSize} />
        </a>
      </li>
      <li>
        <a className="focusable-secondary mr-3" aria-label="Instagram link" href="https://www.instagram.com/makemakelibros" target="_blank" rel="noopener noreferrer" >
          <Icon className="social-media-button instagram" icon="mdi:instagram" width={iconSize} />
        </a>
      </li>
      <li>
        <a className="focusable-secondary mr-3" aria-label="Youtube link" href="https://www.youtube.com/channel/UCAGRlTJB3fUDRfkVRPVNdzQ" target="_blank" rel="noopener noreferrer" >
          <Icon className="social-media-button youtube" icon="bi:youtube" width={iconSize} />
        </a>
      </li>
      <li>
        <a className="focusable-secondary mr-3" aria-label="Youtube link" href="https://chat.whatsapp.com/DJWx58BbqL41fIFWEasgyj" target="_blank" rel="noopener noreferrer" >
          <Icon className="social-media-button whastapp" icon="ic:baseline-whatsapp" width={iconSize} />
        </a>
      </li>
    </ul>
  </section>)
}