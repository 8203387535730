import React, { useEffect, useState, useRef } from "react";
import { useContext } from "react";
import {
	AccesibilidadContext,
	FuentesDisponibles,
	TemasDisponibles,
} from "../contexts/AccesibilidadContext";

import * as PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import { FocusScope } from "react-aria";
import { useDefaultLangByCountry } from "../hooks/use-default-lang-by-country";
import { useTranslations } from "../hooks/use-translations";

/**
 * Menu que contiene la configuración de accesibilidad del sitio
 * @returns {React.Component}
 */
export const MenuAccesibilidad = ({ visible, setVisible }) => {
	const lang = useDefaultLangByCountry({ country: "us", defaultLang: "en" });
	const textosInterfaz = useTranslations({ section: "header", lang: lang, array: true });
	const accebilidadState = useContext(AccesibilidadContext);
	const [esTemaClasico, setEsTemaClasico] = useState(true);
	const InputTamanoref = useRef(null);

	/**Cada vez que se monte este componente se activara el focus en el input tamaño de fuente */
	useEffect(() => {
		if (InputTamanoref.current) {
			InputTamanoref.current.focus();
		}
	}, []);

	/*al hacer scroll se actualiza el estado de mostrar u ocultar el panel de accesibilidad */
	useEffect(() => {
		window.addEventListener("scroll", () => {
			if (window.scrollY >= 1 && window.scrollY < 10) {
				setVisible && setVisible(false);
			}
		});
	}, [visible]);

	/**Actualiza el estado temaClasico */
	useEffect(() => {
		let tema = accebilidadState?.temaActual !== "clasico" ? false : true;
		setEsTemaClasico(tema);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accebilidadState]);

	const cambiarTipoFuente = (valor) => {
		accebilidadState.actualizarFuente(valor || "predeterminado");
	};

	const cambiarTamanoFuente = (valor) => {
		accebilidadState.actualizarTamanioFuente(valor);
	};

	const cambiarTema = (valor) => {
		accebilidadState.actualizarTema(valor);
	};

	const restablecerConfiguracion = () => {
		accebilidadState.actualizarTema(TemasDisponibles.clasico.id);
		accebilidadState.actualizarTamanioFuente(20);
		accebilidadState.actualizarFuente(FuentesDisponibles.predeterminado.id);
	};

	return (
		<>
			<FocusScope contain restoreFocus autoFocus>
				<section
					className={`menu-accesibilidad ${
						visible ? "expanded" : "collapsed"
					} text-fontSize`}
				>
					<div className="item">
						<label className="label text-fontSize">
							{textosInterfaz?.tamano_fuente || "Tamaño de fuente:"}{" "}
							<strong className="numeroPx onprimary-color">
								<span>{accebilidadState?.tamanioFuente}</span>px
							</strong>
						</label>
						<input
							type="range"
							min="20"
							max="32"
							step="2"
							value={accebilidadState?.tamanioFuente}
							ref={InputTamanoref}
							aria-label={textosInterfaz?.tamano_fuente || "Tamaño de fuente:"}
							className={`focusable-red range-slider__range_settings ${
								!esTemaClasico && "onbackground-primary--bordered"
							}`}
							onChange={(e) => {
								cambiarTamanoFuente(e.currentTarget.value);
							}}
						/>
					</div>
					<div className="item">
						<label className="label text-fontSize">
							{textosInterfaz?.tipo_fuente || "Tipo de fuente:"}
						</label>
						<select
							className={`focusable-red wa-select background--bg onbackground-primary--color focusable-red ${
								!esTemaClasico && "onbackground-primary--bordered "
							}`}
							onChange={(e) => {
								cambiarTipoFuente(e.currentTarget.value);
							}}
							value={accebilidadState?.fuente}
							aria-label={textosInterfaz?.tipo_fuente || "Tipo de fuente:"}
						>
							{Object.values(FuentesDisponibles).map((fuente, index) => {
								return (
									<option
										key={`fuente${index}`}
										value={fuente?.id}
										defaultValue={fuente?.id === accebilidadState?.fuente}
									>
										{textosInterfaz?.[fuente.id]
											? textosInterfaz?.[fuente.id]
											: fuente?.nombre}
									</option>
								);
							})}
						</select>
					</div>
					<div className="item">
						<label className="label mb-0 text-fontSize">
							{textosInterfaz?.color_contraste || "Color y contraste:"}
						</label>
						<select
							value={accebilidadState?.temaActual}
							className={`focusable-red wa-select background--bg onbackground-primary--color focusable-red ${
								!esTemaClasico && "onbackground-primary--bordered "
							}`}
							onChange={(e) => {
								cambiarTema(e.currentTarget.value);
							}}
							aria-label={textosInterfaz?.color_contraste || "Color y contraste:"}
						>
							{Object.values(TemasDisponibles).map((tema, index) => {
								return (
									<option
										key={index}
										value={tema?.id}
										defaultValue={tema?.id === accebilidadState?.temaActual}
									>
										{textosInterfaz?.[tema.id]
											? textosInterfaz?.[tema.id]
											: tema?.nombre}
									</option>
								);
							})}
						</select>
					</div>
					<button
						className={`reset-button text-fontSize focusable-red ${
							!esTemaClasico && "onprimary--border"
						}`}
						onClick={() => restablecerConfiguracion()}
					>
						{textosInterfaz?.restablecer || "Restablecer"}
					</button>
					<button
						aria-label="cerrar ajustes de accesibilidad"
						className=" close-button focusable-red p-1"
						onClick={() => setVisible && setVisible(false)}
					>
						<Icon icon="mdi:close-thick" />
					</button>
				</section>
			</FocusScope>
		</>
	);
};

MenuAccesibilidad.propTypes = {
	/**
	 * Función que recibe un parametro booleano que deberá actualizar el estado de visibilidad del componenente.
	 */
	setvisible: PropTypes.func,
	/**
	 * booleano que indica si se debe mostrar o no el componente
	 */
	visible: PropTypes.bool.isRequired,
};
