export const MAKEMAKE_CLIENTS = {
    classics: [
        {
            imgUrl: '/img/editoriales2/editorial-circulo-abierto.png',
        },
        /* {
            imgUrl: '/img/instituciones/Breteau.png',
        }, */
        /* {
            imgUrl: '/img/instituciones/Javeriana.png',
        }, */
        /* {
            imgUrl: '/img/bibliotecas/Queens.png',
        }, */
        {
            imgUrl: '/img/bibliotecas/Comfenalco Valle.png',
        },
        {
            imgUrl: '/img/bibliotecas/Berkeley.png',
        },
        {
            imgUrl: '/img/bibliotecas/RBPC.png',
        },
        /* {
            imgUrl: '/img/bibliotecas/comfamiliarHuila.png',
        }, */
        /* {
            imgUrl: '/img/bibliotecas/comfama.png',
        },
        {
            imgUrl: '/img/bibliotecas/Fresno.png',
        }, */
        {
            imgUrl: '/img/bibliotecas/casaliteraturaperuana.jpg',
        },
        {
            imgUrl: '/img/bibliotecas/denver.jpg',
        },
        {
            imgUrl: '/img/bibliotecas/austin.png',
        },
        {
            imgUrl: '/img/bibliotecas/Banrep.png',
        },
        {
            imgUrl: '/img/bibliotecas/Charlotte.png',
        },
        {
            imgUrl: '/img/bibliotecas/Bibliored.png',
        },
        {
            imgUrl: '/img/bibliotecas/Boston.png',
        },
        /* {
            imgUrl: '/img/bibliotecas/cajacopi.png',
        }, */
        /* {
            imgUrl: '/img/bibliotecas/whittier.png',
        }, */
        {
            imgUrl: '/img/bibliotecas/ComfenalcoAnt.png',
        },
        {
            imgUrl: '/img/bibliotecas/ComfenalcoAtl.png',
        },
        {
            imgUrl: '/img/bibliotecas/comfenalcoQuin.png',
        },
        /* {
            imgUrl: '/img/bibliotecas/Wigberto.png',
        }, */
        {
            imgUrl: '/img/bibliotecas/Fundalectura.png',
        },
        {
            imgUrl: '/img/bibliotecas/Miami.png',
        },
        {
            imgUrl: '/img/bibliotecas/LosAngeles.png',
        },
       /*  {
            imgUrl: '/img/bibliotecas/pflugerville.png',
        }, */
        {
            imgUrl: '/img/bibliotecas/piloto.png',
        },
        {
            imgUrl: '/img/bibliotecas/LBPL.png',
        },
        {
            imgUrl: '/img/bibliotecas/Comfamiliar Risaralda.png',
        },
        {
            imgUrl: '/img/bibliotecas/epm.png',
        },
       /*  {
            imgUrl: '/img/bibliotecas/comfacauca.png',
        },
        {
            imgUrl: '/img/bibliotecas/w-j-niederkorn-library.png',
        },
        {
            imgUrl: '/img/bibliotecas/colsubsidio.png',
        },
        {
            imgUrl: '/img/bibliotecas/u-los-andes.png',
        }, */

    ],
    planLector: [

        /* { imgUrl: '/img/instituciones/alianza.png' },
        { imgUrl: '/img/instituciones/Nogales.png' },
        { imgUrl: '/img/instituciones/Campestre.png' },
        { imgUrl: '/img/instituciones/Madison.png' },
        { imgUrl: '/img/instituciones/Mercy.png' },
        { imgUrl: '/img/instituciones/Columbus.png' },
        { imgUrl: '/img/instituciones/Corazonista.png' }, */
        { imgUrl: '/img/instituciones/NuevaGranada.png' },
       /*  { imgUrl: '/img/instituciones/Jefferson.png' },
        { imgUrl: '/img/instituciones/Marymount.png' },
        { imgUrl: '/img/instituciones/portchester2.jpg' } */
    ]
}