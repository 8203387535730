import React, { useState } from "react";
import { GENERAL_URL_API, store } from "./components/Store";
import { useContext, useEffect } from "react";
import { SesionAPI } from "./services/api/Sesion";
import { useHistory, useLocation } from "react-router-dom";
import { CancelRequestController } from "./util/cancel-request-controller";
import { ClearStorage } from "./util/clear-storage";
import getAjax from "./js/utilities/getAjax";

/**
 * Componente que actúa como wrapper el cual verifica el estado de la sesión del usuario y manipula el dom dependiendo del estado, este componente es importante para la seguridad de los datos en el frontend.
 * @param {object} children
 * @returns {JSX.Element} VerificarSesion
 */
export const VerificarSesion = ({ children }) => {
	const location = useLocation();
	const history = useHistory();
	const { state, dispatch } = useContext(store);
	const [validationFinished, setValidationFinished] = useState(false);

	const loadUserData = async ({ isAuth }) => {
		getAjax(
			`${GENERAL_URL_API}/assets/datosHeader`,
			{ sesion: isAuth ? "SI" : "NO" },
			(response) => {
				const res = response !== "Error" ? JSON.parse(response) : {};
				let makemakeData = res.makemakeData || localStorage.getItem("makemakeData") || {};
				let categoriasMenu =
					res.categoriasMenu || localStorage.getItem("categoriasMenu") || {};
				window.localStorage.setItem("makemakeData", JSON.stringify(makemakeData));
				window.localStorage.setItem("categoriasMenu", JSON.stringify(categoriasMenu));
				dispatch({
					type: "CHANGE_VALUE",
					property: "pais",
					value: makemakeData.pais,
				});
				dispatch({
					type: "CHANGE_VALUE",
					property: "sesionActiva",
					value: makemakeData.sesionActiva,
				});
				dispatch({
					type: "CHANGE_VALUE",
					property: "userType",
					value: makemakeData.userType,
				});
				dispatch({
					type: "CHANGE_VALUE",
					property: "username",
					value: makemakeData.username,
				});
				dispatch({
					type: "CHANGE_VALUE",
					property: "datosHeader",
					value: makemakeData.datosHeader,
				});
				dispatch({
					type: "CHANGE_VALUE",
					property: "categoriasMenu",
					value: categoriasMenu,
				});
				dispatch({
					type: "CHANGE_VALUE",
					property: "tipo",
					value: makemakeData?.tipo,
				});
				dispatch({
					type: "CHANGE_VALUE",
					property: "linkAcceso",
					value: makemakeData?.linkAcceso,
				});
			}
		);
	};
	const verificar = async ({ abortSignal }) => {
		await SesionAPI.usuarioAutenticado({ abortSignal })
			.then((res) => {
				loadUserData({isAuth:res});
				if (res) {
					if (window.BroadcastChannel) {
						const bc = new BroadcastChannel("isAuth");
						bc.postMessage(
							{
								eventName: "isAuth",
								type: "internalAuthVerification",
								payload: true,
							},
							window.location.origin
						);
					}
				}
				if (res === false && state.sesionActiva === "SI") {
					loadUserData();
					ClearStorage();
					window.location.reload();
				}
			})
			.catch((err) => err)
			.finally(() => {
				setValidationFinished(true);
			});
	};

	useEffect(() => {
		const abortController = new CancelRequestController();
		verificar({ abortSignal: abortController.signal });
		return () => {
			abortController.abort();
		};
	}, [state.sesionActiva, location.pathname]);

	// Importante mantener este conditional rendering.
	return <>{validationFinished ? children : <></>}</>;
};
