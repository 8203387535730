import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * Este componente renderiza el recuadro con la lista de tags. Para esto recibe un arreglo, la funcion de actualizar el tag y el titulo correspondiente.
 * @returns BlogTags
 */
const BlogTags = (props) => {
  const {tags, setTagItem, tituloTag} = props;
  return (
    <section className="blog-tags p-3 p-lg-4">
      <h3 className="bold text-center mb-4 onprimary--color">{tituloTag? tituloTag :'Explora por tags'}</h3>
      {tags.map((item, index)=>{
          const {nombre, numero} = item;
          return(
            <span key={`tag${index}`}>
              <Link  
                className='focusable-red'
                to={`/blog?tags=${nombre}`}
                onClick={(e)=>{
                  e.preventDefault();
                  setTagItem(nombre)
                }}
                style={{
                  fontSize: `${numero >= 6 ? 1.5 : (0.55 + (parseInt(numero) * 0.2))}em`
                }}
              >
                {nombre}
              </Link>
              {' '}
            </span>
          )
        }
      )}
    </section>
  )
}

export default BlogTags;

BlogTags.propTypes = {
  /**
   * Arreglo de Objetos que contiene el nombre y numero del tag
   */
  tags: PropTypes.arrayOf(PropTypes.shape({
    nombre: PropTypes.string,
    numero: PropTypes.string
  })).isRequired,

  /**
   * Función que actualiza el filtro por tag 
   * @type{Function(tag:string):void} -callback
   */
  setTagItem: PropTypes.func.isRequired,

  /**
   * String del titulo de la seccion o recuadro de tags
   */
  tituloTag: PropTypes.string
}