/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import { store, GENERAL_URL_API, ASSETS_URL } from '../Store.js';
import getAjax from './../../js/utilities/getAjax';
import { ModoPWAContext } from "../../contexts/ModoPwaContext.js";
import { AccesibilidadContext } from "../../contexts/AccesibilidadContext";
import useIsMobile from "../../js/utilities/useIsMobile.js";
import { useLogout } from "../../hooks/useLogout.js";
import { MenuAccesibilidad } from "../MenuAccesibilidad.js";
import MenuResponsive from "../menu/MenuResponsive";
import useObtenerSubsecciones from "../../js/translations/useObtenerSubsecciones.js";
import PropTypes from 'prop-types';

/**
 * Este componente renderiza el header para cliente individual. A diferencia del Header general, este no contiene la búsqueda avanzada y las opciones de descarga. Entre sus funciones más importantes esta la actualización de información de la sesión, en caso de que se cierre dicha sesión. Este hace uso de los componentes MenuResponsive y MenuAccesibilidad
 */
const HeaderIndividual = (props) => {
  const { textosInterfaz } = props;
  const { menu, panel, cerrar_sesion, accesibilidad, explorar, mi_cuenta, favoritos } = textosInterfaz;

  const globalState = useContext(store);
  const pwaState = useContext(ModoPWAContext);
  const { dispatch, state } = globalState;
  const isLogged = state.sesionActiva !== null && state.sesionActiva === 'SI';

  const history = useHistory()
  const location = useLocation();
  const accebilidadState = useContext(AccesibilidadContext);

  const [esTemaClasico, setEsTemaClasico] = useState(true);
  const logoutHandler = useLogout();
  const [mostrarWA, setMostrarWA] = useState(false);
  const isMobile = useIsMobile();
  const [isShowMenu, setIsShowMenu] = useState(false);
  const [miCuentaMenu, setMiCuentaMenu] = useState(false);
  const [isHome, setIsHome] = useState(false);

  const { isReady, textosSubSecciones: textosInterfazMenu } = useObtenerSubsecciones('menu');

  const refHeader = useRef(null);

  /**
   * Se encarga de actualizar los datos de la sesión en caso de que se cierre la sesión
   */
  useEffect(() => {
    if (state.sesionActiva === null || state.sesionActiva === 'NO') {
      getAjax(`${GENERAL_URL_API}/assets/verificarSesion`, {}, response => {
        let sesionVar = "NO";
        if (response === 'Sin sesion') {
          sesionVar = "NO";
        }
        else if (response === "Error") {
          if (location.pathname === "/login" || location.pathname === "/sin-conexion") {
            sesionVar = "NO";
          }
          else {
            sesionVar = "SI";
          }
        }
        else {
          sesionVar = "SI";
        }
        getAjax(`${GENERAL_URL_API}/assets/datosHeader`, { sesion: sesionVar }, response => {
          let makemakeData = JSON.parse(response).makemakeData;
          let categoriasMenu = JSON.parse(response).categoriasMenu;
          window.localStorage.setItem('makemakeData', JSON.stringify(makemakeData))
          window.localStorage.setItem('categoriasMenu', JSON.stringify(categoriasMenu))
          dispatch({ type: 'CHANGE_VALUE', property: 'pais', value: makemakeData.pais })
          dispatch({ type: 'CHANGE_VALUE', property: 'sesionActiva', value: makemakeData.sesionActiva })
          dispatch({ type: 'CHANGE_VALUE', property: 'userType', value: makemakeData.userType })
          dispatch({ type: 'CHANGE_VALUE', property: 'username', value: makemakeData.username })
          dispatch({ type: 'CHANGE_VALUE', property: 'datosHeader', value: makemakeData.datosHeader })
          dispatch({ type: 'CHANGE_VALUE', property: 'categoriasMenu', value: categoriasMenu })
          dispatch({ type: 'CHANGE_VALUE', property: 'tipo', value: makemakeData?.tipo })
          //loadImage();

          if (makemakeData.cantidadLibros === 0 && makemakeData.sesionActiva === "SI") {
            history.push("/mi-panel/mi-coleccion");
          }
        })

      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**Actualiza el estado de home para activar el botón de volver al Home */
  useEffect(() => {
    let home = location.pathname === "/" ? true : false;
    setIsHome(home)
  }, [location])

  /**
   * Actualiza el estado de EsTemaClasico. 
   */
  useEffect(() => {
    let tema = accebilidadState?.temaActual !== 'clasico' ? false : true;
    setEsTemaClasico(tema);
  }, [accebilidadState])

  /**
   * Esta función actualiza el estado isShowMenu para ocultar el menu responsive
   */
  const ocultarMenu = () => setIsShowMenu(false);

  /**
   * Retorna el boton de explorar o volver al Home
   * @returns explorarBtn
   */
  const explorarBtn = () => {
    return (
      <div className={`width-explore d-lg-flex align-items-center ${!isMobile && 'h-100'}`}>
        <Link
          to={'/home'}
          onClick={() => {
            setIsShowMenu(false);
            setMiCuentaMenu(false);
          }}
          className={`focusable-secondary ${isMobile ? "btn-responsive" : `btn-explorar ${isHome && 'btn-explorar-activo'}`}`}
        >
          <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 31 29.8"
            style={{ enable: "new 0 0 31 29.8" }}
            space="preserve"
            className="icono-home"
          >,
            <path style={{ fill: isLogged && esTemaClasico ? state.datosHeader?.colorLetra : 'var(--onbackground-primary)' }} className="st0" d="M16,5.3c-0.3-0.2-0.7-0.2-0.9,0L0.7,13c-0.9,0.5-0.6,1.9,0.5,1.9h5.1c0.6,0,1.1,0.5,1,1.2L5.4,28.3
	c-0.1,0.6,0.4,1.2,1,1.2h18.2c0.6,0,1.1-0.6,1-1.2L23,16.2c-0.1-0.6,0.3-1.2,1-1.2h5.9c1,0,1.4-1.4,0.5-1.9L16,5.3z M12.5,20.2
	c0-0.6,0.4-1,1-1h4c0.6,0,1,0.4,1,1v9h-6V20.2z"/>
            <path style={{ fill: isLogged && esTemaClasico ? state.datosHeader?.colorLetra : 'var(--onbackground-primary)' }} className="st1" d="M28.7,3.5l-5.3-2.4c-0.1-0.1-0.3,0-0.3,0.2V6l5.6-2.1C28.8,3.8,28.8,3.6,28.7,3.5z" />
            <path style={{ strokeWidth: isLogged && esTemaClasico ? state.datosHeader?.colorLetra : 'var(--onbackground-primary)', fill: isLogged && esTemaClasico ? state.datosHeader?.colorLetra : 'var(--onbackground-primary)'}} className="st2" d="M23.1,11V6 M23.1,6V1.3c0-0.1,0.2-0.2,0.3-0.2l5.3,2.4c0.2,0.1,0.2,0.3,0,0.4L23.1,6z" />
          </svg>
          {explorar}
        </Link>
      </div>
    )
  }

  /**
   * Retorna los botones de mi panel y cerrar sesión
   * @returns menuMiCuenta
   */
  const menuMiCuenta = () => {
    return (
      <>
        <Link
          to="/favoritos"
          className={isMobile ? "btn-responsive" : "btn-menu-cuenta"}
          onClick={() => { setMiCuentaMenu(false); setIsShowMenu(false); }}
        >
          <div className="icon-cuenta i-favoritos order-lg-2"></div>
          <span className="order-lg-1">{favoritos ? favoritos : 'Favoritos'}</span>
        </Link>
        {state.userType === 'Cliente' &&
          <Link
            to="/mi-panel/mi-cuenta"
            className={`focusable-secondary ${isMobile ? "btn-responsive" : "btn-menu-cuenta"}`}
            onClick={() => { setMiCuentaMenu(false); setIsShowMenu(false); }}
          >
            <div className="icon-cuenta i-panel order-lg-2"></div>
            <span className="order-lg-1">{panel ? panel : 'Mi panel'}</span>
          </Link>
        }

        <hr className="d-none d-lg-block" />

        {location.pathname.includes('ezproxy') === false &&
          pwaState?.appInstalada === false &&
          <button
            tabIndex={0}
            className={`focusable-secondary ${isMobile ? "btn-responsive" : "btn-cerrar-sesion"}`}
            onClick={e => {
              e.preventDefault();
              logoutHandler.logout();
              setIsShowMenu(false);
            }}>
            {cerrar_sesion || 'Cerrar sesión'} {logoutHandler?.loading && <i className="fas fa-spinner fa-pulse"></i>}
          </button>
        }
      </>
    )
  }

  /**
   * Retorna los botones de accesibilidad y Mi cuenta
   * @returns accMiCuenta
   */
  const accMiCuenta = () => {
    return (
      <div className="d-lg-flex mx-3 mx-lg-0">
        <button
          className={`focusable-secondary ${isMobile ? "btn-responsive" : `btn-header-gris mr-3 ${mostrarWA && 'active'}`}`}
          onClick={() => {
            setIsShowMenu(false);
            setMostrarWA(!mostrarWA);
            setMiCuentaMenu(false)
          }}
        >
          <i className="fa fa-universal-access mr-2"></i>
          {accesibilidad ? accesibilidad : 'Accesibilidad'}
        </button>

        {!isMobile &&
          <>
            <button
              className={`position-relative focusable-secondary ${isMobile ? "btn-responsive" : `btn-header-cuenta d-flex align-items-center ${miCuentaMenu && 'active-cuenta'}`}`}
              onClick={() => {
                setMiCuentaMenu(!miCuentaMenu);
                setMostrarWA(false);
              }}
            >
              <div
                className="mr-1 mr-lg-3 btn-avatar"
                id="header-avatar-profile"
                style={
                  { backgroundImage: `url('${isLogged ? `${ASSETS_URL}${state.datosHeader?.avatar}` : '/img/profile.png'}')` }
                }
              ></div>
              <span>{mi_cuenta}</span>
              <i className="fas fa-chevron-down fa-sm ml-2 arrow"></i>
            </button>


            <div 
              onClick={(e) => e.target === e.currentTarget && cerrarMiCuenta()} 
              className={`wrapper-mi-cuenta ${miCuentaMenu ? 'visible' : 'hidden'}`}
            >
              <div
                className={`menu-mi-cuenta ${miCuentaMenu ? 'visible' : 'hidden'}`}
                aria-hidden={miCuentaMenu ? false : true}
              >
                {menuMiCuenta()}
              </div>
            </div>
          </>

        }

        {/* <button className={`focusable-secondary ${isMobile ? "btn-responsive" : "btn-buzon ml-3"}`}>
              <div className="icon-buzon"></div>
              <p className="d-lg-none ml-2">Buzón</p>
          </button> */}
      </div>
    )
  }

  //cierra las opciones de mi cuenta
  const cerrarMiCuenta = () => setMiCuentaMenu(false);

  return (
    <>
      {!location.pathname.includes('/visor') && (
        <div
          ref={refHeader}
          className="altura-header px-3"
          style={
            {
              backgroundColor: isLogged ? esTemaClasico ? state.datosHeader?.colorHeader : 'var(--background-primary)' : 'var(----background-color)',
              color: isLogged && esTemaClasico ? state.datosHeader?.colorLetra : 'var(--onbackground-primary)',
              fontSize: '0.8em'
            }
          }>

          <div className="col-6 d-lg-none">
            <button
              onClick={() => { setIsShowMenu(true) }}
              className="btn-menu focusable-secondary p-0 py-1 p-md-2 d-flex aling-items-center "
            >
              <i className="fas fa-bars mr-2 mt-1"></i>
              {menu ? menu : 'Menú'}
            </button>
          </div>

          {!isMobile && explorarBtn()}

          <div className="width-brand d-flex justify-content-end justify-content-lg-center">
            <NavLink
              to={isLogged ? '/home' : '/'}
              onClick={() => {
                setIsShowMenu(false);
                setMiCuentaMenu(false);
              }}
              className={'focusable-secondary header-logo'}
              style={{ backgroundImage: `url(${isLogged && state.datosHeader?.logo != null ? `${ASSETS_URL}${state.datosHeader?.logo}` : '/img/logo-makemake.png'})` }}
              aria-label="Logo institución"
            >
            </NavLink>
          </div>

          <div className="width-options h-100 d-flex justify-content-end align-items-center">
            {!isMobile && accMiCuenta()}
          </div>
        </div>
      )
      }

      <div className="menu-accesibilidad-flotante">
        <MenuAccesibilidad setVisible={setMostrarWA} visible={mostrarWA} />
      </div>

      {isReady && isShowMenu && isMobile &&
        <MenuResponsive
          isShowMenu={isShowMenu}
          ocultarMenu={ocultarMenu}
          menuMiCuenta={menuMiCuenta}
          isLogged={isLogged}
          textosMenu={textosInterfazMenu}
          explorarYbuscar={explorarBtn}
          planAccLogin={accMiCuenta}
        />
      }
    </>
  )
}

export default HeaderIndividual;

HeaderIndividual.propTypes = {
  /*
  * Es un objeto que trae los textos de la interfaz dependiendo del idioma activo
  */
  textosInterfaz: PropTypes.object.isRequired,
}