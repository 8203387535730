import React, { useEffect } from "react"
import useObtenerSubsecciones from "../../js/translations/useObtenerSubsecciones";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { BannerSection } from "../components/banner";
import { GenericHomeRowTitle, HomeRow } from "../components/row-section";
import { BooksSection } from "../components/books-section";
import { CollectionSection } from "../components/collection";
import { AuthorSection } from "../components/authors";
import SkeletonHome from '../../skeletons/SkeletonHome';

/**
 * Componente que muestra la página de inicio del usuario que no es individual y está autenticado.
 * @param {Object} props - Props del componente.
 * @param {Object} props.data - Datos de la página de inicio del usuario.
 * @returns {JSX.Element} Elemento JSX que representa la página de inicio del usuario.
 */
export const UserHome = ({ data }) => {
    const { textosSubSecciones: textosHome } = useObtenerSubsecciones('home');
    const textos = textosHome?.login || {};
    const homeData = data?.data;

    useEffect(()=>{
        window.scrollTo(0,0)
    },[])

    return (<section>
        {
            data?.fetching ?
                <SkeletonHome />
                :

                <LazyLoadComponent threshold={.5} >
                    <BannerSection banners={homeData?.banners} />
                    <HomeRow
                        containerProps={{
                            className: ''
                        }}
                        titleElement={
                            <GenericHomeRowTitle text={textos?.autores || 'Autores y series'} />
                        }>
                        <AuthorSection collections={homeData?.autores} autoPlayDelay={5000} />

                    </HomeRow>
                    <HomeRow
                        containerProps={{
                            className: 'home-row-purple'
                        }}
                        titleElement={
                            <GenericHomeRowTitle text={textos?.libros_recomendados || 'Nuestros libros recomendados'} />
                        }>
                        <BooksSection books={homeData?.librosRecomendados} autoPlayDelay={5500} />
                    </HomeRow>
                    <HomeRow
                        containerProps={{
                            className: ''
                        }}
                        titleElement={
                            <GenericHomeRowTitle text={textos?.nuestra_coleccion || 'Nuestras colecciones'} />
                        }>
                        <CollectionSection collections={homeData?.colecciones} autoPlayDelay={5000} />
                    </HomeRow>
                    <HomeRow
                        containerProps={{
                            className: 'home-row-yellow'
                        }}
                        titleElement={
                            <GenericHomeRowTitle text={textos?.libros_ilustrados || 'Libros ilustrados'} />
                        }>
                        <BooksSection books={homeData?.LibrosIlustrados} autoPlayDelay={5500} />
                    </HomeRow>
                    <HomeRow
                        containerProps={{
                            className: 'home-row-purple'
                        }}
                        titleElement={
                            <GenericHomeRowTitle text={textos?.lecturas_interactivas || 'Lecturas interactivas'} />
                        }>
                        <BooksSection books={homeData?.LibrosInteractivos} autoPlayDelay={5000} />
                    </HomeRow>
                </LazyLoadComponent>
        }

    </section>)
}