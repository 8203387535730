import React, { Children, useState, isValidElement, useEffect, useContext } from "react";
import useObtenerSubsecciones from "../../js/translations/useObtenerSubsecciones";
import _ from "lodash";
import { store } from "../../components/Store";
import { useEpayco } from "../../hooks/useEpayco";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import ModalExternalPageAcademia from "./modal-external-page-academia";
import { useWindowOpen } from "../../hooks/useWindowOpen";

/**
 * Este componente renderiza la ficha de curso. Se encuentra ubicado en la tienda makemake, dentro de la opción quiero aprender algo nuevo.
 * @param {object} generalDescription  objeto, indican la modalidad, tiempo y acceso del curso
 * @param {object} nombreCurso  objeto, indican el nombre del curso
 * @param {object} autor  objeto, contienen el nombre del autor del curso
 * @param {string} trailer  String que hace referencia al link del trailer en academia 
 * @param {string} cardColor  String que equivalente a la clase que se usa para dar color a la tarjeta 
 * @param {object} epaycoData   Objeto que contiene la información de pago del curso
 * @param {string} image   string que equivale a la ruta src correspondiente a la imagen del curso
 * @type {PriceDescription} props.children  - Precio y moneda
 * @type {FeaturesList} props.children  - Lista de características o detalles
 * @type {ExtraInformation} props.children  - más información o información de preventa
 */
const FichaAcademia = (props) => {
  const { children, generalDescription, nombreCurso, autor, trailer, epaycoData, cardColor, image } = props;
  //textos
  const { isReady, textosSubSecciones: textos } = useObtenerSubsecciones('academia');
  const [loading, setLoading] = useState(false);
  const [showModalExternalPage, setShowModalExternalPage] = useState(false);
  const epayco = useEpayco();
  const newWindowTab = useWindowOpen();

  const cargarDetalles = () => {
    return Children.map(children, (child) => {
      if (isValidElement(child) && child.type === FeaturesList || child.type === ExtraInformation) {
        return (
          <>{child}</>
        )
      }
    })
  }
  const [contenidoDetalle, setContenidoDetalle] = useState(cargarDetalles());

  const cargarPrecio = () => {
    return Children.map(children, (child) => {
      if (isValidElement(child) && child.type === PriceDescription) {
        return (
          <>{child}</>
        )
      }
    })
  }
  const [contenidoValor, setContenidoValor] = useState(cargarPrecio());
  useEffect(() => {
    setContenidoDetalle(cargarDetalles());
    setContenidoValor(cargarPrecio());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children])

  return (
    <>
      {
        isReady &&
        <aside className="academy-card d-md-flex" aria-label={'curso'}>
          <div className={`col-md-5 px-0 column-left ${cardColor}`}>
            <div className="img-course order-md-2" style={{ backgroundImage: `url(${image ? image : '/img/no-imagen3.png' })` }}></div>

            <div className="card-description  order-md-1" tabIndex={0} aria-label={'descripción del curso'}>
              <p className="general-description">{_.get(textos, generalDescription.translationPath) || generalDescription.default}</p>
              <h4>{_.get(textos, nombreCurso.translationPath) || nombreCurso.default || 'N/A'}</h4>
              <p
                className="creator"
                dangerouslySetInnerHTML={{ __html: _.get(textos, autor.translationPath) || autor.default || 'N/A' }}
              ></p>
            </div>

          </div>

          <div className="col-md-7 p-3 d-flex flex-column">

            {contenidoDetalle}

            <div className="d-lg-flex align-items-center justify-content-between mt-auto px-md-3">
              {contenidoValor}


              <div className="d-flex align-items-center justify-content-lg-end">
                <button
                  className="btn-trailer mr-3 focusable-red"
                  onClick={() => {
                    // setShowModalExternalPage(true);
                    newWindowTab.open('_blank', trailer)

                  }}
                >
                  <i className="fas fa-play mx-auto"></i>
                  <p>{textos?.interfaz?.trailer || 'Ver trailer'}</p>
                </button>

                <button
                  className="btn-tarjeta-course focusable-red"
                  onClick={() => {
                    epayco.buy(epaycoData);
                    setLoading(true);
                  }}
                >
                  {textos?.interfaz?.btn_comprar || 'Comprar'}  {loading && <Icon icon="fa:spinner" className={'spinner-rotation'} />}
                </button>
              </div>
            </div>
          </div>
        </aside>
      }
      <ModalExternalPageAcademia
        modalId = {`external-page-modal-${nombreCurso?.default||'N/A'}`}
        visible={showModalExternalPage}
        setVisible={setShowModalExternalPage}
        texto={textos?.interfaz?.pop_up || 'Abriremos en otra pestaña la descripción completa de este curso. Para continuar con tu compra, regresa a la pestaña anterior'}
        urlRedirect={trailer}
      />
    </>
  )
}
FichaAcademia.propTypes = {
  /**
   * Objeto que contiene translationPath o default. Estos valores indican la modalidad, tiempo y acceso del curso. 
   */
  generalDescription: PropTypes.object,
  /**
   * Objeto que contiene translationPath o default. Estos indican el nombre del curso
   */
  nombreCurso: PropTypes.object,
  /**
   * Objeto que contiene translationPath o default. Estos contienen el nombre del autor del curso
   */
  autor: PropTypes.object,
  /**
   * String que hace referencia al link del trailer en academia 
   */
  trailer: PropTypes.string,
  /**
   * string equivalente a la clase que se usa para dar color a la tarjeta.
   * Actualmente el proyecto cuenta con las siguientes clases:
   * card-blue, card-pink y card-green
   */
  cardColor: PropTypes.string,
  /** 
   * Objeto que contiene la información de pago del curso. 
  */
  epaycoData: PropTypes.object,
  /**
   * string que equivale a la ruta src correspondiente a la imagen del curso
   */
  image: PropTypes.string
}

/**
 * Función que renderiza la lista de características o detalles del curso
 * @param {array} list arreglo de caracteristicas  o detalles
 * @returns FeaturesList
 */
const FeaturesList = (props) => {
  const { textosSubSecciones: textos } = useObtenerSubsecciones('academia');
  const { list } = props;

  return (
    <ul className="features-list mb-4 mb-lg-0 mt-lg-4">
      {list.map((element, i) => {
        return (
          <li key={`element-${i}`} dangerouslySetInnerHTML={{ __html: _.get(textos, element?.translationPath) || element?.default || 'N/A' }}></li>
        )
      })}
    </ul>
  )
}

/**
 * Función que renderiza más información o información de preventa
 * @param {object} info objeto con la información
 * @param {boolean} activado - indica si el curso contiene esta información
 * @returns ExtraInformation
 */
const ExtraInformation = (props) => {
  const { textosSubSecciones: textos } = useObtenerSubsecciones('academia');
  const { info, activado } = props;
  const list = info?.detalles;

  return (
    <>
      {
        activado &&
        <>
          <p
            className="red-inverse--color font-weight-bold"
            dangerouslySetInnerHTML={{ __html: _.get(textos, info?.titulo?.translationPath) || info?.titulo?.default || 'N/A' }}
          ></p>

          <ul className="features-list mb-4">
            {list.map((element, i) => {
              return (
                <li key={`element-${i}`} dangerouslySetInnerHTML={{ __html: _.get(textos, element?.translationPath) || element?.default || 'N/A' }}></li>
              )
            })}
          </ul>
        </>
      }

    </>
  )

}

/**
 * Esta función renderiza la sección de precio 
 * @param {string} Price string que indica el valor del curso
 * @returns PriceDescription
 */
const PriceDescription = (props) => {
  const { isReady, textosSubSecciones: textos } = useObtenerSubsecciones('academia');
  const { price, descuento, hideDiscountPercentage, preventa } = props;

  const globalState = useContext(store);
  const { state } = globalState;
  const { pais } = state;

  const finalPrice = price && pais === 'CO' ? price?.cop || 'n/a' : price?.usd || 'n/a';
  const offer = descuento && pais === 'CO' ? descuento?.cop || 'n/a' : descuento?.usd || 'n/a';
  const moneda = pais === 'CO' ? 'COP' : 'USD';
  const isOffer = descuento?.activado;
  const isPreSale = preventa?.activado;

  return (
    <>
      {
        isReady &&
        <div className="price-description mb-3 mb-lg-0">
          {
            isOffer&&
            <p className="font-weight-normal" >
              <span style={{fontSize:'.7em', textDecorationLine:'line-through'}}>
                ${finalPrice} {moneda}
              </span>
              {/* <span className="font-weight-bold" style={{fontSize:'.7em'}}> {offer?.cantidad} OFF</span> */}
            </p>
          }
          <p aria-label="moneda" style={{ fontSize: '1em' }}>{moneda}</p>
          <p className="price" style={{ fontSize: '1.2em' }}>${isOffer ? offer?.precio : finalPrice}</p>
          <p>{textos?.interfaz?.precio || 'Precio por persona'}</p>
          {isPreSale && 
            <p
              className="red-inverse--color"
              style={{
                fontSize:'0.8em'
              }}
              dangerouslySetInnerHTML={{ __html: _.get(textos, preventa?.text?.translationPath) || preventa?.text?.default || 'N/A' }}></p>
          }
        </div>
      }
    </>
  )
}

export { FichaAcademia, FeaturesList, PriceDescription, ExtraInformation };