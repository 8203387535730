import { useState } from "react";
import {  store } from "../../../components/Store";
import { useContext } from "react";
import { useEffect } from "react";

let timer;

export const usePreviewBooks = ({ currentOption }) => {
    const stateContext = useContext(store);
    const allBooks = Array.isArray(stateContext?.state?.userBooksData?.books) ? stateContext?.state?.userBooksData?.books : [];
    const loadedCompleted = stateContext?.state?.userBooksData?.loadCompleted;
    const [books, setBooks] = useState([]);
    const [loading, setLoading] = useState(false);

    const filterBooks = () => {
        setLoading(true);
        const filtered = allBooks?.filter(book => book?.[currentOption?.type] === currentOption?.value);
        const randomBooks = [];
        for (let i = 0; i < 6; i++) {
            const randomIndex = Math.floor(Math.random() * filtered?.length);
            const book = filtered[randomIndex];
            randomBooks.push(book);
        }
        setBooks(randomBooks);
        timer = setTimeout(() => {
            setLoading(false);
        }, 500);
    }

    useEffect(() => {
        filterBooks();
        return () => {
            clearTimeout(timer);
        }
    }, [currentOption, allBooks])

    return {
        data: books,
        loading: (loadedCompleted === true && loading === false) ? false : true,

    };
}