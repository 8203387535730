import React from "react";
import useObtenerSubsecciones from "../../js/translations/useObtenerSubsecciones";

/**
 * Componente que muestra una sección de "Descubre más", lleva a la sección de club MakeMake.
 * @returns {JSX.Element|null} Elemento JSX 
 */
export const DiscoverMore = () => {
  const { isReady, textosSubSecciones: textoInstruccion } = useObtenerSubsecciones('home')
  return (
    isReady &&
    <div tabIndex={0} className="focusable-secondary highlight-container demo--instruction-2 py-4 ">
      <div className="row mx-0 py-3">
        <div className="col-lg-8 mx-auto">
          <div className="align-items-center justify-content-between flex-column">
            <div className="mx-auto mb-3">
              <p className="text-center">{textoInstruccion.instruccion.descripcion_uno}</p>
              <p className="text-center">{textoInstruccion.instruccion.descripcion_dos}</p>
            </div>
            <div>
              <a className="focusable-secondary" href="./club-makemake"><button
                className="btn btn-primary py-2 px-4 d-block mx-auto">{textoInstruccion.instruccion.boton}</button></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

