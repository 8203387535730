import { IMPORTANT_STORAGE_KEYS } from "./constants";

/**
 * Función que borra todas las llaves del localStorage excepto las llaves importantes que se encuentren en IMPORTANT_STORAGE_KEYS
 */
export const ClearStorage = () => {
  const storage = window.localStorage;
  const keysStorage = Object.keys(storage);
 
  keysStorage.some(keyName => {
    const isImportantKey = IMPORTANT_STORAGE_KEYS.includes(keyName);
    if(isImportantKey === false){
      storage.removeItem(keyName);
    }
  })  
}
