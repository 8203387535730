import React, { useEffect } from "react";
import { BaseModal } from "./base-modal"
import { Icon } from '@iconify/react';
import useObtenerSubsecciones from "../../js/translations/useObtenerSubsecciones";

/**
Componente Modal que el mensaje de compatibilidad de libros interactivos en celulares
@param {object} props - Propiedades del componente
@param {string} props.visible - Indica si se debe mostrar o no el modal
@param {function} props.setVisible - Función la cual actualiza el estado de visibilidad
@returns {JSX.Element} Elemento JSX que representa el componente modal
*/
export const IncompatibleBook = ({ visible, setVisible }) => {

  const {  textosSubSecciones: visor } = useObtenerSubsecciones("visor");

  useEffect(() => {
    let timer = setTimeout(() => {
      if(visible){
        setVisible(false);
      }
    }, 15000);
    return () => {
        clearTimeout(timer);
    }
}, [visible])

  return (
    <>
      <BaseModal
        windowClassName={'default-modal-window moda_tipo_visor'}
        visible={visible}
        setVisible={setVisible}
        backdropClose={false}
      >
        <div className="d-flex justify-content-end">
            <button
              aria-label="Cerrar ventana de dialogo"
              className="close-button onbackground-primary--color focusable-red"
              onClick={() => setVisible(false)}
            >
              <Icon icon={'mdi:close-thick'} width={35} />
            </button>
        </div>
        <h2 className="font-quicksand font-weight-bold onbackground-third--color text-center mb-3">
          <Icon icon="ic:twotone-replay" width={30} />
          {visor?.modal_incompatible?.titulo || 'Ups!'}
        </h2>
        <div className="px-3 onbackground-primary--color">
          <p className="text-center">{visor?.modal_incompatible?.parrafo_uno || 'Este libro no es compatible con tu teléfono celular. Te invitamos a que lo consultes en tu computador. '}</p>
          <p className="text-center">{visor?.modal_incompatible?.parrafo_dos || '(Este mensaje desaparecerá en 5 segundos.)'}</p>
        </div>
      </BaseModal>
    </>
  )
}