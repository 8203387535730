import React, { useState, useEffect, useContext,  } from "react";
import getAjax from "../js/utilities/getAjax";
import { GENERAL_URL_API, ASSETS_URL } from "../components/Store.js";
import { AccesibilidadContext } from "../contexts/AccesibilidadContext";

//Componentes
import Libro from "../components/Libro";
import SkeletonFichaColeccion from "../skeletons/SkeletonFichaColeccion";
import { CollectionBooks } from "./collectionBooks";

import { useHistory } from "react-router-dom";

/**
 * Componente para mostrar la ficha de una colección.
 * @param {object} props - Propiedades del componente.
 * @returns {JSX.Element} Elemento JSX que representa la ficha de la colección.
 */

const FichaColeccion = (props) => {
  const [isLoading, setIsloading] = useState(true);
  const [datosColeccion, setDatosColeccion] = useState("");
  const [librosColeccion, setLibrosColeccion] = useState();

  const [esTemaClasico, setEsTemaClasico] = useState(true);
  const accebilidadState = useContext(AccesibilidadContext);

  const history = useHistory(); 

  useEffect(() => {
    let id = props.match.params.nombreColeccion;
    getAjax(
      `${GENERAL_URL_API}/assets/datosColeccion`,
      { nombreColeccion: id },
      (response) => {
        try {
          let data = Boolean(response) ? JSON.parse(response) : {};
          setLibrosColeccion(data.libros);
          setDatosColeccion(data);
          setIsloading(false);
        }catch(error){
          console.log(error)
          setIsloading(false);
          setLibrosColeccion([]);
          setDatosColeccion([]);
          history.push('/404')
        }
        
      }
    );
  }, [props.match.params.nombreColeccion]);

  useEffect(() => {
    let tema = accebilidadState?.temaActual !== 'clasico' ? false : true;
    setEsTemaClasico(tema);
  }, [accebilidadState])

  return (
    <>
      {(() => {
        if (isLoading) {
          return <SkeletonFichaColeccion />;
        } else {
          return (
            <>
              <section aria-label="sección" role={"main"} tabIndex={0} className="focusable-secondary main-container">
                <div
                  className="coleccion-container"
                  style={{
                    backgroundColor: esTemaClasico ? datosColeccion.color1 : 'var(--background-color)',
                    color: esTemaClasico ? datosColeccion.colorLetras : 'var(--onbackground-primary)',
                  }}
                >
                  <div
                    className="coleccion-background"
                    style={{
                      background: `${esTemaClasico ? datosColeccion.color2 : 'var(--background-color)'} url('/img/background.png') center/cover repeat`,
                    }}
                  ></div>
                  <div className="container">
                    <div className="row pt-5 align-items-center mb-3">
                      <div className="col-lg-6 mb-5 mb-lg-0">
                        <h1 tabIndex={0} className="focusable-secondary" style={{ color: esTemaClasico ? datosColeccion.colorLetras : 'var(--onbackground-primary)' }}>
                          {datosColeccion.nombreColeccion}
                        </h1>
                      </div>
                      <div className="col-lg-6 pl-lg-5">
                        <p className="coleccion-autor">
                          {datosColeccion.subtitulo}
                        </p>
                        <p tabIndex={0} className="focusable-secondary">{datosColeccion.descripcion}</p>
                      </div>
                    </div>
                    <div className="row mt-5 pb-5 mt-lg-0 align-items-center">
                      <div className="mb-5 mb-lg-0 col-lg-6 px-0">
                        <div className="coleccion-imagen">
                          <img
                            alt={`Imagen colección ${datosColeccion.nombreColeccion}`}
                            className="img-fluid mx-auto d-block"
                            src={`${ASSETS_URL}${datosColeccion.imagen}`}
                          />
                        </div>
                      </div>
                      <div
                        className={
                          librosColeccion?.length <= 6
                            ? "col-lg-6 pl-lg-5"
                            : "col-lg-6 pb-5 pl-lg-4"
                        }
                      >
                        {(() => {
                          if (librosColeccion?.length <= 6) {
                            return (
                              <div className="coleccion-books row">
                                {librosColeccion.map((item, index) => (
                                  <div
                                    className={`cover-book col-6 col-lg-4 mb-4`}
                                    key={`book${index}`}
                                  >
                                    <Libro
                                      libro={item}
                                      portada={item.portada}
                                      webUrl={item.link}
                                    />
                                  </div>
                                ))}
                              </div>
                            );
                          } else {
                            return (<CollectionBooks books={librosColeccion}/>);
                          }
                        })()}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          );
        }
      })()}
    </>
  );
};

export default FichaColeccion;
