
import React from "react"
import { Hero } from "./components/hero"
import { Clients } from "./components/clients"
import { Community } from "./components/community"
import { OurPublishers } from "./components/our-publishers"
import { MainPlans } from "./components/main-plans"
import { DemoRequest } from "./components/demo-request"
import { Newsletter } from "./components/newsletter"
import { BooksPreview } from "./components/books-preview"
import { MorePlans } from "./components/more-plans"
import { Attributes } from "./components/attributes"
import { Awards } from "./components/awards"
import { StaticBagde } from "../components/badge"

export const Home = () => {
    return (<div className="que-es min-vh-100">
        <StaticBagde />
        <Hero />
        <Clients />
        <Community />
        <Attributes/>
        <Newsletter/>
        <MainPlans/>
        <BooksPreview/>
        <OurPublishers />
        <Awards/>
        <DemoRequest/>
        <MorePlans/>
    </div>)
}