import React from "react";
import { BackButtonHeader } from "./back-button";
import { Link } from "react-router-dom";
import { Img } from "../../components/shared/img";
import { Icon } from "@iconify/react";
import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../hooks/use-translations";

/**
 * Este componente muestra la pantalla No encuentro mi instituto
 * Su principal función es redireccionar a las pantallas de ayudanos a contactar a tu institución y agendar una cita
 */

export const InstitutionNotFound = () => {
	const lang = useDefaultLangByCountry({ country: "us", defaultLang: "en" });
	const textos = useTranslations({ section: "autenticacion_institucional", lang: lang });

	return (
		<div className="institutional-auth">
			<div className="relative">
				<div className="bg-shape"></div>
				<div className="container page-content d-flex flex-column align-items-center w-100 h-100">
					<BackButtonHeader link={"/"} />
					<h1 className="onsecondary-title mt-5">
						{textos?.institucion_no_encontrada?.titulo || "No encuentro mi institución"}
					</h1>
					<p className="onsecondary-title2 mb-4 mt-3">
						{textos?.institucion_no_encontrada?.descripcion ||
							"MakeMake tiene alternativas para ti"}
					</p>
					<div className="row justify-content-center">
						<OptionCard
							img={"/img/institutional-auth/cita.png"}
							description={
								textos?.institucion_no_encontrada?.tarjeta?.cita?.descripcion ||
								"Podemos conocernos para saber qué necesitas y como te ayudamos"
							}
						>
							<div>
								<Link
									to={"/tienda/institucional"}
									className="inline-flex primary-button-var2"
								>
									{textos?.institucion_no_encontrada?.boton?.cita ||
										"Agendar una cita"}
								</Link>
							</div>
						</OptionCard>
						<OptionCard
							img={"/img/institutional-auth/plan-individual.png"}
							description={
								textos?.institucion_no_encontrada?.tarjeta?.comprar_plan
									?.descripcion || "¡Accede a las lecturas desde tu casa!"
							}
						>
							<div>
								<Link
									to={"/tienda/individual"}
									className="inline-flex primary-button-var2"
								>
									{textos?.institucion_no_encontrada?.boton?.comprar_plan ||
										"Compra un plan para ti"}
								</Link>
							</div>
						</OptionCard>
						<OptionCard
							img={"/img/institutional-auth/contacto.png"}
							description={
								textos?.institucion_no_encontrada?.tarjeta?.contacto?.descripcion ||
								"Contáctanos para poderte ayudar"
							}
						>
							<div className="d-flex flex-column">
								<a
									href={"mailto:comercial@makemake.com.co"}
									target="_blank"
									rel="noopener noreferrer"
									className="d-flex align-items-center text-break secondary-var2-link mb-2"
									style={{ textDecoration: "none" }}
								>
									<Icon icon="ic:baseline-email" width={22} className="mr-2" />{" "}
									comercial@makemake.com.co
								</a>
								<a
									href={"https://wa.link/aozzbk"}
									target="_blank"
									rel="noopener noreferrer"
									className="d-flex align-items-center text-break secondary-var2-link"
									style={{ textDecoration: "none" }}
								>
									<Icon icon="dashicons:whatsapp" width={22} className="mr-2" />{" "}
									+57 350 2367037
								</a>
							</div>
						</OptionCard>
					</div>
				</div>
			</div>
		</div>
	);
};

const OptionCard = ({ img, description, children }) => {
	return (
		<div className="m-2 col-12 col-sm-10 col-md-9 col-lg-5 col-xl d-flex flex-column align-items-center justify-content-center">
			<div className="not-found-option-card d-flex flex-column px-3 py-5 w-100 h-100 justify-content-between align-items-center">
				<div className="justify-content-center img-wrapper mb-4 mt-4">
					<Img className="w-100 h-100" src={img} />
				</div>
				<p className="description mb-4 text-center px-3">{description}</p>
				<div className="">{children}</div>
			</div>
		</div>
	);
};
