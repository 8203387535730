import React from "react"
import { useHistory } from "react-router-dom"
import useObtenerSubsecciones from "../../js/translations/useObtenerSubsecciones"
import { BaseModal } from "./base-modal"

/**
Componente Modal el cual muestra información en la que se indica que se ha llegado a un límite de concurrencias.
@param {object} props - Propiedades del componente
@param {string} props.visible - Indica si se debe mostrar o no el modal
@param {function} props.setVisible - Función la cual actualiza el estado de visibilidad
@param {'' | 'app'} props.mode -indica en que modo debe mostrarse, si es 'app' se oculta el botón de volver al home.
@returns {JSX.Element} Elemento JSX que representa el componente modal
*/
export const SessionLimitModal = ({ visible, setVisible, mode }) => {

    const history = useHistory();
    const { textosSubSecciones: texts } = useObtenerSubsecciones('global');

    return <BaseModal windowClassName={'default-modal-window'} visible={visible} setVisible={setVisible} backdropClose={false} >
        <p className="text-center">
            <i
                className={`fas fa-exclamation-triangle primary-item fa-4x red-inverse--color `}
            ></i>
        </p>
        <h2
            className={`text-center red-inverse--color "primary-item `}
            id="titulo-modal-alertas"
        >
            {texts?.popups?.limite_concurrencias?.alert || 'Alerta'}
        </h2>
        <div className="modal__content my-2" id="dialogo-modal-alertas">
            <p
                className="second-category-item no-lineheight text-center mb-3 onbackground-third--color"
                id="alert-message"
            >
                {texts?.popups?.limite_concurrencias?.title || 'Has superado el límite de concurrencias'}
            </p>
            <p className="mb-5 text-center onbackground-third--color" id="alert-description">
                {texts?.popups?.limite_concurrencias?.description || 'En este momento tienes más usuarios conectados de los que admite tu suscripción. Comunícate con ventas para aumentar la capacidad de usuarios simultáneos. Escríbenos a contacto@makemake.com.co'}
            </p>

        </div>
        {
            mode !== 'app' &&
            <button
                className="btn btn-primary d-block mx-auto focusable-red"
                onClick={() => history.push('/home')}
            >
                {texts?.popups?.limite_concurrencias?.ok_button || 'Aceptar'}
            </button>
        }
    </BaseModal>
}