import axios from "axios"
import { GENERAL_URL_API } from "../../components/Store";

/**
 * Este servicio envia los datos de solicitud de demo para un instituto no inscrito a MakeMake
 * @async
 * @param {AbortSignal} [options.abortSignal=null] - Objeto AbortSignal para cancelar la solicitud.
 * @param {Object} dataForm - Objeto que contiene la información del formulario
 * @returns {Promise<Object|Error>} Promise que resuelve en un objeto de datos o rechaza con un error.
 */
const solicitarDemoInstitucional = async ({ abortSignal = null, dataForm }) => {
  try {
    axios.defaults.baseURL = GENERAL_URL_API;
    axios.defaults.withCredentials = true;

    const formData = new FormData();
    formData.append('nombreP', dataForm?.nombreP ||'');
    formData.append('correoP', dataForm?.correoP || '');
    formData.append('edadP', dataForm?.edadP || '');
    formData.append('ciudadP', dataForm?.ciudadP || '');
    formData.append('paisP', dataForm?.paisP || '');
    formData.append('codigoPaisP', dataForm?.codigoPaisP || '');
    formData.append('check1', 'solicitar demo');
    formData.append('institucionP', dataForm?.institucionP || '');
    formData.append('telefonoP', dataForm?.telefonoP || '');

    formData.append('referenciaP', 'No aplica');
    formData.append('textoOtroP', '');
    formData.append('institutoCargo', 'No aplica');
    formData.append('tipoInstitucion', 'No aplica');

    formData.append('tipoSolicitudDemo', 'Nueva solicitud de demo con un instituto no inscrito a MakeMake');


    const response =await axios({
      method: 'POST',
      url: '/assets/enviarCorreoDemoNuevo',
      signal: abortSignal || null,
      headers: {"Content-Type":"multipart/form-data"},
      data: formData
    })

    const datos = response.data;
    if(datos === 'Error'){
      return new Error(datos);
    }
    return datos || {};

  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * Este servicio envia los datos de solicitud de brochure
 * @async
 * @param {AbortSignal} [options.abortSignal=null] - Objeto AbortSignal para cancelar la solicitud.
 * @param {Object} dataForm - Objeto que contiene la información del formulario
 * @returns {Promise<Object|Error>} Promise que resuelve en un objeto de datos o rechaza con un error.
 */
const solicitarBrochure = async ({ abortSignal = null, dataForm }) => {
  try {
    axios.defaults.baseURL = GENERAL_URL_API;
    axios.defaults.withCredentials = true;

    const formData = new FormData();
    formData.append('nombreP', dataForm?.nombreP ||'');
    formData.append('correoP', dataForm?.correoP || '');
    formData.append('edadP', dataForm?.edadP || '');
    formData.append('telefonoP', dataForm?.telefonoP || '');
 
    formData.append('check1', 'brochure');

    formData.append('paisP', '');
    formData.append('codigoPaisP', '');
    formData.append('ciudadP', '');
    formData.append('institucionP', 'No aplica');
    formData.append('tipoInstitucion', 'No aplica');
    formData.append('institutoCargo', 'No aplica');
    formData.append('referenciaP', 'No aplica');
    formData.append('textoOtroP', '');

    formData.append('tipoSolicitudDemo', 'Nueva solicitud de brochure');


    const response =await axios({
      method: 'POST',
      url: '/assets/enviarCorreoDemoNuevo',
      signal: abortSignal || null,
      headers: {"Content-Type":"multipart/form-data"},
      data: formData
    })

    const datos = response.data;
    if(datos === 'Error'){
      return new Error(datos);
    }
    return datos || {};

  } catch (error) {
    return Promise.reject(error);
  }
}


const suscripcionBoletin = async ({ abortSignal = null, dataForm }) => {
  try {
    axios.defaults.baseURL = GENERAL_URL_API;
    axios.defaults.withCredentials = true;

    const formData = new FormData();
    formData.append('nombreP', dataForm?.nombreP ||'');
    formData.append('correoP', dataForm?.correoP || '');
    formData.append('edadP', dataForm?.edadP || '');
    formData.append('ciudadP', dataForm?.ciudadP || '');
    formData.append('paisP', dataForm?.paisP || '');
    formData.append('codigoPaisP', dataForm?.codigoPaisP || '');
    formData.append('check1', 'suscripción boletín');

    formData.append('institucionP', 'No aplica');
    formData.append('tipoInstitucion', 'No aplica');
    formData.append('telefonoP', 'No aplica');
    formData.append('institutoCargo', 'No aplica');
    formData.append('referenciaP', 'No aplica');
    formData.append('textoOtroP', '');

    formData.append('tipoSolicitudDemo', dataForm?.tipoSolicitudDemo || '');


    const response =await axios({
      method: 'POST',
      url: '/assets/enviarCorreoDemoNuevo',
      signal: abortSignal || null,
      headers: {"Content-Type":"multipart/form-data"},
      data: formData
    })

    const datos = response.data;
    if(datos === 'Error'){
      return new Error(datos);
    }
    return datos || {};

  } catch (error) {
    return Promise.reject(error);
  }
}


const solicitudInformacionPlanes = async ({ abortSignal = null, dataForm }) => {
  try {
    axios.defaults.baseURL = GENERAL_URL_API;
    axios.defaults.withCredentials = true;

    const formData = new FormData();
    formData.append('nombreP', dataForm?.nombreP ||'');
    formData.append('correoP', dataForm?.correoP || '');
    formData.append('edadP', dataForm?.edadP || '');
    formData.append('ciudadP', dataForm?.ciudadP || '');
    formData.append('telefonoP', dataForm?.telP || '');
    formData.append('paisP', dataForm?.paisP || '');
    formData.append('codigoPaisP', dataForm?.codigoPaisP || '');
    formData.append('check1', 'Ser parte de mkmk');

    formData.append('institucionP', 'No aplica');
    formData.append('tipoInstitucion', 'No aplica');
    formData.append('institutoCargo', 'No aplica');
    formData.append('referenciaP', 'No aplica');
    formData.append('textoOtroP', '');

    formData.append('tipoSolicitudDemo', 'Solicitud información planes');


    const response =await axios({
      method: 'POST',
      url: '/assets/enviarCorreoDemoNuevo',
      signal: abortSignal || null,
      headers: {"Content-Type":"multipart/form-data"},
      data: formData
    })

    const datos = response.data;
    if(datos === 'Error'){
      return new Error(datos);
    }
    return datos || {};

  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * Guarda el correo para solicitud de demo con institución inscrita a MakeMake
 * @async
 * @param {AbortSignal} options.abortSignal - Señal de aborto para la solicitud.
 * @param {Object} props.data - Datos de correo y nombre del instituto para la solicitud del demo.
 * @param {string} props.data.email - String con el correo del usuario.
 * @param {string} props.data.institutoNombre - String con el nombre del instituto.
 * @returns {Promise<boolean>} - 'true' si está autenticado, de lo contrario devuelve 'false'.
 * @throws {Error} - Error en caso de fallo al verificar sesión.
 */
const saveEmail = async ({ abortSignal, data }) => {
  try {
      axios.defaults.baseURL = GENERAL_URL_API;
      axios.defaults.withCredentials = true;

      const fullData = new FormData();
      fullData.append('inputCode', data?.email);
      fullData.append('itVal', data?.institutoNombre);

      const response = await axios({
          method: "POST",
          url: `/assetsApp/guardarCorreoDemo`,
          signal: abortSignal.signal || null,
          headers: {
              "Content-Type": "multipart/form-data",
           },
           data: fullData,
      });
      if (
        response?.error ||
        (typeof response === "string" && response?.toLowerCase()?.includes("error"))
      ) {
        throw new Error(response?.error || response);
      }
      return true;
  } catch (error) {
      console.log("API ERROR:", error);
      return Promise.reject(error);
  }
}

export const SolicitudesDemoAPI = {
  solicitarDemoInstitucional,
  suscripcionBoletin,
  solicitudInformacionPlanes,
  saveEmail,
  solicitarBrochure
}