import React from "react";
import { Icon } from "@iconify/react";
import useObtenerSubsecciones from "../../js/translations/useObtenerSubsecciones";
import { Link } from "react-router-dom";
import { ASSETS_URL } from "../Store";

/**
 * Componente de modal para mostrar libros recomendados en el visor.
 *
 * @component
 * @param {Object} props - Propiedades del componente.
 * @param {string} props.idModal - ID del modal.
 * @param {Object} props.datosLibro - Datos del libro.
 * @param {function} props.cerrarModal - Función para cerrar el modal.
 * @returns {JSX.Element} Elemento JSX que representa el componente.
 */
export const ModalVisor = (props) => {
  const { idModal, datosLibro, cerrarModal} = props;

  const { isReady, textosSubSecciones: visor } = useObtenerSubsecciones("visor");

  return (
    <>
      {isReady &&
        <div className="micromodal-slide" id={idModal || ''} aria-hidden="true">
          <div className="modal__overlay" data-micromodal-close>
            <div className="modal__container_recomendados background--bg"
              role="dialog"
              aria-modal="true"
              aria-labelledby="titulo-visor-modal"
            >
              <div className="d-flex flex-row-reverse">
                <button
                  aria-label="Cerrar ventana de dialogo"
                  className="close-button onbackground-primary--color focusable-red"
                  data-micromodal-close onClick={() => cerrarModal()}
                >
                  <Icon icon={'mdi:close-thick'} width={35} />
                </button>
              </div>
              <div className="mb-3">
                <img src={`/img/estrella.png`} alt='estrella' className="mx-auto d-block" />
                <p
                  id="titulo-visor-modal"
                  className="text-recomendados text-center mx-auto col-10"
                >
                  {visor?.recomendados?.descripcion || 'Si te gusta este libro, te puede gustar también:'}
                </p>

                <div className="row justify-content-center mt-3 m-0">
                  <div className="cover-book col-5 w-100">
                    <Link
                      to={datosLibro?.hrefRecomen1 || ''}
                      className="focusable-red"
                    >
                      <img
                        alt="Portada libro recomendado 1"
                        className="mx-auto d-block img-fluid"
                        src={`${ASSETS_URL}${datosLibro?.hrefRecomen1Portada || ''}`}
                      />
                    </Link>
                  </div>
                  <div className="cover-book col-5 w-100">
                    <Link
                      to={datosLibro?.hrefRecomen2 || ''}
                      className="focusable-red"
                    >
                      <img
                        alt="Portada libro recomendado 2"
                        className="mx-auto d-block img-fluid"
                        src={`${ASSETS_URL}${datosLibro?.hrefRecomen2Portada || ''}`}
                      />
                    </Link>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}