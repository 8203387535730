import React from "react";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";

/**
 *  Primer componente de introducción para que es bibliotecas
 * @returns {JSX.Element} Componente Introduction.
 */
export const Introduction = () => {
	const lang = useDefaultLangByCountry({ country: "us", defaultLang: "en" });
	const textosBibliotecas = useTranslations({ section: "que_es_biblioteca", lang: lang });

	return (
		<div className="introduction">
			<div className="container">
				<div className="col-lg-11 mx-auto">
					<div className="row mx-0 align-items-stretch">
						<div className="col-lg-6 px-0 order-lg-2">
							<figure
								className="ilustracion-inicial w-100 h-100"
								alt="ilustración de una niña sosteniendo una tablet"
								style={{
									backgroundImage: `url('/img/bibliotecas-que-es/ilustracion-nina3.png')`,
								}}
							></figure>
						</div>

						<div className="col-lg-6 px-0 py-lg-5 order-lg-1">
							<div className=" pl-2 mt-lg-5 sub-title">
								<h1
									tabIndex={0}
									className="focusable-secondary onbackground-primary--color secondary-font"
									style={{ lineHeight: "1.3em" }}
								>
									{textosBibliotecas?.intro?.titulo ||
										"¡Sorprende, impacta y engancha nuevos usuarios con MakeMake!"}
								</h1>
							</div>
							<p className="onbackground-primary--color col-lg-10 px-0 mt-4 mb-5">
								{textosBibliotecas?.intro?.descripcion ||
									"Encuentra los mejores libros en tu idioma, apóyate en nuestra experiencia y descubre herramientas para promover la lectura en tu biblioteca. "}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
