export const FOOTER_LINKS = [
  {
    text: {
      default: 'Descarga la App',
      translationPath: 'links.app'
    },
    link: 'https://makemake.app',
    external: true
  },
  {
    text: {
      default: 'Pide tu demo',
      translationPath: 'links.demo'
    },
    link: '/tienda/demo'
  },
  {
    text: {
      default: 'Sobre Makina Editorial',
      translationPath: 'links.about_makina'
    },
    link: 'https://www.makinaeditorial.com',
    external: true
  },
  {
    text: {
      default: 'Sobre MakeMake',
      translationPath: 'links.about_makemake'
    },
    link: (isAuth) => isAuth ? '/que-es/loggedin' : '/que-es',
    byAuth: true,
  },
  {
    text: {
      default: 'Bibliotecas',
      translationPath: 'links.bibliotecas'
    },
    link: '/que-es/bibliotecas'
  },
  {
    text: {
      default: 'Colegios',
      translationPath: 'links.colegios'
    },
    link: '/que-es/colegios'
  },
  {
    text: {
      default: 'Tienda',
      translationPath: 'links.tienda'
    },
    link: '/tienda'
  },
  {
    text: {
      default: 'Políticas de accesibilidad y privacidad de datos',
      translationPath: 'links.privacidad'
    },
    link: '/politica-de-privacidad'
  },
  {
    text: {
      default: 'Términos y condiciones',
      translationPath: 'links.terminos_condiciones'
    },
    link: '/terminos-y-condiciones'
  },
  {
    text: {
      default: 'Política de soporte',
      translationPath: 'links.politica_soporte'
    },
    link: '/politica-de-soporte'
  },
  {
    text: {
      default: 'Open Standard for EdTech Integrations',
      translationPath: 'links.edtechstandard'
    },
    link: '/edtech-open-standard'
  },
  // {
  //   text:{
  //     default:'Preguntas frecuentes',
  //     translationPath:''
  //   },
  //   link:'/'
  // },
]