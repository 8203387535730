import React, { useEffect, useState } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { useQueryParams } from "../hooks/useQueryParams";
import { CancelRequestController } from "../util/cancel-request-controller";

import { DatosFormInstitutoAPI } from "../services/api/LoginInstitucional";
import { Icon } from "@iconify/react";

import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";

//componentes
import CardNumber from "./CardNumber";
import Form from "./Form";
import FormValle from "./FormValle";

/**
 * Componente que no recibe Props. Su principal función es renderizar el componente correspondiente al tipo de formulario. El parametro de  la url  que es usado para acceder a estos formularios es institucional/formulario?inst=NombreInstituto. Este componente, también se encarga de obtener la información del instituto por el cual se debe acceder. Este hace uso de los componentes CardNumber, Form o FormValle.
 * @returns {JSX.Element} CardNumber, Form o FormValle
 */
const Institucional = () => {
  const [preloader, setPreloader] = useState(true);
  const [datosInstituto, setDatosInstituto] = useState({})
  const [instituto, setInstituto] = useState('')

  const { isReady, textosSubSecciones: traducciones } = useObtenerSubsecciones('login');

  const history = useHistory();
  const params = useQueryParams();

  useEffect(() => {
    const institucion = params.get('inst');
    setInstituto(institucion);

    let abortController = new CancelRequestController();

    if (!!institucion) {
      const instituto = decodeURIComponent(institucion);

      const datos = async () => {
        let datos = {};
        await DatosFormInstitutoAPI.obtenerDatos({
          abortSignal: abortController.signal,
          data: instituto
        })
          .then(respuesta => {
            const error = respuesta.status;
            if (error) {
              for (const key in respuesta) {
                const value = respuesta[key];
                datos[key] = value === 'null' || value === '' ? null : value;
              }

              setDatosInstituto({
                ...datos,
                instituto: instituto
              })
              setPreloader(false)
            } else {
              history.push('/404')
            }

          })
          .catch(error => console.log(error));
      }
      datos();
    } else {
      history.push('/404')
    }

    return () => {
      abortController.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])


  return (
    <>
      {isReady &&
        <div className="main-container mm-redimir" style={{minHeight: '70vh'}}>
          <div className="container main-content px-0 pb-0">
            <div className="contenedor-altura-minima">
              {preloader ?
                <div
                  className="secondary-inverse--color d-flex justify-content-center align-items-center"
                  style={{ minHeight: '400px' }}>
                  <Icon icon="icomoon-free:spinner9" width={60} className={'spinner-rotation'} />
                </div>
                :
                <Switch>
                  <Route exact path='/institucional/formulario'>
                    {
                      datosInstituto?.data?.tipoFormulario === 'cardNumber' &&
                      <CardNumber
                        datosInstituto={datosInstituto?.data}
                        instituto={instituto}
                        traducciones={traducciones}
                      />
                    }
                    {
                      datosInstituto?.data?.tipoFormulario === 'form' &&
                      <Form instituto={instituto} traducciones={traducciones} />
                    }
                    {
                      datosInstituto?.data?.tipoFormulario === 'formExten' &&
                      <FormValle instituto={instituto} traducciones={traducciones} />
                    }
                  </Route>
                </Switch>
              }

            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Institucional;
