/**
 * arreglo con todas las materias que hay en MakeMake
 */
export const SUBJECTS = [
    {
        id: 'Artes y manualidades',
        type:'tema',
        name: {
            defaultValue: 'Artes y manualidades',
            translationPath:'content.seccion.materias.artes_manualidades'
        },
        imgPath:'/img/materias/artes.png',

    },
    {
        id: 'Historia y ensayo',
        type:'tema',
        name: {
            defaultValue: 'Ciencias Sociales',
            translationPath:'content.seccion.materias.ciencias_sociales'
        },
        imgPath:'/img/materias/ciencias-sociales.png',
    },
    {
        id: 'Canción y poesía',
        type:'tema',
        name: {
            defaultValue: 'Canción y poesía',
            translationPath:'content.seccion.materias.cancion_poesia'
        },
        imgPath:'/img/materias/cancion.png',
    },
    {
        id: 'Letras y números',
        type:'tema',
        name: {
            defaultValue: 'Letras y números',
            translationPath:'content.seccion.materias.letras_numeros'
        }
        ,
        imgPath:'/img/materias/letras.png',
    },
    {
        id: 'Narrativa',
        type:'tema',
        name: {
            defaultValue: 'Narrativa',
            translationPath:'content.seccion.materias.narrativa'
        },
        imgPath:'/img/materias/narrativa.png',
        mostSearched:true,
        position:2
    },
    {
        id: 'Ciencias',
        type:'tema',
        name: {
            defaultValue: 'Ciencias',
            translationPath:'content.seccion.materias.ciencias'
        },
        imgPath:'/img/materias/ciencias.png',
        mostSearched:true,
        position:4
    },
]