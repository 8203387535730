import React, { useContext, useState, useEffect } from "react";
import { store, getAllFromIndexDB,diasVencimientoDescarga, } from "../components/Store.js";
import { Link,  useLocation } from "react-router-dom";
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";

/**
 * Componente para mostrar información y acciones relacionadas a un libro descargado offline, al dar click en la portada del libro es redirigido al visor para que renderice de manera offline el correspondiente libro.
 *
 * @component
 * @example
 * import { LibroOffline } from "./LibroOffline";
 * 
 * function App() {
 *   return (
 *     <div>
 *       <LibroOffline idLibro="123" />
 *     </div>
 *   );
 * }
 *
 * @param {Object} props - Propiedades del componente.
 * @param {string} props.idLibro - ID del libro descargado.
 * @returns {JSX.Element} Componente LibroOffline.
 */
export const LibroOffline = (props) => {
    const { isReady, textosSubSecciones: descargados } = useObtenerSubsecciones('descargados');
    const globalState = useContext(store);
    const { state } = globalState;
    const [datosLibro, setDatosLibro] = useState("");
    const [idLibro, setIdLibro] = useState("");
    const [leerDisabled, setLeerDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [fechaVencimientoDescarga, setFechaVencimientoDescarga] = useState(
      ""
    );
    const isLogged = state.sesionActiva !== null && state.sesionActiva === "SI";
    const location = useLocation()

    useEffect(() => {
      let id = props.idLibro;
      let numeroArchivosContenido = 0;
      getAllFromIndexDB("tipo", "metadatos").then(function (results) {
        results.forEach((result) => {
          if (result.idLibro === id && result.username === state.username) {
            numeroArchivosContenido = result.metadatos.Archivos.length;/////////////////////
            getAllFromIndexDB("tipo", "portada").then(function (
              resultsPortada
            ) {
              resultsPortada.forEach((resultPortada) => {
                if (resultPortada.idLibro === id) {
                  let metadatos = result.metadatos;
                  metadatos.portada = resultPortada.url;//window.URL.createObjectURL(resultPortada.blob);
                  let fechaVencimientoDescargaTemp = new Date();
                  fechaVencimientoDescargaTemp.setDate(
                    result.fecha.getDate() + diasVencimientoDescarga
                  );

                  setFechaVencimientoDescarga(
                    fechaVencimientoDescargaTemp.toLocaleDateString(
                      navigator.language
                    )
                  );
                  //Prueba Verificacion archivos
                  getAllFromIndexDB("idLibro", id).then(
                    function (resultsContenido) {
                      let numeroArchivosContenidoIndexed = 0;
                      resultsContenido.forEach((resultContenido) => {
                        if (resultContenido.tipo === "contenido") {
                          numeroArchivosContenidoIndexed++;
                        }
                      }
                      );
                      //console.log("Indexed " + id + ": " + numeroArchivosContenidoIndexed)/////////////////
                      //console.log("En metadatos: " + id + ": " + numeroArchivosContenido)////////////////
                      // if (numeroArchivosContenidoIndexed >= numeroArchivosContenido) {
                        setIsLoading(false);
                        setDatosLibro(metadatos);
                        setLeerDisabled(false);
                      // }
                      // else {
                      //   props.eliminarLibro && props.eliminarLibro(id);
                      // }
                    }
                  );

                }
              });
            });
          }
        });
      });

      setIdLibro(id);
    }, [props.idLibro, state]);

    return (
      <>
        {isLoading && (
          <>
            <div className="row mx-2 my-4 pb-3 py-4 libro-descargado">
              <div className="row mx-0 relative col-12" style={{ minHeight: "800px" }}>
                <div
                  className={`statistics-preloader `}
                ></div>
              </div>
            </div>
          </>
        )}
        {!isLoading && (
          <div tabIndex={0} className="focusable-secondary row mx-2 my-4 pb-3 py-4 libro-descargado">
            <div className="col-md-5 col-lg-3 d-flex resource-content pr-lg-5 pl-0 mb-1">
              <img
                role={"presentation"}
                className="img-fluid cover mx-auto mx-sm-0"
                src={datosLibro.portada}
                alt="Portada del recurso"
              />
            </div>
            <div className="col-md-7 col-lg-9 resource-content px-0 pl-lg-2 d-flex flex-column  ">
              <div>
                <h3 tabIndex={0} className="focusable-secondary mb-0 titulo">{datosLibro.titulo || 'N/A'}</h3>
                <p tabIndex={0} className="focusable-secondary mb-1 autor">{datosLibro.autor || 'N/A'}</p>

                <p tabIndex={0} className="focusable-secondary tiempo-disponible">
                  {descargados?.libro?.disponible || 'Disponible hasta: '}
                  <span className="primary-item"> {fechaVencimientoDescarga}</span>
                </p>
              </div>
              {/* <p className="mb-1 secondary-item bold">{datosLibro.editorial}</p> */}

              {/* <p className="mb-3">{datosLibro.descripcion}</p> */}
              <div className="btn-leer d-flex d-sm-block">
                {(() => {
                  let href = isLogged ? `/visor/${idLibro}?returnUrl=${encodeURIComponent(location.pathname)}` : "/login";
                  return (
                    <Link className="focusable-primary mx-auto mx-sm-0 btn btn-primary px-4 py-2 mt-3 mb-3 mb-sm-0" to={href}>
                        {descargados?.libro?.boton_leer ||'Leer'}
                    </Link>
                  );
                })()}
              </div>
              <div>
                <button
                  className="focusable-primary btn btn-eliminar mt-3"
                  onClick={() => {
                    props.mostrarMensajeEliminarLibro(datosLibro)
                  }}
                >
                  {descargados?.libro?.boton_eliminar || 'Eliminar de mis descargas'} <i className="far fa-trash-alt"></i>
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };