/**
 * arreglo con todos los generos que hay en MakeMake
 */
export const GENRES =  [
    {
        id: 'Teatro',
        type:'genero',
        name: {
            defaultValue: 'Teatro',
            translationPath:'content.seccion.generos.teatro'
        },
        imgPath:'/img/generos/teatro.png',
    },
    {
        id: 'Libro álbum',
        type:'genero',
        name: {
            defaultValue: 'Libro álbum',
            translationPath:'content.seccion.generos.libro_album'
        },
        imgPath:'/img/generos/libro-album.png',
    },
    {
        id: 'Cuento ilustrado',
        type:'genero',
        name: {
            defaultValue: 'Cuento ilustrado',
            translationPath:'content.seccion.generos.cuento_ilustrado'
        },
        imgPath:'/img/generos/cuento-ilustrado.png',
    },
    {
        id: 'Poesía',
        type:'genero',
        name: {
            defaultValue: 'Poesía',
            translationPath:'content.seccion.generos.poesia'
        },
        imgPath:'/img/generos/poesia.png',
    },
    {
        id: 'Crónica y ensayo',
        type:'genero',
        name: {
            defaultValue: 'Crónica',
            translationPath:'content.seccion.generos.cronica'
        },
        imgPath:'/img/generos/cronica.png',
    },
    {
        id: 'Informativo',
        type:'genero',
        name: {
            defaultValue: 'Informativo',
            translationPath:'content.seccion.generos.informativo'
        },
        imgPath:'/img/generos/informativo.png',
    },
    {
        id: 'Cuento',
        type:'genero',
        name: {
            defaultValue: 'Cuento',
            translationPath:'content.seccion.generos.cuento'
        },
        imgPath:'/img/generos/cuento.png',
    },
    {
        id: 'Didáctico',
        type:'genero',
        name: {
            defaultValue: 'Didáctico',
            translationPath:'content.seccion.generos.didactico'
        },
        imgPath:'/img/generos/didactico.png',
    },
    {
        id: 'Interactivo',
        type:'genero',
        name: {
            defaultValue: 'Interactivo',
            translationPath:'content.seccion.generos.interactivo'
        },
        imgPath:'/img/generos/interactivo.png',
        mostSearched:true,
        position:1
    },
    {
        id: 'Novela',
        type:'genero',
        name: {
            defaultValue: 'Novela',
            translationPath:'content.seccion.generos.novela'
        },
        imgPath:'/img/generos/novela.png',
        mostSearched:true,
        position:5
    },
    {
        id: 'Cómic',
        type:'genero',
        name: {
            defaultValue: 'Cómic',
            translationPath:'content.seccion.generos.comic'
        },
        imgPath:'/img/generos/comic.png',
        mostSearched:true,
        position:3
    },
]