import { Icon } from "@iconify/react";
import React from "react";

/**
 * Componente de botones de navegación para swiper.
 * @param {Object} props - Props del componente.
 * @param {React.Ref} props.prevButtonRef - Referencia al botón de navegación anterior.
 * @param {React.Ref} props.nextButtonRef - Referencia al botón de navegación siguiente.
 * @returns {JSX.Element} Elemento JSX que representa los botones de navegación.
 */
export const NavigationButtons = ({prevButtonRef, nextButtonRef}) => {
    return (<div className="d-none d-md-flex justify-content-center align-items-center">
        <button ref={prevButtonRef} aria-label="previous" className="home-swiper-navigation-buttons mx-2">
            <Icon className="icon" icon="material-symbols:arrow-back-rounded" width={20} />
        </button>
        <button ref={nextButtonRef} aria-label="next" className="home-swiper-navigation-buttons">
            <Icon className="icon" icon="material-symbols:arrow-forward-rounded" width={20} />
        </button>
    </div>)
}