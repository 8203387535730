import React from 'react';
import { useConexion } from '../hooks/useConexion';
import useObtenerSubsecciones from '../js/translations/useObtenerSubsecciones';

/**
 * Componente que muestra el estado de la conexión a internet.
 *
 * @component
 * @returns {JSX.Element} Elemento JSX que representa el componente.
 */
export const BarraConexion = () => {
    const conexion = useConexion();
    const {textosSubSecciones:traducciones} = useObtenerSubsecciones('global');


    return (
        <>
            {conexion?.online === false &&
                <div className='barra-conexion offline' role={"alert"}>
                    {traducciones?.internet?.conexion?.estado?.sin_conexion || 'No hay conexion a internet'}
                </div>
            }
            {conexion?.online === true &&
                <div className='barra-conexion online auto-hide' role={"alert"}>
                    {traducciones?.internet?.conexion?.estado?.conectado || 'Conectado a internet'}
                </div>
            }
        </>
    )
}

