import React from "react"
import { Img } from "../components/shared/img"
import ProptTypes from 'prop-types';

/**
 * tarjeta botón que muestra la información de una colección.
 */
export const CuraduriaCard = ({ data, onClick, imgProps }) => {
    return <button className="focusable-secondary curaduria-card p-2" onClick={() => onClick && onClick()}>
        <div className="image-container" >
          <Img {...imgProps} className={`img-fluid curadurias-img ${imgProps?.className}`} src={`${data?.imgUrl}`} alt={data?.nombre ||'N/A'}/>
        </div>
        <div className="p-2">
        <p className="w-100 title text-center secondary-font py-3">{data?.nombre || 'N/A'}</p>
        <p className="w-100 description text-left">{data?.descripcion || 'N/A'}</p>
        </div>
    </button>
}

CuraduriaCard.propTypes = {
  /**
   * Objeto con la info de la colección.
   */
  data:ProptTypes.shape({
    nombre:ProptTypes.string,
    descripcion:ProptTypes.string,
    imgUrl:ProptTypes.string,
  }).isRequired,
  /**
   * objeto con props de elemento img que se pasan a la imagen
   */
  imgProps: ProptTypes.object,
  /**
   * Evento click para el botón (tarjeta)
   */
  onClick:ProptTypes.func
}
