import React, { useState } from 'react';
import PropTypes from "prop-types";
import { Img } from '../../components/shared/img';
import FsLightbox from 'fslightbox-react';

/**
 * Este componente renderiza la segunda sección del club MakeMake que corresponde a la información sobre los planes de lecturas 
 * y recibe como props los textos de la interfaz. Se hace uso del componente ModalClub para la visualización de PDF.
 */

const PlanLectura = (props) => {

    const { traducciones } = props;
    const [planLectura1, setPlanLectura1] = useState(false);
    const [planLectura2, setPlanLectura2] = useState(false);
    const [planLectura3, setPlanLectura3] = useState(false);

    return (
        <div className="seccion-morado-claro pt-5" id="plan-acompanamiento">
            <div className="container">
                <div className=" col-lg-10 mx-auto">
                    <h2 className="titulos-actualizacion-club titulo-morado-act">{traducciones?.titulo || 'Plan de lecturas MakeMake'}</h2>

                    <p className="titulo-plan-lecturas text-center text-lg-left pt-3" ><img src="/img/club-makemake/icon-1.png" className="img-fluid " />
                        {traducciones?.fecha || 'Febrero 2023 a Enero 2024'}
                    </p>

                    <p className='pt-5 px-4 px-lg-2 parrafo-acompanamiento onbackground-third--color'>
                        {traducciones?.parrafo_1 || 'Acompañamiento lector a lo largo del año que propone abrir espacios de conversación y discusión. Cada mes presentaremos una pregunta acompañada de 3 libros recomendados los cuales contarán con su respectiva actividad.'}
                    </p>

                    <div className="col-lg-10 mx-auto">
                        <button onClick={() => setPlanLectura1(!planLectura1)}>
                            <Img 
                                className='w-100' 
                                src={traducciones?.plan1 || '/img/club-makemake/plan-lectura/plan-lectura-1.jpg'}
                                alt={traducciones?.plan_alt1 ||  `plan de lecturas 1` } />
                        </button>
                        <FsLightbox
                            toggler={planLectura1}
                            disableLocalStorage={true}
                            disableSlideSwiping={true}
                            type="image"
                            sources={[traducciones?.plan1 || '/img/club-makemake/plan-lectura/plan-lectura-1.jpg']}
                        />

                        <button onClick={() => setPlanLectura2(!planLectura2)}>
                            <Img 
                                className='w-100' 
                                src={traducciones?.plan2 || '/img/club-makemake/plan-lectura/plan-lectura-2.jpg'}
                                alt={traducciones?.plan_alt2 ||  `plan de lecturas 2` } 
                            />
                        </button>
                        <FsLightbox
                            toggler={planLectura2}
                            disableLocalStorage={true}
                            disableSlideSwiping={true}
                            type="image"
                            sources={[traducciones?.plan2 || '/img/club-makemake/plan-lectura/plan-lectura-2.jpg']}
                        />

                        <button onClick={() => setPlanLectura3(!planLectura3)}>
                            <Img 
                                className='w-100' 
                                src={traducciones?.plan3 || '/img/club-makemake/plan-lectura/plan-lectura-3.jpg'}
                                alt={traducciones?.plan_alt3 ||  `plan de lecturas 3` } 
                            />
                        </button>
                        <FsLightbox
                            toggler={planLectura3}
                            disableLocalStorage={true}
                            disableSlideSwiping={true}
                            type="image"
                            sources={[traducciones?.plan3 || '/img/club-makemake/plan-lectura/plan-lectura-3.jpg']}
                        />
                    </div>

                    <div className='row col-lg-10 mx-auto justify-content-around pb-5'>
                        <div className='text-descargar col-12 col-md-6'>
                            <p className='text-center text-md-left pb-5 pb-md-0'>
                                {traducciones?.descarga?.parrafo_descarga || 'Descarga el plan completo del 2024 y programa tus lecturas con MakeMake'}
                            </p>
                        </div>
                        <div className='col-12 col-md-6 d-flex align-items-center pb-3 pb-md-0'>
                            <a
                                href={traducciones?.link_descarga || "/document/plan_lecturas_2024.pdf" }
                                download={traducciones?.descarga?.doc || 'Plan lecturas 2024'}
                                className='btn-descargar-act col-lg-12 py-2 focusable-red mx-auto d-block'
                            >
                                {traducciones?.descarga?.boton_descarga || 'Plan lecturas 2024'}
                            </a>
                        </div>  
                    </div>

                    <div className="col-lg-10 mx-auto px-3 pb-5">
                        <p className="texto-especificacion-act"><span>{traducciones?.disponible?.uno || '* Disponible solo para suscriptores institucionales.'}</span> {traducciones?.disponible?.dos || 'Si tu colegio o biblioteca hace parte de MakeMake tendrás acceso a los libros recomendados durante el mes que estén anunciados.'}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PlanLectura;

PlanLectura.propTypes = {
    /**
     * Es un objeto que trae los textos de la interfaz, especificamente los de mi cuenta. Estos cambian dependiendo del idioma activo.
     */
    traducciones: PropTypes.object.isRequired
}