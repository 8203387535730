export const AVAILABLE_LANGUAGES = {
	en: "en",
	es: "es",
};

export const AVAILABLE_LANGUAGES_ARRAY = Object.values(AVAILABLE_LANGUAGES);

export const LANGUAGE_RULE_COUNTRIES = [
	// Europa
	"AL", // Albania
	"DE", // Alemania
	"AD", // Andorra
	"AM", // Armenia
	"AT", // Austria
	"AZ", // Azerbaiyán
	"BE", // Bélgica
	"BY", // Bielorrusia
	"BA", // Bosnia y Herzegovina
	"BG", // Bulgaria
	"CY", // Chipre
	"VA", // Ciudad del Vaticano
	"HR", // Croacia
	"DK", // Dinamarca
	"SK", // Eslovaquia
	"SI", // Eslovenia
	"ES", // España
	"EE", // Estonia
	"FI", // Finlandia
	"FR", // Francia
	"GE", // Georgia
	"GR", // Grecia
	"HU", // Hungría
	"IE", // Irlanda
	"IS", // Islandia
	"IT", // Italia
	"KZ", // Kazajistán (Parte de su territorio se encuentra en Europa)
	"LV", // Letonia
	"LI", // Liechtenstein
	"LT", // Lituania
	"LU", // Luxemburgo
	"MK", // Macedonia del Norte
	"MT", // Malta
	"MD", // Moldavia
	"MC", // Mónaco
	"ME", // Montenegro
	"NO", // Noruega
	"NL", // Países Bajos
	"PL", // Polonia
	"PT", // Portugal
	"GB", // Reino Unido
	"CZ", // República Checa
	"RO", // Rumania
	"RU", // Rusia (Parte de su territorio se encuentra en Europa)
	"SM", // San Marino
	"RS", // Serbia
	"SE", // Suecia
	"CH", // Suiza
	"UA", // Ucrania
	"TR", // Turquía (Parte de su territorio se encuentra en Europa)
	"BY", // Bielorrusia

	// America
	"US",
];
