import React, { useContext, useState, useEffect, lazy, Suspense } from "react";
import { Switch, Route, useHistory } from "react-router-dom";

//Componentes
import { store } from '../components/Store.js';
import MenuPanel from "./MenuPanel";

import { Icon } from "@iconify/react";

const MiCuenta = lazy(() => import('./MiCuenta/'));
const MiColeccion = lazy(() => import('./MiColeccion'));
const Estadisticas = lazy(() => import('./Estadisticas/index.js'));
const Recursos = lazy(() => import('./Recursos'));
const Soporte = lazy(() => import('./Soporte/'));

/**
 * Este componente no recibe Props. Este se encarga de renderizar la sección de mi panel una vez se encuentre el usuario logueado. Su principal función es mostrar el contenido indicado según la opción elegida en MenuPanel. Este componente hace uso de MenuPanel, MiCuenta, MiColeccion, Estadisticas Recursos y Soporte.
 * @returns {JSX.Element} MiCuenta, MiColeccion, Estadisticas Recursos o Soporte
 */
const MiPanel = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const [isLoading, setIsloading] = useState(true);

  const history = useHistory()

  useEffect(() => {
    if (state.sesionActiva !== "SI") {
      history.push('/');
    }
  }, [])

  useEffect(() => {
    if (state.isHeaderShow === true && isLoading) {
      setIsloading(false);
    }
  }, [isLoading, state.isHeaderShow])

  return (<>
    {
      isLoading ?
        <>
        </>
        :
        <>
          <div className="main-container py-5">
            <div className="mx-auto container ">
              {/*MENÚ*/}
              <MenuPanel />
            </div>

            <div className="container main-content p-3 p-lg-5 mx-auto">
              <Suspense
                fallback={
                  <div className="secondary-inverse--color d-flex justify-content-center align-items-center" style={{ minHeight: '800px' }}>
                    {/* <div className={`statistics-preloader`} /> */}
                    <Icon icon="icomoon-free:spinner9" width={60} className={'spinner-rotation'} />
                  </div>
                }
              >

                <Switch>
                  <Route exact path='/mi-panel/mi-cuenta' component={MiCuenta} />
                  <Route exact path='/mi-panel/mi-coleccion' component={MiColeccion} />
                  <Route exact path='/mi-panel/estadisticas' component={Estadisticas} />
                  <Route exact path='/mi-panel/estadisticas/:subsection' component={Estadisticas} />
                  <Route exact path='/mi-panel/estadisticas/:subsection/:id' component={Estadisticas} />
                  <Route exact path='/mi-panel/recursos/' component={Recursos} />
                  <Route exact path='/mi-panel/soporte' component={Soporte} />
                </Switch>
              </Suspense>
            </div>
          </div>
        </>
    }
  </>)
  // return <></>
}


export default MiPanel;