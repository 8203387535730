import { Icon } from "@iconify/react";
import React from "react";
import { useHistory } from "react-router-dom";
import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../hooks/use-translations";

export const BackButtonHeader = ({ customLink }) => {
	const history = useHistory();
	const lang = useDefaultLangByCountry({ country: "us", defaultLang: "en" });
	const textos = useTranslations({ section: "autenticacion_institucional", lang: lang });

	const handleBackButton = () => {
		if (customLink) {
			history.push(customLink || "/home");
		} else {
			history.goBack();
		}
	};
	return (
		<div className="back-button-header w-100 d-flex justify-content-start align-items-center my-4 m-2">
			<button
				className="back-button p-2 d-flex justify-content-center align-items-center focusable-primary"
				onClick={() => handleBackButton()}
			>
				<Icon icon="teenyicons:arrow-left-solid" width={28} className="mx-1" />
				{textos?.global?.boton?.volver || "Volver"}
			</button>
		</div>
	);
};
