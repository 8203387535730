
import MicroModal from 'micromodal'
import React, { useState, useRef } from 'react';
import { usePdf } from '@mikecousins/react-pdf';
import useObtenerSubsecciones from "../../js/translations/useObtenerSubsecciones";
import { Icon } from "@iconify/react";

/**
 * Componente de modal para mostrar el club de lectura.
 *
 * @component
 * @param {Object} props - Propiedades del componente.
 * @param {string} props.texto - Texto del plan de lecturas.
 * @param {string} props.anio - Año del plan de lecturas.
 * @param {string} props.rutaPDF - Ruta del archivo PDF.
 * @param {string} props.fileName - Nombre del archivo PDF.
 * @returns {JSX.Element} Elemento JSX que representa el componente.
 */
const ModalClub = (props) => {

    const { texto, anio, rutaPDF, fileName } = props
    const { isReady, textosSubSecciones: textosClubModal } = useObtenerSubsecciones('club_makemake');

    const cerrarModal = () => {
        MicroModal.close('modal-club', {
            awaitCloseAnimation: true
        });
    }

    const [page, setPage] = useState(1);
    const canvasRef = useRef(null);

    const { pdfDocument, pdfPage } = usePdf({
        file: `${rutaPDF}`,
        page,
        canvasRef,
    });

    return (
        <>
            <div className="micromodal-slide" id='modal-club' aria-hidden="true">
                <div className="modal__overlay" data-micromodal-close>
                    <div className="modal__container_club"
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby="titulo-visor-modal"
                    >
                        <div className='d-flex justify-content-between align-items-center pb-3 contenedor-planes mx-auto'>
                            <div>
                                <button className='min-botton d-flex justify-content-center align-items-center' onClick={() => { cerrarModal(); setPage(1); }}>
                                    <i className='icono-minus fas fa-times fa-2x fa-fw' />
                                </button>
                            </div>
                            <div className='w-30'>
                                <div className='titulo-plan px-4 py-3 text-center'>
                                    {texto || 'Plan lecturas'}<br></br><span>{anio || '2020'}</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            {!pdfDocument &&
                                <div
                                    className="secondary-inverse--color d-flex justify-content-center align-items-center" style={{ height: '400px' }}>
                                    <Icon icon="icomoon-free:spinner9" width={60} className={'spinner-rotation'} />
                                </div>
                            }
                            <div className='d-flex justify-content-center'>
                                <canvas ref={canvasRef} className='canvas-planes' />
                            </div>

                            {Boolean(pdfDocument && pdfDocument.numPages) && (
                                <div className="pager bg-info  d-flex justify-content-around contenedor-planes mx-auto pt-3">
                                    <div className="previous flex-grow-1">
                                        <button disabled={page === 1} onClick={() => setPage(page - 1)}>
                                            <i className="icono-page fas fa-caret-left fa-2x fa-fw"></i>
                                        </button>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <a
                                            href={rutaPDF}
                                            download={fileName}
                                            className="px-3 py-2 text-center focusable-red btn-planes"
                                        >
                                            {textosClubModal?.ventana_modal?.descarga}
                                        </a>
                                    </div>
                                    <div className="next">
                                        <button
                                            disabled={page === pdfDocument.numPages}
                                            onClick={() => setPage(page + 1)}
                                        >
                                            <i className="icono-page fas fa-caret-right fa-2x fa-fw" />
                                        </button>
                                    </div>

                                </div>
                            )}

                        </div>

                    </div>
                </div>
            </div>
        </>

    )
}

export default ModalClub
