import { useContext } from 'react';
import { store } from '../components/Store';
import getUrlParam from '../js/utilities/getUrl';



/**
 * Hook que se utiliza para ir a la pasarela de pagos de epayco.
 * @return {{
 * buy:(data:{nombre:string, descripcion:string, precio:{usd:number, cop:number}})
 * }}
 */
export const useEpayco = () => {
  const globalState = useContext(store);
  const country = globalState?.state?.pais;

  /**
   * Método que gestiona y maneja la lógica con respecto a pagos con epayco.
   */
  const buy = (data = { nombre: 'N/A', descripcion: 'N/A', precio: { usd: 0, cop: 0 }, descuento:{} }) => {
    const isOffer = data?.descuento?.activado;
    let finalPrice = 0;
    if(isOffer){
      finalPrice = country !== 'CO'? data?.descuento?.usd?.precio:  data?.descuento?.cop?.precio;
    }else{
      finalPrice = country !== 'CO'? data?.precio?.usd:data?.precio?.cop;
    }

    let datosEpayco = {
      //Parametros compra (obligatorio)
      name: data?.nombre,
      description: data?.descripcion,
      //invoice: "1234",
      currency: country !== 'CO' ? "usd" : "cop",
      amount: finalPrice,
      tax_base: "0",
      tax: "0",
      country: "co",
      lang: country==='US'?'en':'es',

      // //Onpage="false" - Standard="true"
      Onpage: "false",
      external: "true",
      //Atributos opcionales
      confirmation:`${window.location.origin}/tienda/compra/estado-transaccion`,
      response: `${window.location.origin}/tienda/compra/estado-transaccion`,
      //acepted: 'https://makemake.com.co/compra/resultado-transaccion?status=acepted',
      //rejected: 'https://makemake.com.co/compra/resultado-transaccion?status=rejected',
      //pending: 'https://makemake.com.co/compra/resultado-transaccion?status=pending',
      //Atributos cliente
      //email_billing: 'aecheverry@makinaeditorial.com',
      //name_billing: "Andrés Echeverry",
      type_doc_billing: "cc",
      //mobilephone_billing: "3050000000",
      //number_doc_billing: "100000000",

      //atributo deshabilitación metodo de pago
      //methodsDisable: ["PSE","SP","CASH","DP"]
      methodsDisable: getUrlParam('pais') === 'EC' ? ["PSE", "SP", "CASH", "DP"] : []
    }


    window.ga('ec:addProduct', {
      'id': data?.nombre,
      'name': data.description
    });

    window.ga('ec:setAction', 'click');

    // Send click with an event, then send user to product page.
    window.ga('send', 'event', 'UX', 'click', 'Results', {
      hitCallback: function () {
        if (window.epaycoHandler) {
          window.epaycoHandler.open(datosEpayco)
        }
      }
    });
  }

  return {
    buy
  }

}

