import React from "react";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";

export const LapizAcero = () => {
	const lang = useDefaultLangByCountry({ country: "us", defaultLang: "en" });
	const texts = useTranslations({ section: "footer", lang: lang });
	return (
		<div className="d-flex flex-column justify-content-center align-items-center">
			<p className="primary-inverse--color font-weight-bold">
				{texts?.lapiz_acero?.titulo || "Mejor sitio web 2023"}
			</p>
			<a
				className="mt-2"
				style={{ maxWidth: 200 }}
				href="https://www.lapizdeacero.org/winners/biblioteca-digital-makemake/"
				target="_blank"
				rel={"noopener noreferrer"}
			>
				<img
					className="img-fluid p-2"
					style={{ backgroundColor: "#fff", borderRadius: ".5rem" }}
					src="/img/lapiz-acero.png"
					alt="Lapíz de acero"
				/>
			</a>
		</div>
	);
};
