import React from 'react';
import PropTypes from "prop-types";

/**
 * Este componente renderiza la primera sección del club MakeMake que corresponde a la introducción del club y recibe como props los textos de la interfaz.
 */

const IntroduccionClub = (props) => {

    const { traducciones } = props;

    return (
        <div className="container pb-5">
            <div className='row mx-0 py-5 justify-content-center'>
                <div className='col-md-7 px-4'>
                    <strong className="parrafo-especial-act text-left onbackground-third--color">
                        {traducciones?.parrafo_int || 'MakeMake te apoya en el salón de clases y en la biblioteca. Conoce el Plan anual de lecturas y nuestra programación en vivo con autores y artistas, y ¡lee todo el año! '}
                    </strong>
                </div>
                <div className='col-md-3 px-3 px-lg-0 mt-5 mt-md-0 d-flex align-items-center justify-content-center'>
                    <a className="icon-actualizacion-abajo focusable-red" href="#plan-acompanamiento">
                        <img src="/img/club-makemake/icon-abajo2.png" alt="enlace directo a plan de acompañamiento" />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default IntroduccionClub;

IntroduccionClub.propTypes = {
    /**
     * Es un objeto que trae los textos de la interfaz, especificamente los de mi cuenta. Estos cambian dependiendo del idioma activo.
     */
    traducciones: PropTypes.object.isRequired
}
