import React, { useState, useEffect } from "react";
import { STORE_PLANS_CARDS_DATA } from "../data/plans-cards-data";
import { TarjetaPlanes, CardHeader, PriceDescription, FeaturesList, TitleHeader } from "./TarjetaPlanes";
import { useHistory } from "react-router-dom";

/**
 * Sección que muestra las tarjetas de los diferentes planes de la tienda individual, 
 * teniendo en cuenta los tipos de planes: mensual, trimestral y semestra.
 * Dependiendo del plan elegido, se llevará al usuario a la sección correspondiente.
 */
export const Planes = () => {
  const plans = STORE_PLANS_CARDS_DATA.individual.planes;

  const history = useHistory();

  const goToPlan = ({ pathname, search }) => {
    history.push({
      pathname: pathname || '/',
      search: search || ''
    })
  }



  return (
    <>
      <div className="container  px-md-0 py-5">
        <div className="d-md-flex mx-0 my-3">
          <div className="col-md-6 col-lg-5 mb-5 mb-md-0">
          </div>
          <div className="col-md-6 col-lg-7">
          </div>
        </div>
        <div className="row justify-content-lg-center">
          {
            plans.map(plan => {
              let precio = plan?.precio;
              let descuento = plan?.descuento;
              return <div className="col-lg-6 mb-3" key={plan.id}>
                <TarjetaPlanes
                  isFavorite={plan.personalizado}
                  onClickBuyButton={() => goToPlan(plan?.sectionUrl)}
                  planID={plan.id}
                >
                  <TitleHeader
                    title={plan.nombre}
                  />
                  <CardHeader
                    image={plan.imgUrl}
                    colorPlan={plan.className}
                  />

                  <FeaturesList list={plan.detalles} description={plan.descripcion} />

                  <PriceDescription descuento={descuento} price={precio} />
                </TarjetaPlanes>
              </div>

            })
          }
        </div>
      </div>
    </>
  )
}