import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { GENERAL_URL_API, store } from "./Store";
import ModalAlertas from "./ModalAlertas";
import { MensajeDemoSolicitudDemoInst } from "./MensajeSoclitudDemoInst";
import { useContext } from "react";
import { useModalManager } from "../hooks/useModalManager";
import { DatosPaisesAPI } from "../services/api/datosPaises";
import { SolicitudesDemoAPI } from "../services/api/SolicitudesDemo";
import { CancelRequestController } from "../util/cancel-request-controller";
import { useImmer } from "use-immer";
import { useQueryParams } from "../hooks/useQueryParams";
import { useDefaultLangByCountry } from "../hooks/use-default-lang-by-country";
import { useTranslations } from "../hooks/use-translations";

/**
 * Componente de formulario para demo institucional.
 * @returns {JSX.Element} Elemento JSX que representa el formulario.
 */
export const FormDemoInstitucional = () => {
	const lang = useDefaultLangByCountry({ country: "us", defaultLang: "en" });
	const textosInfo = useTranslations({ section: "informacion_institucional", lang: lang });
	const textosTienda = useTranslations({ section: "tienda_makemake", lang: lang });
	//textos

	// Estados para las alertas
	const [message, setMessage] = useState("");
	const [description, setDescription] = useState("");
	const [sendedForm, setSendedForm] = useState(false);
	const [sendingForm, setSendingForm] = useState(false);
	const [paisSelecccionado, setPaisSelecccionado] = useImmer({ nombrePais: "", codigoPais: "" });
	const [validacionCorreo, setValidacionCorreo] = useState(true);
	const [paises, setPaises] = useState([]);
	const [sortedCountries, setSortedCountries] = useState([]);
	const [preloader, setPreloader] = useState(false);
	const [notificationAge, setNotificationAge] = useState(false);
	const [isOtherCountry, setOtherCountry] = useState(false);


	const [check, setCheck] = useState(false);

	const refNombre = useRef(null);
	const refCorreo = useRef(null);
	const refConfirmarCorreo = useRef(null);
	const refInstitucion = useRef(null);
	const refTelefono = useRef(null);
	const refPais = useRef(null);
	const refOtroPais = useRef(null);
	const refCiudad = useRef(null);
	const refEdad = useRef(null);
	const refFormulario = useRef(null);
	const globalState = useContext(store);
	const country = globalState?.state?.pais;
	const language = globalState?.state?.idioma;
	const modal = useModalManager();
	const params = useQueryParams();

	/**
	 * Esta función valida que los campos del formulario esten diligenciados. De ser asi envia la información correspondiente haciendo uso del sevicio SolicitudesDemoAPI.solicitarDemoInstitucional. Si la respuesta del API es exitosa, esta función se encarga de mostrar el MensajeDemoSolicitudDemoInst
	 * @param {Event} e
	 */
	const enviarFormulario = async (e) => {
		e.preventDefault();

		validacionEdad();

		if (refFormulario.current.reportValidity() && !sendingForm && validacionCorreo) {
			setSendingForm(true);
			setPreloader(true);

			let abortController = new CancelRequestController();

			const propiedades = {
				nombreP: refNombre.current.value,
				correoP: refCorreo.current.value,
				edadP: check ? "Mayor de edad" : "N/A",
				ciudadP: refCiudad.current.value,
				paisP: isOtherCountry
					? `Otro - ${refOtroPais.current.value}`
					: paisSelecccionado?.nombrePais || "",
				codigoPaisP: isOtherCountry ? "CO" : paisSelecccionado?.codigoPais || "",
				institucionP: refInstitucion.current.value,
				telefonoP: refTelefono.current.value,
			};

			await SolicitudesDemoAPI.solicitarDemoInstitucional({
				abortSignal: abortController.signal,
				dataForm: propiedades,
			})
				.then((response) => {
					const data = response;
					const error = data.status === 0;
					if (!error) {
						setSendedForm(true);
					} else {
						modal.setVisible("modal-alertas", true);
						console.log("ERROR ENVIANDO DEMO:", response?.info || response || "N/A");
						setMessage(
							textosInfo?.modal_alerta?.mensaje_error ||
							"¡Ups! El correo ya se ha usado anteriormente"
						);
						setDescription(
							textosInfo?.modal_alerta?.descripcion_error ||
							"Si no recibiste la información solicitada escríbenos a contacto@makemake.com.co"
						);
					}
					setPreloader(false);
					setSendingForm(false);
				})
				.catch((error) => console.log(error));

			return () => {
				abortController.abort();
			};
		}
	};

	/**
	 * Pone de primeras el pais del usuario en la lista de paises.
	 */
	const myCountryFirst = (data) => {
		const con = country ? country : "CO";
		if (Array.isArray(data) && con) {
			const _countries = [...data].filter((c) => c?.codigo !== con);
			const c = [...data].find((c) => c?.codigo === con);
			if (c) {
				if (con === "US") {
					language === "en"
						? (c.nombre = "United States of America")
						: (c.nombre = "Estados Unidos de América");
				}
				setSortedCountries([c, ..._countries]);
			}
		}
	};

	useEffect(() => {
		let abortController = new CancelRequestController();

		//Utiliza el servicio obtenerLatamUSA de DatosPaisesAPI y obtiene la lista de paises de Latam más USA
		const listaPaises = async () => {
			await DatosPaisesAPI.obtenerLatamUSA({
				abortSignal: abortController.signal,
			})
				.then((res) => {
					setPaises(Array.isArray(res) ? res : []);
				})
				.catch((error) => console.log(error));
		};
		listaPaises();
		return () => {
			abortController.abort();
		};
	}, []);

	useEffect(() => {
		myCountryFirst(paises);
	}, [paises, country, language]);

	/**
	 * Esta función actualiza el estado isOtherCountry si la selección
	 *  previa del país es "Otro". Esto habilita el campo nombre del país.  También actualiza el valor del país seleccionado
	 */
	const validarPais = (valor) => {
		let isOther = refPais.current.value === "Otro" ? true : false;
		setOtherCountry(isOther);

		sortedCountries.map((pais) => {
			if (pais?.codigo === valor) {
				setPaisSelecccionado((draft) => {
					draft.nombrePais = pais?.nombre;
					draft.codigoPais = pais?.codigo;
				});
			}
		});
	};

	/**
	 * Esta función valida que la edad sea mayor a 18 años.
	 * También actualiza el estado notificationAge para mostrar la
	 * notificación y bloquear el botón de enviar
	 */
	const validacionEdad = () => {
		let isLess = check === false ? true : false;
		setNotificationAge(isLess);
	};

	/**
	 * Esta función retorna una alerta en caso de que se ejecute un evento de tipo copiar o pegar en un campo determinado.
	 * @param {Event} e
	 * @returns {alert}
	 */
	const alertaEvento = (e) => {
		e.preventDefault();
		return alert("Está acción no está permitida.");
	};

	const validacionCamposCorreo = () =>
		setValidacionCorreo(
			refCorreo.current.value === refConfirmarCorreo.current.value
				? true
				: false
		);

	return (
		<>
			<>
				<div className="mm-redimir margin-top-header" id="demo-ingresar-datos">
					{sendedForm ? (
						<MensajeDemoSolicitudDemoInst />
					) : (
						<div className="p-0">
							<div className="col-lg-9 mt-3 mx-auto">
								<p className="text-center onsecondary--color font-weight-normal">
									{textosTienda?.institucional?.demo?.descripcion || "Déjanos tus datos y un agente de MakeMake te contactará para explicarte más sobre nuestros servicios"}
								</p>
							</div>

							<form
								id="contact-form"
								name="contactoForm"
								className="pb-5 compra-informacion-institucional-form"
								action={`${GENERAL_URL_API}/assets/enviarCorreoDemoNuevo`}
								method="POST"
								ref={refFormulario}
							>
								<div className="col-xl-10 mx-auto pt-3 pb-5">
									<div className="row pt-4">
										<div className="col-lg-12 mb-3 mb-lg-0">
											{/* <p className="onbackground-primary--color">
													<strong>
														{textosInfo?.form_detallado?.nombre
															?.texto || "Nombre completo*:"}
													</strong>
												</p> */}
											<label htmlFor="inputName" className="sr-only">
												{textosInfo?.form_detallado?.nombre?.label ||
													"Nombre completo"}
											</label>
											<input
												ref={refNombre}
												type="text"
												className={"focusable-secondary form-control"}
												id="inputName"
												/* placeholder={
													textosInfo?.form_detallado?.nombre
														?.placeholder ||
													"Escribe tu nombre y apellido aquí"
												} */
												placeholder={textosInfo?.form_detallado?.nombre
													?.texto || "Nombres y apellidos"}
												name="nombreP"
												required
											/>
										</div>
									</div>

									<div className="row my-lg-4">
										<div className="col-lg-6 mb-3 mb-lg-0">
											{/* <p className="onbackground-primary--color">
												<strong>
													{textosInfo?.form_instituto?.nombre
														?.texto || "Nombre de institución*:"}
												</strong>
											</p> */}
											<label htmlFor="institutoName" className="sr-only">
												{textosInfo?.form_instituto?.nombre?.label ||
													"Instituto"}
											</label>
											<input
												ref={refInstitucion}
												type="text"
												className={"focusable-secondary form-control"}
												id="institutoName"
												/* placeholder={
													textosInfo?.form_instituto?.nombre
														?.placeholder ||
													"Escribe el nombre de tu instituto o biblioteca"
												} */
												placeholder={textosInfo?.form_instituto?.nombre
													?.texto || "Nombre de institución"}
												name="institucionP"
												required
											/>
										</div>
										<div className="col-lg-6 mb-3 mb-lg-0">
											{/* <p className="onbackground-primary--color">
												<strong>
													{textosInfo?.form_instituto?.telefono?.texto ||
														"Teléfono*:"}
												</strong>
											</p> */}
											<label htmlFor="inputPhone" className="sr-only">
												{textosInfo?.form_instituto?.telefono?.label ||
													"Teléfono"}
											</label>
											<input
												ref={refTelefono}
												type="text"
												className={"focusable-secondary form-control"}
												id="inputPhone"
												/* placeholder={
													textosInfo?.form_instituto?.telefono
														?.placeholder || "Escribe tu teléfono"
												} */
												placeholder={
													textosInfo?.form_instituto?.telefono?.label ||
													"Teléfono"
												}
												name="telefonoP"
												required
											/>
										</div>
									</div>

									<div className="row my-lg-4">
										<div className="col-lg-6 mb-3 mb-lg-0">
											{/* <p className="onbackground-primary--color">
												<strong>
													{textosInfo?.form_detallado?.correo?.texto ||
														"Correo electrónico*:"}
												</strong>
											</p> */}
											<label htmlFor="inputEmail" className="sr-only">
												{textosInfo?.form_detallado?.correo?.label ||
													"Correo electrónico"}
											</label>
											<input
												onPaste={(e) => { alertaEvento(e); }}
												onCopy={(e) => { alertaEvento(e); }}
												className={`focusable-secondary form-control`}
												id="inputEmail"
												name="correoP"
												defaultValue={params?.get("email") || ""}
												/* placeholder={
													textosInfo?.form_detallado?.correo
														?.placeholder ||
													"Escribe tu correo electrónico"
												} */
												placeholder={
													textosInfo?.form_detallado?.correo?.label ||
													"Correo electrónico"
												}
												ref={refCorreo}
												required
												type="email"
												onChange={validacionCamposCorreo}
											/>
											{!validacionCorreo && (
												<p className="validacion-campo-error primary-inverse--color">
													{textosInfo?.form_detallado?.correo?.error || 'Los datos ingresados no son iguales'}
												</p>
											)}
										</div>

										<div className="col-lg-6 mb-3 mb-lg-0">
											{/* <p className="onbackground-primary--color">
												<strong>{textosInfo?.form_detallado?.confirmar_correo?.texto || 'Confirmar correo electrónico*:'}</strong>
											</p> */}
											<label htmlFor="inputEmail" className="sr-only">
												{textosInfo?.form_detallado?.confirmar_correo?.label || 'Confirmar correo electrónico'}
											</label>
											<input
												className={`focusable-secondary form-control `}
												id="inputEmailConfirm"
												name="correoPConfirm"
												onChange={validacionCamposCorreo}
												/* placeholder={textosInfo?.form_detallado?.confirmar_correo?.placeholder || "Escribe de nuevo tu correo electrónico"} */
												placeholder={textosInfo?.form_detallado?.confirmar_correo?.label || 'Confirmar correo electrónico'}
												ref={refConfirmarCorreo}
												required
												type="email"
											/>
											{!validacionCorreo && (
												<p className="validacion-campo-error primary-inverse--color">
													{textosInfo?.form_detallado?.confirmar_correo?.error || 'Los datos ingresados no son iguales'}
												</p>
											)}
										</div>
									</div>

									<div className="row mb-4 my-lg-4">
										<div className="col-lg-6  mb-3 mb-lg-4">
											{/* <p className="onbackground-primary--color">
												<strong>
													{textosInfo?.form_detallado?.pais?.texto ||
														"País:"}
												</strong>
											</p> */}
											<label htmlFor="form-select-pais" className="sr-only">
												{textosInfo?.form_detallado?.pais?.label || "País"}
											</label>

											<select
												ref={refPais}
												name="paisP"
												className={"focusable-secondary form-control "}
												id="form-select-pais"
												onChange={(e) => validarPais(e.target.value)}
											>
												<option value="" hidden>
													{textosInfo?.form_detallado?.pais
														?.placeholder || "Escoge tu país"}
												</option>
												{sortedCountries.length !== 0 &&
													sortedCountries?.map((pais) => (
														<option
															key={pais?.codigo}
															value={pais?.codigo}
														>
															{pais?.nombre}
														</option>
													))}
												<option value="Otro">
													{textosInfo?.form_detallado?.otro?.texto ||
														"Otro"}
												</option>
											</select>
										</div>

										{isOtherCountry && (
											<div className="col-lg-6 mb-3 mb-lg-0">
												{/* <p className="onbackground-primary--color">
													<strong>
														{textosInfo?.form_detallado?.otro_pais
															?.texto || "Nombre del país:"}
													</strong>
												</p> */}
												<label htmlFor="inputOtroPais" className="sr-only">
													{textosInfo?.form_detallado?.otro_pais?.label ||
														"Nombre del país"}
												</label>
												<input
													ref={refOtroPais}
													type="text"
													className={"focusable-secondary form-control"}
													id="inputOtroPais"
													/* placeholder={
														textosInfo?.form_detallado?.otro_pais
															?.placeholder ||
														"Escribe el nombre de tu país"
													} */
													placeholder={textosInfo?.form_detallado?.otro_pais
														?.texto || "Nombre del país"}
													name="inputOtroPais"
													required=""
												/>
											</div>
										)}

										<div className="col-lg-6 mb-3">
											{/* <p className="onbackground-primary--color">
												<strong>
													{textosInfo?.form_detallado?.ciudad?.texto ||
														"Ciudad:"}
												</strong>
											</p> */}
											<label htmlFor="inputPhone" className="sr-only">
												{textosInfo?.form_detallado?.ciudad?.label ||
													"Ciudad"}
											</label>
											<input
												ref={refCiudad}
												type="text"
												className={"focusable-secondary form-control"}
												id="inputCiudad"
												/* placeholder={
													textosInfo?.form_detallado?.ciudad
														?.placeholder || "Escribe tu ciudad"
												} */
												placeholder={
													textosInfo?.form_detallado?.ciudad?.label ||
													"Ciudad"
												}
												name="ciudadP"
												required=""
											/>
										</div>
									</div>

									<div className="row mb-4 px-3">
										<label
											className="d-flex align-items-center w-100 focusable-red pointer"
											tabIndex={0}
											role="checkbox"
											checked={check}
											aria-checked={check}
											onKeyDown={(event) => {
												if (event.key === "Enter") {
													setCheck(!check);
												}
											}}
										>
											<input
												type="checkbox"
												className="demo-checkbox"
												ref={refEdad}
												checked={check}
												onChange={(e) => {
													setNotificationAge(false);
													setCheck(e?.target.checked);
												}}
												required
											/>
											<i className="check"></i>
											<p
												className="ml-2 onsecondary--color"
												style={{
													fontSize: '0.9em'
												}}
											>
												{textosInfo?.form_detallado?.edad?.placeholder ||
													"Soy mayor de edad"}
											</p>
										</label>
										<p>
											{notificationAge && (
												<span className="validacion-campo-error primary-inverse--color">
													{textosInfo?.form_detallado?.edad?.tooltip ||
														"Es necesario que seas mayor de edad"}
												</span>
											)}
										</p>
									</div>

									<div className="row mt-lg-5">
										<div className="col-lg-8 col-xl-9 my-4 my-lg-0 ">
											<p
												className="onsecondary--color"
												style={{
													fontSize: '.8em'
												}}
											>
												{textosInfo?.condiciones?.al_ingresar ||
													"Al ingresar tus datos estás aceptando"}{" "}
												<a
													className="focusable-secondary onsecondary--color"
													href="/terminos-y-condiciones"
												>
													<u>{textosInfo?.condiciones?.terminos ||
														"Términos y condiciones"}</u>
												</a>{" "}
												{textosInfo?.condiciones?.y || "y"}{" "}
												<a
													className="focusable-secondary onsecondary--color"
													href="/politica-de-privacidad"
												>
													<u>{textosInfo?.condiciones?.politica ||
														"Política de privacidad"}</u>
												</a>
											</p>
										</div>

										<div className="col-lg-4 col-xl-3">
											<button
												className="focusable-primary  btn-solicitar  d-block w-100"
												type="submit"
												id="btn-enviar-datos"
												onClick={enviarFormulario}
												disabled={notificationAge}
											>
												{preloader ? (
													<i className="fas fa-spinner fa-pulse"></i>
												) : (
													textosInfo?.boton?.enviar || "Solicitar"
												)}
											</button>
										</div>
									</div>


								</div>
							</form>
						</div>
					)}
				</div>

				<ModalAlertas
					alertType={undefined}
					icono={undefined}
					message={message}
					description={description}
					color={"primary-item"}
					boton={textosInfo?.modal_alerta?.boton || "Aceptar"}
				/>
			</>
		</>
	);
};
