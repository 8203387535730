import React from "react";
import useObtenerTraducciones from "../js/translations/useObtenerTraducciones";

/**
 * Componente para mostrar un mensaje con información importante luego de enviar el formulario de demo en la tienda institucional.
 * 
 * @returns {JSX.Element} Componente de MensajeDemoSolicitudDemoInst.
 */
export const MensajeDemoSolicitudDemoInst = () => {
  const { isReady, textosSeccion: mensaje } = useObtenerTraducciones('mensajeCompra');
  return (
    <>
      {isReady &&
        <div id="demo-mensaje">
          <div>
            <div className="onbackground-primary--color p-3 pl-lg-5">
              <div tabIndex={0} role={"region"} aria-label={mensaje?.titulo || '¡Hemos enviado tu solicitud!'} className={"focusable-secondary"} id="onbackground-primary--color mm-mensaje-institucion">
                <div  className="onbackground-primary--color col-lg-10 mx-auto pt-3 pb-4">
                  <h2 className="mm-redimir_title mb-5 text-center onsecondary--color">{mensaje?.titulo || '¡Hemos enviado tu solicitud! '}</h2>

                  <p className="mm-redmir_textlogin text-center onsecondary--color">
                   {mensaje?.descripcion_uno || 'Los accesos demo son exclusivos para uso institucional. Nuestros asesores analizarán tu solicitud y si cumples con los requisitos recibirás tu acceso demo.'}{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};


