import React from "react";
import { BOOKS_PREVIEW_OPTIONS } from "../data/books_preview";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCoverflow } from "swiper";
import { ASSETS_URL } from "../../../components/Store";
import { Icon } from "@iconify/react";
import { usePreviewBooks } from "../hooks/use-books-preview";
import * as _ from "lodash";
import { Link } from "react-router-dom";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";

export const BooksPreview = () => {
	const lang = useDefaultLangByCountry({ country: "us", defaultLang: "en" });
	const textos = useTranslations({ section: "que_es", lang: lang });
	const booksPreviewTexts = textos?.libros;
	const options = [...BOOKS_PREVIEW_OPTIONS];
	const [currentOption, setCurrentOption] = useState(options[0]);
	const books = usePreviewBooks({ currentOption });

	return (
		<div className="books-preview py-3">
			<div className="container">
				<div className="row p-0 m-0">
					<div className="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center p-4">
						<div className="col-12 col-xl-9 text-center text-lg-left d-flex flex-column justify-content-center">
							<h1
								className="w-100 my-3 secondary-font onbackground-primary--color"
								style={{ lineHeight: "1.3em" }}
							>
								{booksPreviewTexts?.titulo ||
									"Una cuidadosa selección de libros de las mejores editoriales de América Latina"}
							</h1>
						</div>
					</div>
					<div className="col-12 col-lg-6">
						<div>
							<BooksSwiper books={books?.data} loading={books?.loading} />
						</div>
						<div className="d-flex flex-wrap justify-content-center align-items-center focusable-primary">
							{options?.map((option, i) => {
								return (
									<button
										className={`p-2 m-1 px-3 preview-button-option focusable-red ${
											currentOption?.value === option?.value ? "selected" : ""
										}`}
										style={{ fontWeight: 500 }}
										onClick={() => setCurrentOption(option)}
										key={i}
									>
										{_.get(booksPreviewTexts, option?.text?.translationPath) ||
											option?.text?.default ||
											"N/A"}
									</button>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const BooksSwiper = ({ books, loading }) => {
	books = Array.isArray(books) ? books : [];

	return (
		<div className="book-swiper py-5 position-relative" role={"presentation"}>
			<Swiper
				className="py-2"
				autoplay={{
					delay: 3000,
					disableOnInteraction: false,
				}}
				coverflowEffect={{
					rotate: 10,
					stretch: 0,
					depth: 300,
					modifier: 1,
					slideShadows: true,
				}}
				effect="coverflow"
				grabCursor={true}
				loop={true}
				modules={[Autoplay, EffectCoverflow]}
				slidesPerView="3"
			>
				{books?.map((book, i) => {
					return (
						<SwiperSlide key={`${i}`}>
							<Link to={book?.webUrl}>
								<img
									src={`${ASSETS_URL}${book?.portada}`}
									alt={`portada-${i}`}
									className="focusable-secondary img-fluid"
								/>
							</Link>
						</SwiperSlide>
					);
				})}
			</Swiper>
			{loading && (
				<div
					className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center surface--bg secondary-inverse--color"
					style={{ top: 0, left: 0, zIndex: 1 }}
				>
					<Icon icon="fa:spinner" width={30} className={"spinner-rotation"} />
				</div>
			)}
		</div>
	);
};
