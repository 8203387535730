import React from "react"
import ReactModal from "react-modal"

/**
 * Componente base para la creación de modales.
 * @param {Object} props - Propiedades del modal.
 * @param {boolean} props.visible - Indica si el modal es visible o no.
 * @param {function} props.setVisible - Función para controlar la visibilidad del modal.
 * @param {boolean} props.backdropClose - Indica si el modal se cierra al hacer clic en el fondo.
 * @param {JSX.Element} props.children - Contenido del modal.
 * @param {string} props.windowClassName - Clases CSS  para el contenedor donde va el contenido (no confundir con el overlay).
 * @returns {JSX.Element} - Elemento JSX que representa el modal.
 */
export const BaseModal = ({ visible, setVisible, backdropClose, children, windowClassName }) => {
    return <ReactModal
        isOpen={visible}
        overlayClassName={'base-modal-overlay'}
        className={`${windowClassName}`}
        onRequestClose={() => backdropClose === true && setVisible && setVisible(false)} >
        {children}
    </ReactModal>
}