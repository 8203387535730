import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import getPromise from '../js/utilities/getPromise';
import { ASSETS_URL } from '../components/Store';
import Libro from '../components/Libro';
import { Icon } from "@iconify/react";
import useObtenerSubsecciones from '../js/translations/useObtenerSubsecciones';

import { LibrosAPIs } from '../services/api/Libros';

/**
 * Componente que no recibe propiedades. Este renderiza la entrada seleccionada previamente en la página principal de blog. También hace uso del componente Libro para generar portadas. Sus principales funciones son obtener la información, generar el contenido de la entrada y mostrar la lista de tags relacionados. 
 * @returns BlogEntrada
 */
const BlogEntrada = () => {
  const { isReady, textosSubSecciones: textosBlog } = useObtenerSubsecciones('blog');
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();
  const { id } = params;
  const [blogContent, setBlogContent] = useState('');
  const [blogDatos, setBlogDatos] = useState({});
  const { title, author, description, date, tag1, tag2, tag3, tag4, tag5, rutaBanner } = blogDatos;

  const [loadingRecommendedBooks, setLoadingRecommendedBooks] = useState(true);
  const [recommendedBooks, setRecommendBooks] = useState([]);

  useEffect(() => {
    if (id !== undefined) {
      loadInformation();
    }
  }, [id])

  useEffect(() => {
    if (recommendedBooks.length === 0) {
      loadRecommendedBooks();
      return;
    }
  }, [recommendedBooks])

  /**
   * Función que obtiene los libros recomendados.
   */
  const loadRecommendedBooks = async () => {
    setLoadingRecommendedBooks(true)
    await LibrosAPIs.obtenerRecomendados({ abortSignal: null, idLibro: '' })
      .then(recommended => {
        setRecommendBooks(recommended);
        setLoadingRecommendedBooks(false);
      })
      .catch(error => console.log(error))
  }

   /**
   * Obtiene la información de la entrada
   */
  const loadInformation = async () => {
    setIsLoading(true)
    let datosBlog = await getPromise('/assets/datosBlog', { idBlog: parseInt(id) })
    let contenidosBlog = await getPromise('/assets/contenidoBlog', { idBlog: parseInt(id) })
    setBlogDatos(JSON.parse(datosBlog))
    setBlogContent(contenidosBlog);
    setIsLoading(false);
  }

  /**
   * Esta función se encarga de devolver el contenido html de la entrada.
   * @param {Element} body 
   * @returns {JSX.Element} Contenido del body entrada
   */
  const createMarkup = (body) => {
    return { __html: body };
  }

  /**
   * Función que retorna la lista de enlaces relacionados a la entrada
   * @returns {JSX.Element} lista de tags
   */
  const joinTags = () => {
    let arra = [];
    if (tag1 !== '') { arra.push(tag1) }
    if (tag2 !== '') { arra.push(tag2) }
    if (tag3 !== '') { arra.push(tag3) }
    if (tag4 !== '') { arra.push(tag4) }
    if (tag5 !== '') { arra.push(tag5) }
    return (
      <>
        {arra.map((item, index) =>
          <Link
            to={`/blog/?tags=${encodeURIComponent(item)}`}
            key={`blog${index}`}
            className="focusable-secondary px-1"
          >{item}{index !== arra.length - 1 ? ', ' : ''}</Link>
        )}
      </>
    )
  }

  return (
    <>
      {isLoading &&
        <>
          <div
            className="secondary-inverse--color d-flex justify-content-center align-items-center"
            style={{ minHeight: '400px' }}>
            <Icon icon="icomoon-free:spinner9" width={60} className={'spinner-rotation'} />
          </div>
        </>
      }
      {!isLoading &&
        <>
          {isReady &&
            <div className="blog-entrada-container">
              <div className="blog-image mx-auto">
                <img className="img-fluid d-block" src={`${ASSETS_URL}${rutaBanner}`} alt={`imagen de ${title}`} />
              </div>

              <div classs="container mx-auto px-lg-5">
                <div className="px-lg-5 container blog-contenido py-5">
                  <div className="px-3 px-lg-5">
                    <div className="row justify-content-between mt-2 mb-5 mx-0">
                      <p className="secondary-item secondary-inverse--color">{textosBlog?.ficha?.tags || 'Tags:'} {joinTags()}</p>
                      <p>{textosBlog?.ficha?.fecha || 'Fecha:'} {date}</p>
                    </div>
                    <h2 className="entrada-title">{title}</h2>
                    <p className="secondary-item secondary-inverse--color mb-3">{textosBlog?.ficha?.escrito_por || 'Escrito por:'} {author}</p>
                    <p className="entrada-description mb-5 pb-4">{description}</p>

                    <div className="mb-content" dangerouslySetInnerHTML={createMarkup(blogContent)}></div>
                  </div>
                </div>
              </div>
              <div className="fichalibro-recommended py-5">
                <h2 className="text-center mb-4">{textosBlog?.ficha?.recomendaciones || 'Nuestras recomendaciones'}</h2>
                <div className=" row mx-0">
                  <div className="col-md-10 col-xl-7 mx-auto">
                    <div className="row m-0 justify-content-center">
                      {recommendedBooks.map((item, index) => {
                        if (!!item?.link && index !== 2) {
                          return (
                            <div
                              className="cover-book col-6 col-sm-4 col-lg-3 mb-4 mb-lg-0"
                              key={index}
                              onClick={() =>
                              (document.getElementsByTagName(
                                "html"
                              )[0].scrollTop = 0)
                              }
                              style={{
                                maxWidth: 250
                              }}
                            >
                              {loadingRecommendedBooks ?
                                <div
                                  className="secondary-inverse--color d-flex justify-content-center align-items-center"
                                  style={{ minHeight: '400px' }}>
                                  <Icon icon="fa:spinner" width={30} className={'spinner-rotation'} />
                                </div>
                                :
                                <Libro
                                  libro={item}
                                  portada={item.portada}
                                  webUrl={item.link}
                                  alto={280}
                                />
                              }
                            </div>
                          )
                        }
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </>
      }
    </>
  )
}

export default BlogEntrada;
