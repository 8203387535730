import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { TabPanel } from "../components/shared/tabpanel";
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";
import { Tiendas } from "./tiendas";

/**
 * Este componente tab panel carga la tienda individual y cursos.
 */
export const TabPanelTiendaIndividual = ({ }) => {
    const {textosSubSecciones:textos} = useObtenerSubsecciones('tienda_makemake');

    const history = useHistory();
    const location = useLocation();
    const tabPanelIds = {
        individual: '/tienda/individual',
        cursos: '/tienda/cursos',
    }
    /**
     * define cual es el tab panel (tienda) que se debe mostrar en pantalla.
     */
    const [currentTabPanel, setCurrentTabPanel] = useState(tabPanelIds.individual)


    useEffect(() => {
        /**
            dependiendo de la ruta del navegador si coincide con algún id 
            de los tab panel, entonces muestra el panel respectivo, 
            si no es así mostrará por defecto el de tienda individual
         */
        if (Object.values(tabPanelIds).includes(location.pathname)) {
            setCurrentTabPanel(location.pathname)
        } else {
            setCurrentTabPanel(tabPanelIds.individual)
        }
    }, [location])

    /**
     * cambia la ruta actual, debería llevar a la ruta de una tienda especifica.
     * Si la ruta coincide con el id de algún tab panel se mostrará ese panel automaticamente, 
     * de no existir se mostrará por defecto el tab de tienda individual.
     */
    const tabPanelNavigateTo = (path) => {
        history.push(path);
    }

    return (
        <TabPanel.Box currentPanelId={currentTabPanel} className={'tab-panel'}>
            <TabPanel.Tabs className={'tabs'}>
                <TabPanel.Tab
                    tabPanel={tabPanelIds.individual}>
                    <button
                        className={`focusable-red tab individual ${currentTabPanel === tabPanelIds.individual ? 'current' : ''}`}
                        onClick={() => tabPanelNavigateTo(tabPanelIds.individual)}>
                        <img
                            className="icon"
                            src={`/img/tienda-makemake/planes-individual${currentTabPanel === tabPanelIds.individual ? '-selected' : ''}.png`}
                            alt={tabPanelIds.individual} />
                           <p className="d-flex align-items-center">{textos?.tabpanel?.individual || 'Quiero leer en MakeMake'}</p> 
                    </button>
                </TabPanel.Tab>
                <TabPanel.Tab
                    tabPanel={tabPanelIds.cursos}>
                    <button
                        className={`focusable-red tab cursos ${currentTabPanel === tabPanelIds.cursos ? 'current' : ''}`}
                        onClick={() => tabPanelNavigateTo(tabPanelIds.cursos)}>
                        <img
                            className="icon"
                            src={`/img/tienda-makemake/planes-cursos${currentTabPanel === tabPanelIds.cursos ? '-selected' : ''}.png`}
                            alt={tabPanelIds.cursos} />
                            <p className="d-flex align-items-center">{textos?.tabpanel?.cursos || 'Quiero aprender algo nuevo'}</p>
                    </button>
                </TabPanel.Tab>
            </TabPanel.Tabs>
            <TabPanel.Panel panelId={tabPanelIds.individual}>
                <Tiendas.TiendaClienteIndividual />
            </TabPanel.Panel>
            <TabPanel.Panel panelId={tabPanelIds.cursos}>
                <Tiendas.TiendaCursos />
            </TabPanel.Panel>
        </TabPanel.Box>
    )
}

