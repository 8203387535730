import React from "react";
import { Img } from "../../../components/shared/img";
import { Link } from "react-router-dom";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";

/**
 * Componente que muestra las características comerciales de MakeMake.
 * @returns {JSX.Element} Elemento JSX.
 */
export const Attributes = () => {
	const lang = useDefaultLangByCountry({ country: "us", defaultLang: "en" });
	const textos = useTranslations({ section: "que_es", lang: lang });
	const attributesText = textos?.atributos;
	const featuresText = textos?.sin_login?.info_home || {};
	return (
		<>
			<div
				role={"group"}
				tabIndex={0}
				aria-label={"MakeMake Classics Info"}
				className="focusable-secondary d-flex col-md-10 mx-auto  mt-5 mt-lg-0 pb-3 py-lg-5 flex-wrap contenedor-caracteristicas justify-content-around"
			>
				<Link to="/home" className="col-5 col-lg-3 d-flex flex-column mb-4 mb-lg-0 px-0">
					<div className="col-md-6 mx-auto mb-3 d-flex justify-content-center align-items-center">
						<Img
							tabIndex={0}
							alt={`${featuresText?.editoriales?.parte_uno} ${featuresText?.editoriales?.parte_dos}`}
							src="/img/home/editoriales-2.png"
							className="focusable-secondary w-100 h-100"
							width={80}
							height={80}
						/>
					</div>
					<div>
						<p
							className="text-center text-break"
							dangerouslySetInnerHTML={{
								__html:
									attributesText?.seleccion ||
									"Una cuidadosa selección de <br/> libros de América Latina",
							}}
						></p>
					</div>
				</Link>

				<a
					href="https://makemake.app"
					target="_blank"
					className="col-5 col-lg-3 d-flex flex-column mb-4 mb-lg-0 px-0"
				>
					<div className="col-md-6 mx-auto mb-3 d-flex justify-content-center align-items-center">
						<Img
							tabIndex={0}
							alt={`${featuresText?.interactivos?.parte_uno} ${featuresText?.interactivos?.parte_dos} ${featuresText?.interactivos?.parte_tres}`}
							src="/img/home/interactivos-2.png"
							className="focusable-secondary img-fluid"
							width={80}
							height={80}
						/>
					</div>
					<div>
						<p
							className="text-center text-break"
							dangerouslySetInnerHTML={{
								__html:
									attributesText?.app ||
									"Apps para móviles y  <br/> computadores",
							}}
						></p>
					</div>
				</a>

				<a
					href="https://www.lapizdeacero.org/winners/biblioteca-digital-makemake/"
					target="_blank"
					className="col-5 col-lg-3 d-flex flex-column mb-4 mb-lg-0 px-0"
				>
					<div className="col-md-6 mx-auto mb-3 d-flex justify-content-center align-items-center">
						<Img
							tabIndex={0}
							alt={`${featuresText?.diversidad?.parte_uno} ${featuresText?.diversidad?.parte_dos} ${featuresText?.diversidad?.parte_tres}`}
							src="/img/home/diversidad-2.png"
							className="focusable-secondary img-fluid"
							width={80}
							height={80}
						/>
					</div>
					<div>
						<p
							className="text-center text-break"
							dangerouslySetInnerHTML={{
								__html:
									attributesText?.interfaz ||
									"Interfase amigable y <br/> ganadora",
							}}
						></p>
					</div>
				</a>

				<Link
					to="/edtech-open-standard"
					className="col-5 col-lg-3 d-flex flex-column mb-4 mb-lg-0 px-0"
				>
					<div className="col-md-6 mx-auto mb-3 d-flex justify-content-center align-items-center">
						<Img
							tabIndex={0}
							alt={`${featuresText?.acompanamiento?.parte_uno} ${featuresText?.acompanamiento?.parte_dos}`}
							src="/img/home/guias-2.png"
							className="focusable-secondary img-fluid"
							width={80}
							height={80}
						/>
					</div>
					<div>
						<p
							className="text-center text-break"
							dangerouslySetInnerHTML={{
								__html:
									attributesText?.accesible ||
									"Plataforma accesible <br/> según WCAG",
							}}
						></p>
					</div>
				</Link>
			</div>
		</>
	);
};
