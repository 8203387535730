import React, { useCallback, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import normalize from '../js/utilities/normalizeString';
import PropTypes from 'prop-types';

/**
 * Componente que recibe la infomación del acordeón y renderiza las correspondientes opciones con su lista de entradas del blog. Este componente hace uso de la función BlogAccordionItem situado en el mismo archivo. 
 * @returns {JSX.Element} BlogAccordionItem
 */
const BlogAccordion = (props) => {
  const { acordeon } = props;
  const [statesOpen, setStatesOpen] = useState([]);

  useEffect(() => {
    let initialStates = [];
    for (var i in acordeon) {
      initialStates.push(false)
    }
    setStatesOpen(initialStates)
  }, [])

  const createItems = () => {
    let items = [];
    for (var i in acordeon) {
      let index = Object.keys(acordeon).indexOf(i);
      items.push(
        <BlogAccordionItem
          key={`item ${i}`}
          mes={i}
          index={index}
          entradas={acordeon[i]}
          statesOpen={statesOpen}
          setStatesOpen={setStatesOpen}
        />
      )
    }
    return items;
  }

  return (
    <section className="blog-accordion">
      <ul className="px-0" aria-label={'Botones del grupo de control de acordeón'}>
        {createItems()}
      </ul>
    </section>
  )
}

/**
 * Función que renderiza la opcion del acordeón con su correspondiente lista
 * @param {{
*  mes: string, 
*  entradas: Array,
*  index: number,
*  statesOpen: Array,
*  setStatesOpen: Function
* }} props 
* @returns BlogAccordionItem
*/
const BlogAccordionItem = (props) => {
  const { mes, entradas, index, statesOpen, setStatesOpen } = props;
  const isOpen = statesOpen[index];
  const [itemHeight, setItemHeight] = useState(0);

  const accordionItem = useCallback(node => {
    const createHeight = () => {
      if (node !== null) {
        node.style.height = 'auto';
        setItemHeight(node.clientHeight)
        node.style.height = 0
      }
    }
    createHeight();
    window.addEventListener('resize', createHeight)
    return (
      window.removeEventListener('resize', createHeight)
    )
  }, [])

  return (
    <li className="list-main-item ">
      <button
        className={`focusable-secondary w-100 row m-0 justify-content-between align-items-center secondary-item secondary-inverse--color list-selector p-3 ${isOpen ? 'item-active' : ''}
      `}
        aria-controls={`entrada${index}`}
        aria-expanded={isOpen ? true : false}
        onClick={() => {
          if (isOpen) {
            let newStatesOpen = statesOpen.slice();
            newStatesOpen[index] = false;
            setStatesOpen(newStatesOpen)
          } else {
            let newStatesOpen = [];
            statesOpen.forEach((item) => newStatesOpen.push(false))
            newStatesOpen[index] = true;
            setStatesOpen(newStatesOpen)
          }
        }}
      >
        {mes}
        <i className="fas fa-chevron-down pr-2"></i>
      </button>

      <ul
        id={`entrada${index}`}
        className={`list-unstyled list-content px-4`}
        ref={accordionItem}
        style={{ height: isOpen ? `${itemHeight}px` : '0px', display: isOpen ? `inline-table` : 'none' }}
        aria-hidden={isOpen ? false : true}
      >
        {
          entradas.map((item, index) => {
            return (
              <li
                className="py-3 row justify-content-between mx-0"
                key={`item${index}`}>
                <Link   
                  to={`/blog/${item.idBlog}/${normalize(item.title)}`}
                  className="focusable-secondary px-2"
                >
                  <p>{item.title}</p>
                </Link>
              </li>
            )
          })
        }
      </ul>
    </li>
  )
}

export default BlogAccordion;

BlogAccordion.propTypes = {

  /**
   * Objeto que contiene las opciones del acordeon con su correspondiente fecha. Cada opción tiene un arreglo de objetos con la lista de entradas y a su vez la información pertinente de dichas entradas.
   */
  acordeon: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        fecha: PropTypes.string, /**Fecha  ej 2019-4-9*/
        idBlog: PropTypes.string,
        title:  PropTypes.string,
        url:  PropTypes.string
      })
    )
  ).isRequired
}