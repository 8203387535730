import React, { useState } from 'react'
import PropTypes from "prop-types";
import FsLightbox from 'fslightbox-react';
import { Img } from '../../components/shared/img';
import useIsMobile from "../../js/utilities/useIsMobile";
import MicroModal from 'micromodal';
import ModalClub from '../../components/modals/ModalClub';

/**
 * Este componente renderiza la tercera sección del club MakeMake que corresponde a la información sobre los eventos y talleres que realizará MakeMake
 * y recibe como props los textos de la interfaz.
 */

const MakeMakeVivo = (props) => {

    const { traducciones } = props;
    const [enVivo, setEnVivo] = useState(false);
    const isMobile = useIsMobile();
    const [anio, setAnio] = useState();
    const [rutaPDF, setRutaPDF] = useState();
    const [fileName, setFileName] = useState();
    const isRegistration = true;


    /**
     * Esta función define si el tamaño de la pantalla es de un dispositivo de escritorio los botones planes de lectura despliegan una ventana modal donde se pueden 
     * pre visualizar los archivos PDF, en cambio, si la pantalla es de un dispositivo móvil la pre visualización se desactiva y pasa a ser un botón para descargar el archivo PDF.
     * @param {string} ruta - ruta del archivo PDF
     * @param {string} file - nombre del archivo PDF 
     * @param {string} date - año del plan de lectura
     */

    const botonDescarga = (ruta, file, date) => {
        if (isMobile) {
            let alink = document.createElement('a');
            alink.href = ruta;
            alink.setAttribute("download", file);
            document.body.appendChild(alink);
            alink.click();
            alink.remove();
        }
        else {
            MicroModal.show("modal-club", {
                awaitCloseAnimation: true,
            });
            setAnio(date)
            setRutaPDF(ruta)
            setFileName(file)
        }
    }

    return (
        <div className="pt-5">
            <div className="container">
                <div className="col-lg-10 mx-auto">
                    <h2 className="titulos-actualizacion-club titulo-rojo-act mx-0 mx-lg-3 ">{traducciones?.titulo || 'MakeMake en vivo'}</h2>

                    <p className='py-5 px-4 px-lg-2 parrafo-acompanamiento onbackground-third--color'>
                        {traducciones?.canal?.descripcion?.parrafo_1 || 'Ciclo de eventos, charlas, talleres y foros con autores, ilustradores, músicos y profesionales especializados. Síguenos en nuestro canal de Youtube!'}
                    </p>

                    <div className='col-lg-10 mx-auto pt-5 pt-lg-0'>
                        <div className='borde-amarillo mx-3'>
                            <div className='mx-3'>
                                <img alt="make-ipad" src="/img/club-makemake/iconos-cerrar.png" className="img-fluid " />
                            </div>
                        </div>
                        <div className='contenedor-video mx-3'>
                            <div className='mx-3 mt-5'>
                                <div className="embed-responsive embed-responsive-16by9">
                                    <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/6NOQcLL9GDs"></iframe>
                                </div>
                            </div>
                            <div className='d-sm-flex m-sm-3 m-3 justify-content-between align-items-center'>
                                <div className='mr-3'>
                                    <img alt="make-ipad" src="/img/club-makemake/youtube.png" className="img-fluid d-none d-sm-block background-contraste" />
                                </div>
                                <div className='parrafo-youtube text-center text-sm-right'>{traducciones?.canal?.video?.texto || 'Sigue nuestro Canal de Youtube para ver todos los eventos pasados y recibir notificaciones de los eventos por venir.'}
                                </div>
                            </div>
                            <div className='d-flex justify-content-center m-3 mb-5'>
                                <a
                                    href="https://www.youtube.com/@makemake2206"
                                    target="_black"
                                    className="btn bnt-suscribete focusable-red mx-auto"
                                >
                                    {traducciones?.canal?.video?.boton_suscribete || 'Suscríbete a nuestro canal'}
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className='row col-lg-10 mx-auto justify-content-around pt-5'>
                        <div className='text-descargar col-12 col-md-6'>
                            <p className='text-center text-md-left pb-5 pb-md-0'>
                                {traducciones?.programacion?.descripcion || 'Descarga toda la programación 2024  y ¡no te pierdas ninguno de nuestros eventos!'}
                            </p>
                        </div>
                        <div className='col-12 col-md-6 d-flex align-items-center pb-3 pb-md-0'>
                            <a
                                href={traducciones?.programacion?.link_descarga || "/document/makemake_en_vivo_2024.pdf" }
                                download={traducciones?.programacion?.doc || 'makemake_en_vivo_2024.pdf'}
                                className='btn-descargar-act col-lg-12 py-2 focusable-red mx-auto d-block'
                            >
                                {traducciones?.programacion?.boton || 'Programación 2024'}
                            </a>
                        </div>  
                    </div>

                    <div>

                        <div className="col-lg-10 mx-auto px-0 mt-5">
                            <button onClick={() => setEnVivo(!enVivo)}>
                                <Img 
                                    className='w-100' 
                                    src={traducciones?.img_envivo || '/img/club-makemake/en-vivo/en-vivo.jpg'}
                                    alt={traducciones?.img_alt || 'Próximos en vivo'} 
                                />
                            </button>
                            <FsLightbox
                                toggler={enVivo}
                                disableLocalStorage={true}
                                disableSlideSwiping={true}
                                type="image"
                                sources={[traducciones?.img_envivo || '/img/club-makemake/en-vivo/en-vivo.jpg']}
                            />
                        </div>
                        <div className="col-lg-10 mx-auto px-0">
                            < a
                                href="https://forms.gle/kU4dqyjEAt2YpVfU6 "
                                target="_black"
                                className="col-lg-6 btn btn-primary-act d-block mx-auto focusable-red"
                            >
                                {isRegistration ? traducciones?.btn_inscripcion || 'Inscripción al próximo evento': traducciones?.ver_evento || 'Ver evento'}
                            </a>
                        </div>
                        <div className="col-lg-10 mx-auto mt-5">
                            <p className='titulo-planes titulo-violeta-act py-4 pt-xl-0'>
                                {traducciones?.subtitulo || 'Planes de lectura y programación anteriories:'}
                            </p>
                            <strong className=' onprimary--color pb-5'>
                                {traducciones?.parrafo_1 || 'Puedes ver los eventos que te hayas perdido o repetir tus presentaciones favoritas. Toda nuestra programación queda grabada en nuestro canal de YouTube.'}
                            </strong>
                        </div>
                        <div className="d-md-flex flex-wrap mt-5 justify-content-around align-items-center">
                            <div className="col-md-6 col-lg-3 pb-3 pb-lg-0 px-2 ">
                                <a
                                    href="https://www.youtube.com/playlist?list=PLOzDFiyCElH7hbDjZ8n6HE_jmLPW9WkYi"
                                    target="_black"
                                    className="btn btn-primary-act d-block mx-auto focusable-red"
                                >
                                    {traducciones?.botones_prog?.prog_2020 || 'Programación 2020'}
                                </a>
                            </div>
                            <div className="col-md-6 col-lg-3 pb-3 pb-lg-0 px-2 ">
                                <a
                                    href="https://www.youtube.com/playlist?list=PLOzDFiyCElH7zPY7KCBiHi6oopJvO0AYx"
                                    target="_black"
                                    className="btn btn-primary-act d-block mx-auto focusable-red"
                                >
                                    {traducciones?.botones_prog?.prog_2021 || 'Programación 2021'}</a>
                            </div>
                            <div className="col-md-6 col-lg-3 pb-3 pb-lg-0 px-2 ">
                                <a
                                    href="https://www.youtube.com/playlist?list=PLOzDFiyCElH6qdpXPwpaSZCcwS18rTkHG"
                                    target="_black"
                                    className="btn btn-primary-act d-block mx-auto focusable-red"
                                >
                                    {traducciones?.botones_prog?.prog_2022 || 'Programación 2022 '}
                                </a>
                            </div>
                            <div className="col-md-6 col-lg-3 pb-3 pb-lg-0 px-2 ">
                                <a
                                    href="https://www.youtube.com/playlist?list=PLOzDFiyCElH5mo8jNZ9fLh-Zr6VVewrKB"
                                    target="_black"
                                    className="btn btn-primary-act d-block mx-auto focusable-red"
                                >
                                    {traducciones?.botones_prog?.prog_2023 || 'Programación 2023'}
                                </a>
                            </div>
                        </div>
                        <div className="d-md-flex flex-wrap my-5">
                            <div className="col-12 col-md-6 col-lg-3  pb-3 pb-lg-0 px-2 ">
                                <button
                                    onClick={() => {
                                        botonDescarga("/document/plan_lecturas_2020_act.pdf", "plan_lecturas_2020.pdf", "2020")
                                    }}
                                    className="div-planes py-2 px-2 d-block mx-auto text-center focusable-red"
                                >
                                    {traducciones?.botones_planes?.planes_text?.texto || 'Plan lecturas '} <br className='d-none d-md-block' /> <span>2020</span>
                                    <div className='boton-abre-modal '>
                                        <i className='icono-fa fas fa-plus fa-2x fa-fw'></i>
                                    </div>
                                </button>

                            </div>
                            <div className="col-12 col-md-6 col-lg-3  pb-3 pb-lg-0 px-2 ">
                                <button
                                    onClick={() => {
                                        botonDescarga("/document/plan_lecturas_2021_act.pdf", "plan_lecturas_2021.pdf", "2021")
                                    }}
                                    className="div-planes py-2 px-2 d-block mx-auto text-center focusable-red"
                                >
                                    {traducciones?.botones_planes?.planes_text?.texto || 'Plan lecturas '} <br className='d-none d-md-block' /> <span>2021 </span>
                                    <div className='boton-abre-modal'>
                                        <i className='icono-fa fas fa-plus fa-2x fa-fw'></i>
                                    </div>
                                </button>

                            </div>
                            <div className="col-12 col-md-6 col-lg-3  pb-3 pb-lg-0 px-2 ">
                                <button
                                    onClick={() => {
                                        botonDescarga(traducciones?.plan_2022?.link_descarga || "/document/plan_lecturas_2022_act.pdf", traducciones?.plan_2022?.nombre_archivo || "plan_lecturas_2022.pdf", "2022")
                                    }}
                                    className="div-planes py-2 px-2 d-block mx-auto text-center focusable-red"
                                >
                                    {traducciones?.botones_planes?.planes_text?.texto || 'Plan lecturas'} <br className='d-none d-md-block' /> <span>2022</span>

                                    <div className='boton-abre-modal'>
                                        <i className='icono-fa fas fa-plus fa-2x fa-fw'></i>
                                    </div>
                                </button>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 pb-3 pb-lg-0 px-2 ">
                                <button
                                    onClick={() => {
                                        botonDescarga(traducciones?.plan_2023?.link_descarga || "/document/plan_lecturas_2023_act.pdf", traducciones?.plan_2023?.nombre_archivo || "plan_lecturas_2023.pdf", "2023")
                                    }}
                                    className="div-planes py-2 px-2 d-block mx-auto text-center focusable-red"
                                >
                                    {traducciones?.botones_planes?.planes_text?.texto || 'Plan lecturas'} <br className='d-none d-md-block' /> <span>2023</span>

                                    <div className='boton-abre-modal'>
                                        <i className='icono-fa fas fa-plus fa-2x fa-fw'></i>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalClub texto={traducciones?.botones_planes?.planes_text?.texto} anio={anio} rutaPDF={rutaPDF} fileName={fileName} />
        </div >
    )
}

export default MakeMakeVivo;

MakeMakeVivo.propTypes = {
    /**
     * Es un objeto que trae los textos de la interfaz, especificamente los de mi cuenta. Estos cambian dependiendo del idioma activo.
     */
    traducciones: PropTypes.object.isRequired
}
