import { Icon } from "@iconify/react";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MAIN_PLANS } from "../data/main-plans";
import { Img } from "../../../components/shared/img";
import useIsMobile from "../../../js/utilities/useIsMobile";
import ReactTooltip from "react-tooltip";
import { AccesibilidadContext, TemasDisponibles } from "../../../contexts/AccesibilidadContext";
import * as _ from "lodash";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";

export const MainPlans = () => {
	const lang = useDefaultLangByCountry({ country: "us", defaultLang: "en" });
	const textos = useTranslations({ section: "que_es", lang: lang });
	const plansTexts = textos?.planes_principales;

	const plans = [...MAIN_PLANS];
	const accesibility = useContext(AccesibilidadContext);

	return (
		<div
			className="position-relative surface--bg onbackground-primary--color overflow-hidden py-3"
			style={{ zIndex: 0 }}
		>
			<div className="main-plans-shape"></div>
			{accesibility.temaActual === TemasDisponibles.clasico.id && (
				<>
					<div
						className="SINUSOIDAL_SHAPE d-none d-lg-block position-absolute w-100 h-100 left-0 overflow-hidden"
						style={{ zIndex: -1, top: "20%" }}
					>
						<svg
							width="100%"
							height="200%"
							viewBox="0 0 1512 1273"
							fill="none"
							preserveAspectRatio="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M1470.08 1273L1384.3 980.16C1384.3 980.16 1485.66 885.205 1499.99 859.036C1586.52 701.276 1613.22 524.326 1550.92 353.606C1486.15 176.905 1407.53 107.371 1231.27 41.8246C1146.47 10.4222 1026.82 -26.4633 877.748 27.3695C752.903 72.4794 729.418 128.555 527.936 169.179C293.574 216.283 160.076 110.86 -70.8246 252.67C-179.6 319.462 -303.209 418.405 -341.28 634.484C-409.759 1023.52 -64.3969 1215.43 326.206 1248.83C622.867 1273.75 1018.41 1223.9 1166.74 1099.29L1470.08 1273Z"
								fill="#E3E3F9"
							/>
						</svg>
					</div>
					<div
						className="SINUSOIDAL_SHAPE_MOBILE d-block d-lg-none position-absolute w-100 h-100 left-0 overflow-hidden"
						style={{ zIndex: -1, top: "5%" }}
					>
						<svg
							width="100%"
							height="200%"
							viewBox="0 0 360 1856"
							preserveAspectRatio="none"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M1068.08 1856L940.486 1429.05C940.486 1429.05 1091.24 1290.6 1112.57 1252.45C1241.26 1022.44 1280.97 764.453 1188.31 515.548C1091.98 257.923 975.049 156.544 712.882 60.9792C586.762 15.1952 408.797 -38.5827 187.077 39.904C1.3905 105.673 -33.5405 187.43 -333.213 246.658C-681.788 315.334 -880.344 161.631 -1223.77 368.386C-1385.56 465.767 -1569.41 610.023 -1626.03 925.06C-1727.88 1492.27 -1214.21 1772.06 -633.252 1820.75C-192.017 1857.09 396.296 1784.42 616.913 1602.73L1068.08 1856Z"
								fill="#E3E3F9"
							/>
						</svg>
					</div>
					<div className="container d-flex justify-content-center flex-column align-items-center">
						<div className="text-center py-4" style={{ maxWidth: 800 }}>
							<h1 className="secondary-font onbackground-primary--color">
								{plansTexts?.titulo || "MakeMake se adapta a tu institución"}
							</h1>
						</div>
					</div>
				</>
			)}
			<div className="container d-flex flex-wrap position-relative justify-content-center align-items-center">
				<div className="row justify-content-center">
					{plans?.map((plan, i) => {
						return (
							<div key={i} className="col-12 col-sm-9 col-lg-5 m-3">
								<MainPlanCard key={i} data={plan} />
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export const MainPlanCard = ({ data }) => {
	const lang = useDefaultLangByCountry({ country: "us", defaultLang: "en" });
	const textos = useTranslations({ section: "que_es", lang: lang });
	const planTexts = textos?.planes_principales?.plan;

	const [expandedFeatures, setExpandedFeatures] = useState(false);
	const mobile = useIsMobile();
	const features = Array.isArray(data?.features) ? data.features : [];

	useEffect(() => {
		if (mobile === false) {
			setExpandedFeatures(true);
		} else {
			setExpandedFeatures(false);
		}
	}, [mobile]);

	return (
		<div className="main-plan-card">
			{mobile === false ? (
				<div className="image">
					<Img className="img-fluid accesible-image" src={data?.imgUrl} />
				</div>
			) : (
				<>
					<div
						className="image-mobile"
						style={{
							backgroundImage: `url('${
								data?.imgUrl ? `${data?.imgUrl}` : "/img/no-imagen3.png"
							}')`,
						}}
					></div>

					<div className="row p-2 m-0">
						<div className="col">
							<Img className="img-fluid accesible-image" src={data?.mobileImgUrl} />
						</div>
						<h2 className="col-9 secondary-font font-weight-bold my-3">
							{_.get(planTexts, data?.title?.translationPath) ||
								data?.title?.default ||
								"N/A"}
						</h2>
					</div>
				</>
			)}
			<div className="p-3">
				<div>
					{mobile === false && (
						<h2 className="secondary-font font-weight-bold my-3">
							{_.get(planTexts, data?.title?.translationPath) ||
								data?.title?.default ||
								"N/A"}
						</h2>
					)}
					<p className="font-weight-bold" style={{ fontSize: "0.9em" }}>
						{_.get(planTexts, data?.description?.translationPath) ||
							data?.description?.default ||
							"N/A"}
					</p>
				</div>
				<div className="d-flex flex-column flex-lg-row p-0 m-0 my-3">
					<Link
						className="flex-grow-1 btn btn-primary my-1 mx-0 mr-lg-2 p-1 focusable-red"
						to={data?.demoUrl}
					>
						{planTexts?.boton?.demo || "Agenda un demo"}
					</Link>
					<Link
						className="flex-grow btn bordered-button my-1 mx-0 p-1 focusable-red"
						to={data?.moreInfoUrl}
					>
						{planTexts?.boton?.saber_mas || "Conoce más"}
						<Icon className="mx-2" icon="maki:arrow" width={"1.5em"} />
					</Link>
				</div>
				{mobile && (
					<div className="onbackground-primary--color">
						<div className="onbackground-primary--bg w-100" style={{ height: 1 }} />
						<button
							className="my-3 font-weight-bold d-flex justify-content-between w-100 focusable-red"
							onClick={() => setExpandedFeatures(!expandedFeatures)}
						>
							<span>{planTexts?.mobile?.expandible || "Ver características"}</span>
							<Icon
								className={`mx-2 dropdown-arrow-transition ${
									expandedFeatures ? "expanded-dropdown" : "collapsed-dropdown"
								}`}
								icon="fe:arrow-down"
								width={"1.5em"}
							/>
						</button>
					</div>
				)}
				<ul className={`features ${expandedFeatures ? "visible" : "hidden"}`}>
					{features?.map((feature, i) => {
						return (
							<li key={i} className="feature my-2 row p-0 mx-0">
								<div className="d-flex justify-content-center align-items-center">
									<Icon className="" icon="mingcute:check-fill" width={"1em"} />
								</div>
								<span className="col">
									{_.get(planTexts, feature?.description?.translationPath) ||
										feature?.description?.default ||
										"N/A"}
								</span>
								{feature?.tooltip && (
									<>
										<span
											data-for={`tooltip-${i}`}
											data-tip={
												_.get(
													planTexts,
													feature?.tooltip?.translationPath
												) ||
												feature?.tooltip?.default ||
												"N/A"
											}
											style={{ cursor: "pointer", transform: "scale(1.5)" }}
										>
											<i className="ml-2 fas fa-question-circle onbackground-third--color"></i>
										</span>

										<ReactTooltip
											className={"tooltiptext"}
											id={`tooltip-${i}`}
											place="bottom"
										/>
									</>
								)}
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
};
