import React from "react";
import { Link } from "react-router-dom";
import { ASSETS_URL } from "../components/Store";
import normalize from "../js/utilities/normalizeString";
import PropTypes from 'prop-types';

/**
 * Componente que recibe la información de las entradas y los textos de interfaz. Este renderiza la lista de las entradas del blog en la pantalla principal.  También hace uso de la función EntradaBlog situado en el mismo archivo. 
 * @returns {JSX.Element} EntradaBlog
 */
const EntradasBlog = (props) => {
  const { entradas, textosBlog } = props;
  const arr = entradas ? entradas : [];
  return (
    <section className="col-lg-9 px-4">
      <div className="row mx-0">
        {arr.length === 0 && (
          <h3 className="no-hay-resultados onbackground-third--color">
            {" "}
            {textosBlog?.entrada?.no_resultados || 'No se encontraron resultados en la búsqueda :('}
          </h3>
        )}
        {arr.length > 0 && (
          <>
            {entradas.map((item) => {
              return <EntradaBlog item={item} key={`item${item.idBlog}`} textosBlog={textosBlog} />;
            })}
          </>
        )}
      </div>
    </section>
  );
};

/**
 * Función que renderiza cada recuadro con la información de la entrada
 * @param {{
*  item: object,
*  textosBlog : object
* }} props 
* @returns EntradaBlog
*/
const EntradaBlog = (props) => {
  const { item, textosBlog } = props;
  const { fecha, idBlog, title, description, portada } = item;
  return (
    <div className="col-sm-6 col-lg-4 blog-entrada pb-5 my-4 px-lg-4 text-center">
      <Link to={`/blog/${idBlog}/${normalize(title)}`} className="focusable-secondary">
        <img
          className="cover bg-cover img-fluid mb-4"
          style={{ borderRadius: "0.25em" }}
          alt={`portada ${title}`}
          src={`${ASSETS_URL}${portada}`}
        />
        <div>
          <p className="date text-left">{textosBlog?.entrada?.fecha || 'Fecha:'} {fecha}</p>
          <h3 className="secondary-item secondary-inverse--color">{title}</h3>
          <p className="mb-4">{description}</p>
        </div>
        <button className="btn btn-secondary px-5 py-2 m-auto mt-4 w-75 text-center focusable-red">
          {textosBlog?.botones?.leer || 'Leer'}
        </button>
      </Link>
    </div>
  );
};

export default EntradasBlog;

EntradasBlog.propTypes = {
  /**
   * Arreglo de objetos que contiene la información de cada entrada
   */
  entradas: PropTypes.arrayOf(PropTypes.shape({
    description:  PropTypes.string,
    fecha: PropTypes.string,
    idBlog: PropTypes.string,
    portada: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string
  })).isRequired, 

  /**
   * Es un objeto que trae los textos de la interfaz dependiendo del idioma activo.
   */
  textosBlog: PropTypes.object.isRequired,
}