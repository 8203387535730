import React from "react";
import { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Error404 from "../404";
import { EstadoTransaccion } from "./epayco-views/estado-transaccion";
import { useContext } from "react";
import { store } from "../components/Store";
import { TabPanelTiendaIndividual } from "./tab-panel-tienda-individual";
import { useDefaultLangByCountry } from "../hooks/use-default-lang-by-country";
import { useTranslations } from "../hooks/use-translations";

import { PlanInstituciones } from "./planInstituciones";

/**
 * Contenedor principal que contiene el enrutamiento de la tienda y también la definición del script del API de epayco.
 */
export const TiendaMakeMake = ({}) => {
	const appState = useContext(store);
	const isAuth = appState?.state?.sesionActiva === "SI";

	useEffect(() => {
		const script = document.createElement("script");
		script.async = true;
		script.src = "https://checkout.epayco.co/checkout.js";
		document.body.appendChild(script);

		script.onload = () => {
			const handler = window?.ePayco?.checkout
				? window.ePayco.checkout.configure({
						key: "215ad17b84c9c1db3947a4919b7f09ab",
						// test:window.location.hostname==='makemake.com.co'?false: true
						test: false,
				  })
				: false;
			window.epaycoHandler = handler;
		};

		return () => {
			document.body.removeChild(script);
		};
	}, []);

	return (
		<>
			<Switch>
				<Route exact path="/tienda/individual">
					<TiendaGeneral type="individual" />
				</Route>
				<Route exact path="/tienda/individual/:plan">
					<TiendaGeneral type="individual" />
				</Route>
				<Route exact path="/tienda/cursos">
					<TiendaGeneral type="individual" />
				</Route>
				<Redirect exact from="/tienda/institucional" to={"/tienda/institucional/cita"} />
				<Route exact path="/tienda/institucional/cita">
					<PlanInstituciones />
				</Route>
				<Redirect exact from="/tienda/demo" to={"/tienda/institucional/demo"} />
				<Route exact path="/tienda/institucional/demo">
					<PlanInstituciones />
				</Route>
				<Redirect exact from="/tienda/brochure" to={"/tienda/institucional/brochure"} />
				<Route exact path="/tienda/institucional/brochure">
					<PlanInstituciones />
				</Route>
				<Redirect exact from="/tienda/contactanos" to={"/tienda/institucional/contactanos"} />
				<Route exact path="/tienda/institucional/contactanos">
					<PlanInstituciones />
				</Route>
				<Route
					exact
					path="/tienda/compra/estado-transaccion"
					component={EstadoTransaccion}
				/>
				<Redirect exact from="/tienda/" to={"/tienda/institucional"} />
				<Route component={Error404} />
			</Switch>
		</>
	);
};

/**
 *Componente que contiene el tab panel con los 3 tipos de tiendas actuales.
 */
const TiendaGeneral = (props) => {
	const { type } = props;
	const lang = useDefaultLangByCountry({ country: "us", defaultLang: "en" });
	const textos = useTranslations({
		section: "tienda_makemake",
		lang: type === "institucional" ? lang : null,
	});
	return (
		<div className="tienda-makemake">
			<div className="main-title tienda-bg">
				<div className="container h-100">
					<div className="row align-items-center h-100">
						<div className="col-12 col-lg-7 col-xl-6">
							<h1 className="text-title red-inverse--color">
								{type === "individual"
									? textos?.tienda?.familias?.titulo || "Para familias"
									: textos?.tienda?.instituciones?.titulo ||
									  "Para  instituciones"}
							</h1>
						</div>
						<div className="col-12 col-lg-5 col-xl-6 onprimary--color">
							{type !== "individual" && (
								<p className="text-title">
									{textos?.tienda?.instituciones?.subtitulo ||
										"MakeMake es un servicio de lectura diseñado para colegios y bibliotecas. ¡Hablemos!"}
								</p>
							)}

							{type === "individual" && (
								<>
									<p className="text-title font-weight-bold">
										{textos?.tienda?.familias?.subtitulo ||
											"Suscribete a MakeMake"}
									</p>
									<p>
										{textos?.tienda?.familias?.descripcion ||
											"¡Explora las opciones que tenemos para ti!"}
									</p>
								</>
							)}
						</div>
					</div>
				</div>
			</div>

			{type === "individual" && <TabPanelTiendaIndividual />}
		</div>
	);
};

