
import axios from "axios"
import { GENERAL_URL_API, STREAMER_URL_API } from "../../components/Store";


/**
 * Este servicio devuelve un objeto con el manifest 
 * @param {{abortSignal:AbortSignal, data:{epubUrl:String}}} 
 * @returns {Promise<{manifest:string, ditaUrl:string}>} retorna un objeto con los datos de la institución
 */
const getBookManifest = async ({ abortSignal = null, data }) => {
  try {
    axios.defaults.baseURL = GENERAL_URL_API;
    axios.defaults.withCredentials = true;


    const fullData = {
      url: data?.epubUrl || ''
    }

    const params = Object.entries(fullData)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&')

    const response = await axios({
      method: 'GET',
      url: `${STREAMER_URL_API}/getManifest?${params}`,
      signal: abortSignal || null,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    })

    const datos = response.data;
    if (datos === 'Error') {
      return new Error(datos);
    }
    return datos || {};

  } catch (error) {
    return Promise.reject(error);
  }

}
/**
 * Este servicio devuelve los assets de un libro epub
 * @param {{abortSignal:AbortSignal, data:{epubManifest:String}}}  
 * @returns {Promise<{book:string[], styles:{type:string, url:string}[]}>} 
 */
const getAssets = async ({ abortSignal = null, data }) => {
  try {
    axios.defaults.baseURL = GENERAL_URL_API;
    axios.defaults.withCredentials = true;

    const formData = new FormData();
    formData.append('manifest', data?.epubManifest)

    const response = await axios({
      method: 'POST',
      url: `assets/streamerApi`,
      signal: abortSignal || null,
      headers: { "Content-Type": "multipart/form-data" },
      data: formData
    })

    const datos = response.data;
    if (datos === 'Error') {
      return new Error(datos);
    }
    return {
      book:Array.isArray(datos?.book)?datos?.book:[],
      styles:Array.isArray(datos?.styles)?datos?.styles:[],
    };

  } catch (error) {
    return Promise.reject(error);
  }

}

export const EpubAPI = {
  getBookManifest,
  getAssets
}