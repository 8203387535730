import React, { Children, isValidElement, useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { CheckBox } from "../../components/shared/checkbox";
import useObtenerSubsecciones from "../../js/translations/useObtenerSubsecciones";
import _ from 'lodash';
import { store } from "../../components/Store";
import useIsMobile from "../../js/utilities/useIsMobile";


/**
 * Este componente renderiza la tarjeta de plan, en la tienda makemake.
 * @param {boolean} isFavorite booleano que indica si es un plan favorito. Este añade la bandera de favorito y cambia el texto del botón.
 * @param {string} confirmacionUrl string correspondiente al path de la URL a donde debe dirigirse.
 * @type {TitleHeader} props.children  - Título del plan
 * @type {CardHeader} props.children  - Imagen del plan
 * @type {PriceDescription} props.children  - Precio y descripción
 * @type {FeaturesList} props.children  - Lista de características
 */

const TarjetaPlanes = (props) => {
  const { children, isFavorite, onClickBuyButton} = props;
  const { isReady, textosSubSecciones: textos } = useObtenerSubsecciones('tienda_makemake')

  /**
   * Esta función carga el contenido de tipo TitleHeader, CardHeader, PriceDescription y FeaturesList
   */

  const cargarTexto = () => {
    return Children.map(children, (child) => {
      if (isValidElement(child) && child.type === TitleHeader) {
        return (
          <>{child}</>
        )
      }
    })
  }

  const cargarImagen = () => {
    return Children.map(children, (child) => {
      if (isValidElement(child) && child.type === CardHeader) {
        return (
          <>{child}</>
        )
      }
    })
  }

  const cargarContenido = () => {
    return Children.map(children, (child) => {
      if (isValidElement(child) && child.type === FeaturesList) {
        return (
          <>{child}</>
        )
      }
    })
  }

  const cargarPrecio = () => {
    return Children.map(children, (child) => {
      if (isValidElement(child) && child.type === PriceDescription) {
        return (
          <>{child}</>
        )
      }
    })
  }

  const [title, setTitle] = useState(cargarTexto())
  const [imagen, setImagen] = useState(cargarImagen());
  const [contenido, setContenido] = useState(cargarContenido());
  const [precio, setPrecio] = useState(cargarPrecio());

  useEffect(() => {
    setContenido(cargarContenido())
    setImagen(cargarImagen())
    setTitle(cargarTexto())
    setPrecio(cargarPrecio())
  }, [children, isReady])

  return (
    <>
      <div className={`new-card  ${isFavorite ? 'tagIsFavorite' : 'tagIsNotFavorite'}`}>
        {title}
        <div className="mx-auto d-md-flex">
          {imagen}
          {isFavorite &&
            <div className="favorite-plan">{textos?.individual?.interfaz?.favorito || 'Nuestro favorito'}</div>
          }
          <div className="col-md-7 p-3 d-flex flex-column justify-content-center">
            {contenido}
            <div className="d-flex my-4">
              {precio}
              <button
                className={`btn-tarjeta-plan ${isFavorite ? "w-75" : "w-50"} focusable-red`}
                onClick={() => onClickBuyButton && onClickBuyButton()}
              >
                {isFavorite ? textos?.individual?.interfaz?.btn_agendar || 'Agenda una cita' : textos?.individual?.interfaz?.btn_quiero || '¡Lo quiero!'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
TarjetaPlanes.propTypes = {
  /**
   * Booleano que indica si es un plan favorito. Este añade la bandera de favorito y cambia el texto del botón.
   */
  isFavorite: PropTypes.bool,

  /**
   * Función que se ejecuta cuando el botón de compra es accionado.
   */
  onClickBuyButton: PropTypes.func,
}

/**
 *  Renderiza el título de la tarjeta
 * @param {object} title objeto que contiene el nombre del plan. 
 * Este debe tener un valor por defecto y el path de traducción
 * @returns TitleHeader
 */
const TitleHeader = (props) => {
  const { title } = props;
  const { textosSubSecciones: textos } = useObtenerSubsecciones('tienda_makemake')
  return (
    <>
      <div className={`column-title p-2 mx-auto text-background card-description`}>
        <h2>{_.get(textos, title.translationPath) || title.default}</h2>
      </div>
    </>
  )
}

/**
 *  Renderiza el encabezado de la tarjeta
 * @param {string} image string equivale a la ruta src correspondiente a la imagen del plan
 * @param {string} colorPlan string equivalente a la clase que se usa para dar color a la tarjeta.
 * Actualmente el proyecto cuenta con las siguientes clases:red-plan, blue-plan, green-plan y yellow-plan 
 * @returns CardHeader
 */
const CardHeader = (props) => {
  const { image, colorPlan } = props;
  const isMobile = useIsMobile();
  return (
    <>
      <div className={`col-md-5 px-0 px-0 column-left-individual ${colorPlan}`}>
        <div className="img-course" style={{ backgroundImage: `url(${isMobile ? image.normal : !isMobile ? image.thumb : '/img/no-imagen3.png'})` }}></div>
      </div>
    </>
  )
}

/**
 * Función que renderiza la descripción y la lista de características del plan
 * @param {array} list Arreglo que contiene los textos de la lista de características del plan
 * @param {object} description objeto que contiene los textos correspondientes a la descripción
 * @returns FeaturesList
 */
const FeaturesList = (props) => {
  const { textosSubSecciones: textos } = useObtenerSubsecciones('tienda_makemake')
  const { list, description } = props;
  return (
    <div className="features-list">
      <p className="description my-4 onprimary--color">{_.get(textos, description?.translationPath) || description?.default}</p>
      <ul className="px-3 mb-3">
        {list.map((element, i) => {
          return (
            <li key={`element-${i}`} className="d-flex align-items-center">
              <i className="fas fa-solid fa-check mt-1 mr-3"></i>
              <p dangerouslySetInnerHTML={{ __html: _.get(textos, element?.translationPath) || element?.default || 'N/A' }}></p>
            </li>
            
          )
        })}
      </ul>

    </div>
  )
}

/**
 * Esta función renderiza el precio dentro de la tarjeta
 * @param {object} price objeto que contiene el valor del plan. este debe tener el valor en cop y usd por ahora.
 * @returns PriceDescription
 */
const PriceDescription = (props) => {

  const { price, descuento} = props;
  const globalState = useContext(store);
  const { state } = globalState;
  const { pais } = state;

  const finalPrice = price && pais === 'CO' ? price?.cop || 'n/a' : price?.usd || 'n/a';
  const offer = descuento && pais === 'CO' ? descuento?.cop || 'n/a' : descuento?.usd || 'n/a';
  const moneda = pais === 'CO' ? 'COP' : 'USD';
  const isOffer = descuento?.activado;

  return (
    <>
      {price &&
        <div className="price-description m-auto">
          {
            isOffer&&
            <p className="font-weight-normal" >
              <span style={{fontSize:'.7em', textDecorationLine:'line-through'}}>
              ${finalPrice} {moneda}
              </span>
            <span className="font-weight-bold" style={{fontSize:'.7em'}}> {offer?.cantidad} OFF</span>
            </p>
          }
          <p className="price" style={{fontSize:'1em'}}>${isOffer?offer?.precio:finalPrice} <span className="currency">{moneda}</span>
          </p>
        </div>
      }
    </>
  )
}

export { TarjetaPlanes, TitleHeader, CardHeader, PriceDescription, FeaturesList };