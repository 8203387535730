import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useModalManager } from "../../hooks/useModalManager";
import { Icon } from "@iconify/react";
import { useWindowOpen } from "../../hooks/useWindowOpen";

/**
 * Modal que se usa al abrir una ventana con un curso de academia en otra pestaña del navegador
 * @returns ModalExternalPageAcademia
 */
const ModalExternalPageAcademia = (props) => {
  const {modalId, visible, setVisible,  texto, urlRedirect } = props;
  const modalManager = useModalManager();
  const newWindowTab = useWindowOpen();

 
  useEffect(() => {
    let closeTimeout = null;
    if (visible == true) {
      modalManager.setVisible(modalId, true)
      closeTimeout = setTimeout(() => {
      newWindowTab.open('_blank', urlRedirect)
        setVisible&&setVisible(false)
      }, 4000)
    }else{
      modalManager.setVisible(modalId, false)
    }

    return ()=>{
      clearTimeout(closeTimeout);
    }
  }, [visible])

  return (
    <>
      <div className="micromodal-slide" id={modalId} aria-hidden="true">
        <div className="modal__overlay">
          <div
            className="modal__container_instalacion  background--bg"
            role="dialog"
            aria-modal="true"
            aria-describedby={'modal-dialogo'}
          >
            <div className="w-100 d-flex justify-content-end">
            <button className="onbackground-primary--color" aria-label="Cerrar/close" onClick={() => setVisible(false)}>
              <Icon icon="mdi:close-thick" width={30} />
            </button>
            </div>
            <div className="d-flex flex-column-reverse flex-md-row justify-content-center align-items-center">
              <div className="d-flex justify-content-center align-items-center">
                <div className="col-11 text-center onbackground-primary--color">
                  <p className="onbackground-primary--color" id={'modal-dialogo'} >{texto}</p>
                  <Icon icon="fa:spinner" className={'spinner-rotation'} />
                </div>
              </div>
                <img className="img-fluid" src="/img/monst.png" alt='imagen ' />
            </div>

          </div>
        </div>
      </div>
    </>
  )
}
export default ModalExternalPageAcademia;

ModalExternalPageAcademia.propTypes = {
  /**
   * Booleano que indica si la ventana se encuentra activa
   */
  visible: PropTypes.bool, 
  /**
   * Función que cambia el estado de la ventana activa
  */
  setVisible: PropTypes.func, 
  /**
   * string que equivale al texto de la ventana
   */
  texto: PropTypes.string,
  /**
   * Equivale a la url a donde debe dirigir la nueva pestaña del navegador. 
  */ 
  urlRedirect: PropTypes.string
}