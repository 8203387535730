import React from "react";
import { useHistory } from "react-router-dom";
import { CuraduriaCard } from "./curaduria-card";
import { trackWindowScroll } from "react-lazy-load-image-component";
import { NoResults } from "../components/shared/no-results";
import { ASSETS_URL } from "../components/Store";
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";
import PropTypes from 'prop-types';
import { useSyncPagination } from "../hooks/usePagination";
import InfiniteScroll from "react-infinite-scroll-component";
import { Icon } from "@iconify/react";

/**
 * Componente que renderiza el listado de series,  recibe como parametro un arreglo de series.
 */
const Series = ({ series }) => {
    const { textosSubSecciones: textos } = useObtenerSubsecciones('curadurias');
    const history = useHistory();
    series = Array.isArray(series) ? series : [];
    const titulo = series?.length !== 1 ? `${textos?.series?.titulo?.plural || 'Series'}` : `${textos?.series?.titulo?.singular || 'Serie'}`;
    const pagination = useSyncPagination({ itemsPerPage: 8, data: series, nextPageDelay: 200 });

    return <div className="w-100 d-flex flex-column">
        <h2 className="primary-font p-3 pl-4 onbackground-primary--color">{series?.length} {titulo}</h2>
        <div className="px-3">
            {
                series?.length > 0 ?
                    <>
                        <InfiniteScroll
                            style={{ overflow: 'visible' }}
                            className='row align-self-center'
                            dataLength={pagination.paginatedData.length}
                            next={pagination.nextPage}
                            hasMore={pagination.hasMore}
                            scrollThreshold={0.86}
                            loader={
                                <div
                                    className="secondary-inverse--color container-fluid w-100 d-flex justify-content-center align-items-center col-md-10 col-lg-10">
                                    <Icon icon="icomoon-free:spinner9" width={60} className={'spinner-rotation'} />
                                </div>
                            }
                        >
                            {
                                pagination.paginatedData?.map((serie, i) => {
                                    return <div key={i} className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                                        <CuraduriaCard
                                            key={i}
                                            imgProps={{ className: 'px-lg-4 px-xl-5' }}
                                            // data={{ ...serie,  imgUrl: `https://nf.app.makemake.reallabs.com.co/makemake/coleccion/pj/img/imagenHomeOptim.png` }}
                                            data={{ ...serie, imgUrl: `${ASSETS_URL}${serie?.imgUrlInterna}` }}
                                            onClick={() => history.push(serie?.url)} />
                                    </div>
                                })
                            }

                        </InfiniteScroll>
                        {
                            pagination.hasMore &&

                            <div className='w-100 d-flex flex-column justify-content-center align-items-center'>
                                <p className='secondary-inverse--color bold'>
                                    {`${textos?.scroll?.descripcion ||
                                        ' Desplazate hacia abajo para cargar más contenido.'}`
                                    }

                                </p>
                                <Icon icon="mdi:mouse-move-down" className='secondary-inverse--color text-28 mx-2' width={30} />
                            </div>
                        }

                    </>
                    : <NoResults title={'Aún no hay series'} message={'No hay series asociadas a tus libros.'} />
            }
        </div>
    </div>
}

Series.propTypes = {
    /**
     * Arreglo con las series
     */
    series: PropTypes.arrayOf(PropTypes.shape({
        nombre: PropTypes.string,
        descripcion: PropTypes.string,
        url: PropTypes.string,
        imgUrl: PropTypes.string,
        imgUrlInterna: PropTypes.string
    }))
}

export const SeriesList = trackWindowScroll(Series)