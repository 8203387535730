import { Icon } from "@iconify/react";
import React, { useContext, useEffect, useState } from "react";
import { useImmer } from "use-immer";
import { Accordion } from "../../../components/shared/accordion";
import { Breadcrumbs } from "../../../components/shared/breadcrums";
import { useEpayco } from "../../../hooks/useEpayco";
import useObtenerSubsecciones from "../../../js/translations/useObtenerSubsecciones";
import { TIENDA_MAKEMAKE_DATA } from "../../data/data";
import { BarraCompra } from "../barra-compra";
import { PlanLectura } from "./plan-lectura";
import _ from 'lodash';



/**
 * 
 * Componente que carga toda la sección y lógica del plan de lecturas,
 *  muestra los diferentes planes y maneja la lógica de compra de cada uno.
 */
export const LecturasMakeMake = () => {
    const {textosSubSecciones:textos} = useObtenerSubsecciones('tienda_makemake');
    const epayco = useEpayco();
    const lecturaMakeMakeData = { ...TIENDA_MAKEMAKE_DATA.individual.planes.semestral.find(plan => plan.id === 'lecturas-makemake') };
    const planesLectura = Array.isArray(lecturaMakeMakeData?.planesLectura) ? lecturaMakeMakeData?.planesLectura : []
    const preguntasFrecuentes = Array.isArray(lecturaMakeMakeData?.preguntasFrecuentes)?lecturaMakeMakeData?.preguntasFrecuentes:[];

    const [planes, setPlanes] = useImmer(planesLectura);
    const [selectedPlan, setSelectedPlan] = useState(null)


    const handleSelectedPlan = (selected, plan) => {
        if (selected) {
            const mapped = [...planes.map(pl => {
                return {
                    ...pl,
                    checked: plan?.id === pl?.id ? selected : false
                }
            })]
            setSelectedPlan(plan)
            setPlanes(mapped);
        } else {

            setPlanes(draft => {
                const index = draft.findIndex(pl => pl?.id === plan?.id);
                draft[index].checked = selected;
            });
        }
    }

    const QuestionAccordion = () => {
        return <Accordion.Container>
            {
                preguntasFrecuentes.map((item,i)=>{
                    return (
                        <Accordion.Item key={i} itemId={`item-${i}`}>
                            <Accordion.ItemButton label={_.get(textos, item?.pregunta?.translationPath) || item?.pregunta?.default || 'N/A'} />
                            <Accordion.Content>
                                <p className="onbackground-primary--color">
                                    {
                                        item?.respuesta?.html?
                                            <span dangerouslySetInnerHTML={{__html:_.get(textos, item?.respuesta?.translationPath) || item?.respuesta?.default || 'N/A'}}></span>
                                            :
                                            <>{_.get(textos, item?.respuesta?.translationPath) || item?.respuesta?.default || 'N/A'}</>
                                    }
                                </p>
                            </Accordion.Content>
                        </Accordion.Item>

                    )
                })
            }
        </Accordion.Container>
    }


    useEffect(() => {
        const filtered = planes?.filter(pl => pl.checked);
        const hasPlanChecked = filtered.length > 0
        if (hasPlanChecked === false) {
            setSelectedPlan(null);
        }
    }, [planes])

    return (<section>
        <Breadcrumbs.Box className={'tienda-breadcrumbs'}>
            <Breadcrumbs.Crumb
                className={`crumb`}
                activeClassName={'current'}
                name={textos?.individual?.lecturas_makemake?.breadcrumb_1 || 'Planes'}
                url={'/tienda/individual?tipo=semestral'} />
            <Breadcrumbs.Crumb
                className={`crumb`}
                activeClassName={'current'}
                name={textos?.individual?.lecturas_makemake?.breadcrumb_2 ||  'Lecturas MakeMake'}
                url={'/tienda/individual/lecturas-makemake'}
                last={true} />
        </Breadcrumbs.Box>
        <div className="plan-section-layout lectura-makemake">
            <div className="d-lg-flex justify-content-between">
                <div className="sidenav" >
                    <h1 className="title">
                        {_.get(textos, lecturaMakeMakeData?.nombre?.translationPath) || lecturaMakeMakeData?.nombre?.default || 'N/A'}
                    </h1>
                    <div className="details"  >
                        {
                            lecturaMakeMakeData?.detalles?.map((detalle, i) => {
                                return <p className="detail" key={i}>
                                    <Icon className="icon" icon="mdi:check-bold" />
                                    {
                                        detalle?.html?
                                            <span dangerouslySetInnerHTML={{__html:_.get(textos, detalle?.translationPath) || detalle?.default || 'N/A'}}></span>
                                            :
                                            <>{_.get(textos, detalle?.translationPath) || detalle?.default || 'N/A'}</>
                                    }
                                </p>
                            })
                        }
                    </div>
                    <div className="p-3 mb-5">
                        <p className="secondary-inverse--color font-weight-bold"> {textos?.plan_lectura?.ideal_para || 'Ideal para:'}</p>
                        <ul className="list">
                            {
                                lecturaMakeMakeData?.idealPara.map((item, i) => {
                                    return <li key={i}>{_.get(textos, item?.translationPath) || item?.default || 'N/A'}</li>
                                })
                            }
                        </ul>
                    </div>
                    <div className="desktop-accordion">
                        {QuestionAccordion()}
                    </div>
                </div>
                <div className="content_layout">
                    {
                        planes.map((plan, i) => {
                            return <PlanLectura
                                key={i}
                                data={{ ...plan, idealPara: lecturaMakeMakeData?.idealPara }}
                                isSelected={plan?.checked}
                                onChangeSelection={(selected) => handleSelectedPlan(selected, plan)} />
                        })
                    }
                </div>
                <div className="mobile-accordion">
                    {QuestionAccordion()}
                </div>
            </div>

            <BarraCompra
                enableBuyButton={selectedPlan !== null}
                stylePrice={"price-info"}
                offer={lecturaMakeMakeData?.descuento}
                priceText={lecturaMakeMakeData?.precio}
                onClick={() => epayco.buy(selectedPlan?.epayco)} />
        </div>

    </section>)
}


