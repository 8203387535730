
import axios from "axios"
import { GENERAL_URL_API } from "../../components/Store";

/**
 * Este servicio verifica si el cliente actual tiene habilitado el modo PWA
 * @param {{abortSignal:AbortSignal}} param0 
 * @returns {Promise<boolean>} retorna true si el cliente actual tiene habilitado el modo PWA, false si no
 */
const pwaHabilitado = async ({abortSignal})=>{
    try {
        axios.defaults.baseURL = GENERAL_URL_API;
        axios.defaults.withCredentials = true;
        const response = await axios({
          method: 'POST',
          url: 'assets/permisosPWA',
          signal: abortSignal
        })
        const permisos = response.data;
        return  Boolean(permisos.modoPWA) || false;
    
      } catch (error) {
        return Promise.reject(error);
      }
    
}

/**
 * Este servicio devuelve on objeto con los permisos del cliente actual
 * @param {{abortSignal:AbortSignal}} param0 
 * @returns {Promise<object>} retorna un objeto con los permisos del cliente actual
 */
const obtenerPermisos = async ({abortSignal})=>{
    try {
        axios.defaults.baseURL = GENERAL_URL_API;
        axios.defaults.withCredentials = true;
        const response = await axios({
          method: 'POST',
          url: 'assets/permisosPWA',
          signal: abortSignal
        })
        const permisos = response.data;
        return {
            modoPWA:Boolean(permisos.modoPWA) || false,
        };
    
      } catch (error) {
        return Promise.reject(error);
      }
    
}

export const PermisosAPI = {
    pwaHabilitado,
    obtenerPermisos
}