import React from "react"
import { Img } from "../components/shared/img"
import ProptTypes from 'prop-types';

/**
 * tarjeta botón que muestra la información de una colección de tipo 'país'.
 */
export const CountryCard = ({ data, onClick, imgProps }) => {
  return <button className="focusable-secondary country-card p-2" onClick={() => onClick && onClick()}>
    <div className="image-container" >
      <Img {...imgProps} className={` ${imgProps?.className}`} src={`${data?.imgUrl}`} alt={data?.nombre || 'N/A'} />
    </div>
    <div className="p-2">
      <p className="w-100 title text-left secondary-font py-1">{data?.nombre || 'N/A'}</p>
    </div>
  </button>
}

CountryCard.propTypes = {
  /**
   * Objeto con la info de la colección.
   */
  data: ProptTypes.shape({
    nombre: ProptTypes.string,
    descripcion: ProptTypes.string,
    imgUrl: ProptTypes.string,
  }).isRequired,
  /**
   * objeto con props de elemento img que se pasan a la imagen
   */
  imgProps: ProptTypes.object,
  /**
   * Evento click para el botón (tarjeta)
   */
  onClick: ProptTypes.func
}
