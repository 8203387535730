import { useState } from "react";
import normalize from "../../js/utilities/normalizeString";
import { useEffect } from "react";
import { useUserBooks } from "../useUserBooks";


/**
 * Este hook recibe un objeto con el parametro 'text',
 * 
 * A partir de ese parametro se realiza una búsqueda de concidencias en los libros del usuario y devuelve el listado.
 * 
 * Las coincidencias se filtran con base a: titulo, autor, editorial, tema y tags.
 * 
 * @param {string} text - texto que se quiere buscar
 * @returns {{matches:[], loading:boolean}}
 */
export const useSearchBooksManager = ({ text }) => {
    const [matches, setMatches] = useState([{}, {}, {}]);
    const userBooksData = useUserBooks();
    const books = userBooksData?.books;

    const loadMatches = () => {
        const _text = normalize(text);
        const _matches = books.filter(book => {
            let tags = Array.isArray(book?.tags) ? book?.tags : [book?.tag1, book?.tag2, book?.tag3, book?.tag4, book?.tag5,];
            tags = tags.map(tag => normalize(tag));
            return tags.includes(_text)
                ||
                normalize(book?.tema).includes(_text)
                ||
                normalize(book?.editorial).includes(_text)
                ||
                normalize(book?.title).includes(_text)
                ||
                normalize(book?.author).includes(_text)
        }

        )
        const sorted = _matches.sort((a, b) => {
            if (a?.title < b?.title) {
                return -1;
            }
            if (a?.title > b?.title) {
                return 1;
            }
            return 0;
        })
        setMatches(sorted)
    }

    useEffect(() => {
        loadMatches();
    }, [text, books])

    return {
        loading: userBooksData.loading,
        matches
    }
}

