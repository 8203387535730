import React, { useEffect } from "react";
import { useImmer } from 'use-immer';
import _ from 'lodash';

import normalizeString from "../../js/utilities/normalizeString";
import { cambiarSaltoLinea } from "../../js/functions/cambiarSaltoLinea";
import { ASSETS_URL } from "../../components/Store.js";
import { METADATA_TRANSLATE_FICHA } from "./translateMetadata";

import PropTypes from "prop-types";
import { ProtectedLinkFicha } from "../protected-link-ficha.js";
import { Link } from "react-router-dom";

/**
 * Este componente recibe los props necesarios para mostrar la información basica del libro en la sección de la Ficha. Entre sus funciones esta actualizar los filtros según el idioma activo y retornar el parametro buscable de la editorial
 * @returns InfoBasica
 */
const InfoBasica = (props) => {
  const { textoFicha, metadata, idioma } = props;

  // filtros  de la ficha
  const [valores, setValores] = useImmer({ nivel: '', genero: '', materia: '' });
  const expresion = /\s?\,\s?|\s\y\s?/;

  //Actualiza el texto de nivel
  useEffect(() => {
    actualizarFiltros('nivel', metadata?.nivelDeLectura);
    actualizarFiltros('genero', metadata?.genero);
    actualizarFiltros('materia', metadata?.materia);
  }, [metadata])

  /**
   * Esta función compara el METADATA con las opciones correspondientes al filtro y retorna el valor del path correspondiente al texto con traducción
   * @param {string} filtro //estado que se va a actualizar
   * @param {string} data //texto del metada
   */
  const actualizarFiltros = (filtro, data) => {
    const lista = METADATA_TRANSLATE_FICHA[filtro];
    const normalizeValue = normalizeString(data);
    lista.map((item) => {
      if (normalizeValue === item.nombre) {
        setValores(draft => { draft[filtro] = item.pathTranslate });
      }
    })
  }

/**
 * Esta función transforma el nombre de la editorial en un parametro buscable 
 * @param {string} caracter //string: caracter que debe ser reemplazado por nuevoCaracter
 * @param {string} nuevoCaracter //string: nuevo caracter
 * @param {string} texto //string: nombre de la editorial
 * @returns 
 */
  const newUrlEditorial = (caracter, nuevoCaracter, texto) => {
    var buscarCaracter = texto.search(caracter);
    var newUrl;
    if (buscarCaracter === -1) {
      newUrl = normalizeString(texto).replace(caracter, nuevoCaracter);
      return newUrl;
    } else {
      return normalizeString(texto);
    }
  };

  return (
    <>
      <div className="row mb-4 mb-md-0">
        <div className="col-md-8 order-md-1">
          {/* Autor */}
          <div className="mb-4">
            {metadata?.autor?.split(expresion).map((item, index) => {
              return (
                <div key={`autor-${index}`} className="d-inline">
                  <ProtectedLinkFicha
                    to={`/busqueda?sch=${item}`}
                    aria-label={`${textoFicha?.info_basica?.autor || 'Autor'} ${item} `}
                    className="fichalibro-data--link focusable-secondary"
                  >
                    {item}
                  </ProtectedLinkFicha>
                  <span className="fichalibro-data--link mr-3">{metadata?.autor?.split(expresion)?.length !== (index + 1) && ','}</span>
                </div>

              )
            })}

            <p className="fichalibro-data--name">
              {metadata?.autor?.split(expresion)?.length > 1 ?
                textoFicha?.info_basica?.autores || 'Autores'
                :
                textoFicha?.info_basica?.autor || 'Autor'
              }
            </p>
          </div>
          {/* otro colaborador */}
          {(() => {
            if (metadata?.contribuidor !== "") {
              return (
                <div className="mb-4">
                  {
                    metadata?.contribuidor?.split(expresion)?.map((item, index) => {
                      return (
                        <div key={`autor-${index}`} className="d-inline">
                          <ProtectedLinkFicha
                            to={`/busqueda?sch=${item}`}
                            aria-label={`${idioma === "es" ? metadata?.funcionAutorSecundario || 'Otros' : textoFicha?.info_basica?.otros || 'Otros colaboradores'} ${item}`}
                            className="fichalibro-data--link focusable-secondary"
                          >
                            {item}
                          </ProtectedLinkFicha>
                          <span className="fichalibro-data--link mr-3">{metadata?.contribuidor?.split(expresion)?.length !== (index + 1) && ','}</span>
                        </div>
                      )
                    })
                  }

                  <p className="fichalibro-data--name">
                    {idioma === "es" ? metadata?.funcionAutorSecundario || 'Otros' : textoFicha?.info_basica?.otros || 'Otros colaboradores'}
                  </p>
                </div>
              );
            }
          })()}
          {/* editorial */}
          <div className="mb-4">
            {
              metadata?.editorial?.split(expresion)?.map((item, index) => {
                return (
                  <div key={`editorial-${index}`} className="d-inline">
                    <Link
                      to={`/editorial/${newUrlEditorial("---", "-", item || '')}`}
                      aria-label={`${textoFicha?.info_basica?.editorial || 'Editorial'} ${item}`}
                      className="fichalibro-data--link focusable-secondary"
                    >
                      {item}
                    </Link>
                    <span className="fichalibro-data--link mr-3">{metadata?.editorial?.split(expresion)?.length !== (index + 1) && ','}</span>
                  </div>
                )
              })
            }

            <p className="fichalibro-data--name">
              {metadata?.editorial?.split(expresion).length > 1 ?
                textoFicha?.info_basica?.editoriales || 'Editoriales'
                :
                textoFicha?.info_basica?.editorial || 'Editorial'
              }
            </p>
          </div>
        </div>

        <div className="col-md-12 mb-4 order-md-3">
          <div className="d-flex flex-wrap">
            {metadata?.tagArray &&
              metadata?.tagArray?.map((item, index) => {
                if(index!== 4){
                  return (
                    <ProtectedLinkFicha
                      key={index}
                      to={idioma === "es" ? `/busqueda?sch=${item.tag}` : `/busqueda?sch=${item.tagEng}` }
                      className="ficha-btns-etiquetas px-3 py-1 mr-2 mb-2 focusable-secondary"
                    >
                      {idioma === "es" ? item?.tag || 'tag' : item?.tagEng || item?.tag || 'tagEng'}
                    </ProtectedLinkFicha>
                  )
                }
              })
            }

          </div>
          <p className="fichalibro-data--name mb-4">{textoFicha?.mas_detalles?.etiquetas || 'Etiquetas'}</p>
        </div>

        <div className="col-md-4 order-md-2">
          {metadata?.tieneActividad === true && (
            <ProtectedLinkFicha to={`/busqueda?actividad=true`} className="ficha-actividad">
              <img
                src="/img/bombilla.png"
                alt="imagen de bombilla"
                className="mr-3"
              />
              <p>{textoFicha?.mas_detalles?.incluye || 'Incluye actividad'}</p>
            </ProtectedLinkFicha>
          )}
        </div>


      </div>

      <div>
        {/* nivel, genero y materia */}
        <div className="row">
          <div className="col-md-4 mb-4 mb-md-0">
            <ProtectedLinkFicha
              data-nivel={metadata?.nivelDeLectura}
              to={`/busqueda?nivel=${encodeURIComponent(metadata?.nivelDeLectura)}`}
              className="data-ficha-features ficha-libro-nivel focusable-secondary"
            >
              {_.get(textoFicha, valores.nivel) || 'N/A'}
            </ProtectedLinkFicha>
          </div>
          <div className="col-md-4 mb-4 mb-md-0">
            <ProtectedLinkFicha
              data-materia={metadata.materia}
              to={`/busqueda?tema=${metadata.materia}`}
              className="data-ficha-features ficha-libro-materia focusable-secondary"
            >
              <p>{textoFicha?.info_basica?.materia || 'Materia'}</p>
              <p>{_.get(textoFicha, valores.materia) || 'N/A'}</p>
            </ProtectedLinkFicha>
          </div>
          <div className="col-md-4">
            <ProtectedLinkFicha
              className="data-ficha-features fichalibro-data--genero focusable-red d-flex justify-content-center align-items-center"
              to={`/busqueda?genero=${metadata?.genero}`}
            >
              <img
                alt=""
                className="img-fluid mr-2"
                src={`${ASSETS_URL}/genero/img/${normalizeString(metadata?.genero)}_blanco.svg`}
              />
              <p>{_.get(textoFicha, valores.genero) || 'N/A'}</p>
            </ProtectedLinkFicha>
          </div>
        </div>

        {/* Descripcion */}
        <div className="fichalibro-data--text my-4">
          {idioma === 'es' ?
            cambiarSaltoLinea("<br>", metadata?.descripcion || 'N/A')
            :
            cambiarSaltoLinea("<br>", metadata?.descripcionEn || metadata?.descripcion || 'N/A')
          }
          {metadata?.esBilingue === 1 &&
            <p>{textoFicha?.interfaz?.bilingue || 'Este libro es bilingüe.' }</p>
          }
        </div>
      </div>
    </>

  )
}
export default InfoBasica;

InfoBasica.propTypes = {
  /**
   * Objeto que contiene los textos de la ficha. Estos se actualizan según del idioma activo
   */
  textoFicha: PropTypes.object.isRequired,
  /**
   * Objeto que contiene la información del libro 
   */
  metadata: PropTypes.object.isRequired,
  /**
   * String que contiene la abreviación del idioma activo. 'es' en caso de español - 'en' en caso del inglés
   */
  idioma: PropTypes.string,
}