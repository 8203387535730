import React, { useContext, useEffect } from "react"
import { BannerSection } from "../components/banner"
import { memo } from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { BooksSection } from "../components/books-section";
import { GenericHomeRowTitle, HomeRow } from "../components/row-section";
import { MakeMakeFeatures } from "./features";
import PodemosAyudar from "../../PodemosAyudar";
import { DiscoverMore } from "./discover-more";
import useObtenerSubsecciones from "../../js/translations/useObtenerSubsecciones";
import { CollectionSection } from "../components/collection";
import SkeletonHome from "../../skeletons/SkeletonHome";
import { store } from "../../components/Store";
import { useHistory } from "react-router-dom";

/**
 * Componente que muestra la página de inicio del usuario que no está autenticado.
 * @param {Object} props - Props del componente.
 * @param {Object} props.data - Datos de la página de inicio del usuario.
 * @returns {JSX.Element} Elemento JSX que representa la página de inicio del usuario.
 */
export const DefaultHome = memo(({ data }) => {
    const { textosSubSecciones: textosHome } = useObtenerSubsecciones('home');
    const textos = textosHome?.sin_login || {};
    const homeData = data?.data;
    const globalState = useContext(store);
    const isAuth = globalState?.state?.sesionActiva === 'SI';
    const books = Array.isArray(data?.data?.books) ? data?.data?.books : [];
    const history = useHistory();

    useEffect(() => {
        if (data?.loaded && isAuth && books?.length <= 0 ) {
            history.push('/mi-panel/mi-coleccion')
        }
}, [books, isAuth, data])

    useEffect(()=>{
        window.scrollTo(0,0)
    },[])

    return (<section>
        {
            data?.fetching ?
                <SkeletonHome />
                :
                <LazyLoadComponent threshold={.5}>
                    <BannerSection banners={homeData?.banners} />
                    <MakeMakeFeatures />
                    <HomeRow
                        containerProps={{
                            className: 'home-row-yellow'
                        }}
                        titleElement={
                            <GenericHomeRowTitle text={textos?.libros_interactivos || 'Libros Interactivos'} />
                        }>
                        <BooksSection books={homeData?.LibrosInteractivos} autoPlayDelay={5000} />
                    </HomeRow>
                    <HomeRow
                        containerProps={{
                            className: ''
                        }}
                        titleElement={
                            <GenericHomeRowTitle text={textos?.nuestra_coleccion || 'Nuestras colecciones'} />
                        }>
                        <CollectionSection collections={homeData?.colecciones} autoPlayDelay={5500} />
                    </HomeRow>
                    <HomeRow
                        containerProps={{
                            className: 'home-row-purple'
                        }}
                        titleElement={
                            <GenericHomeRowTitle text={textos?.recomendados || 'Recomendados del mes'} />
                        }>
                        <BooksSection books={homeData?.librosRecomendados} autoPlayDelay={5000} />
                    </HomeRow>
                    <DiscoverMore />
                    <PodemosAyudar />
                </LazyLoadComponent>
        }

    </section>)
})