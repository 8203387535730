import React from 'react';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';

/**
 * Este componente renderiza la cuarta sección del club MakeMake que corresponde a la información sobre la comunidad lectora. Este componente hace uso del archivo
 * dataComunidad, el cual es mapeado para renderizar la información de la comunidad y recibe como props los textos de la interfaz. 
 * Se hace uso del componente ModalClubComunidad para visualizar la información completa de la comunidad.
 */

const ComunidadLectora = (props) => {

  const { traducciones } = props;

  return (
    <div className="seccion-morado-claro py-5">
      <div className=" container">
        <div className=" col-lg-10 mx-auto">
          <h2 className="pb-3 titulos-actualizacion-club titulo-violeta-act ">{traducciones?.titulo || 'Comunidad lectora'}</h2>
     
          <p className='pb-3 onbackground-third--color parrafo-acompanamiento'>
            {traducciones?.descripcion ||  '¡Haz parte de la comunidad lectora de MakeMake!  Ùnete y participa'}
            {/* {traducciones?.descripcion || 'En MakeMake nos interesa escuchar a nuestros lectores y lectoras. Te presentamos al grupo de promotores, profesores, lectores empedernidos y expertos a quienes apoyamos. Sigue sus canales y conéctate a un nuevo mundo de lecturas.'} */}
          </p>

          <div className="d-lg-flex justify-content-between py-3">
            <div className="contenedor-inscribete d-sm-flex col-lg-6 p-3 mt-3 m-lg-2">
              <div className="col-md-4 px-0 pr-3 d-flex align-items-center justify-content-center">
                <img alt="niña leyendo" src="/img/club-makemake/nina_club.png" className="img-fluid background-contraste"></img>
              </div>
              <div className="d-flex align-items-center">
                <div>
                  <p className=' text-boletin text-center text-sm-left'>
                    {traducciones?.seccion_boletin?.boletin?.parrafo_inscribete || 'Inscríbete a nuestro boletín para conocer más sobre MakeMake y enterarte de recomendaciones y eventos.'}
                  </p>
                  <Link
                    to={`/boletin/ingresar-datos?email=`}
                    className="my-3 btn btn-planes focusable-red mx-auto d-block"
                  >
                    {traducciones?.seccion_boletin?.boletin?.boton_inscribete ||  'Inscríbete al boletín'}
                  </Link>
                </div>
              </div>
            </div>

            <div className="contenedor-inscribete d-sm-flex col-lg-6 p-3 mt-3 m-lg-2">
              <div className="col-md-4 px-0 pr-3 d-flex align-items-center justify-content-center">
                <img alt="monster-whatsapp" src="/img/club-makemake/monster_club.png" className='img-fluid background-contraste'></img>
              </div>
              <div className="d-flex align-items-center">
                <div>
                  <p className='text-boletin text-center text-sm-left'>
                    {traducciones?.seccion_whats?.whatsapp?.parrafo_whatsapp || 'Quieres ser parte de nuestro grupo de WhatsApp, donde compartimos recomendaciones y noticias sobre la promoción de lectura.'}
                  </p>
                  <a
                    href="https://chat.whatsapp.com/DJWx58BbqL41fIFWEasgyj"
                    className="my-3 btn btn-planes focusable-red mx-auto d-block"
                  >
                    {traducciones?.seccion_whats?.whatsapp?.boton_whatsapp ||  'Unéte a WhatsApp'}
                  </a>
                </div>
              </div>
            </div>

          </div>

          <p className="siguenos-title text-center pt-3 col-md-10 col-lg-9 mx-auto">
            {traducciones?.text_siguenos || 'Síguenos en redes sociales para enterarte de todas nuestras novedades y noticias:'}
          </p>

          <div className="row mx-lg-0 align-items-center justify-content-start">
            <div className="col-md-4 py-5  d-flex justify-content-center ">
              <a
                href="https://www.youtube.com/channel/UCAGRlTJB3fUDRfkVRPVNdzQ"
                className="d-flex align-items-center link-redes-act focusable-red px-2"
                target="_black"
              >
                <div className=' d-flex column'>
                  <div className='d-flex align-items-center'>
                    <img src="/img/club-makemake/icon-youtube2.png" alt="icono youtube" className="img-icon-act mr-3" />
                  </div>
                  <div className=''>
                    <p className='title-redes'>Youtube:</p>
                    @MakeMake
                  </div>
                </div>

              </a>
            </div>
            <div className=" col-md-4 pb-5 py-md-5   d-flex justify-content-center">
              <a
                href="https://www.instagram.com/makemakelibros/"
                className="d-flex align-items-center link-redes-act focusable-red px-2"
                target="_black"
              >
                <div className=' d-flex column'>
                  <div className='d-flex align-items-center'>
                    <img src="/img/club-makemake/icon-instagram2.png" alt="icono instagram" className="img-icon-act mr-3" />
                  </div>
                  <div className=''>
                    <p className='title-redes'>Instagram:</p>
                    @makemakelibros
                  </div>
                </div>
              </a>
            </div>
            <div className=" col-md-4 pb-5 py-md-5 d-flex justify-content-center">
              <a
                href="https://www.facebook.com/Bookmakemake"
                className="d-flex align-items-center link-redes-act focusable-red px-2"
                target="_black"
              >
                <div className=' d-flex column'>
                  <div className='d-flex align-items-center'>
                    <img src="/img/club-makemake/icon-facebook2.png" alt="icono facebook" className="img-icon-act mr-3" />
                  </div>
                  <div className=''>
                    <p className='title-redes'>Facebook:</p>
                    @Bookmakemake
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

    </div>


  )
}

export default ComunidadLectora;

ComunidadLectora.propTypes = {
  /**
   * Es un objeto que trae los textos de la interfaz, especificamente los de mi cuenta. Estos cambian dependiendo del idioma activo.
   */
  traducciones: PropTypes.object.isRequired
}

