import React, { useContext, useState, useEffect } from "react";
import { store, clearDB, getAllFromIndexDB, deleteDB, diasVencimientoDescarga, librosMaxDescargados, } from "../components/Store.js";
import { Link, useHistory } from "react-router-dom";
import MicroModal from "micromodal";
import ModalConfirmacionEliminarDescargas from "../components/ModalConfirmacionEliminarDescargas";
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";
import { LibroOffline } from "./LibroOffline";

/**
 * Este componente se encarga de renderizar el listado de portadas de libros descargados por el usuario (guardado en IndexedDB). También permite eliminar uno o todos los libros para que el usuario pueda descargar nuevos libros, este componente y el componente de visor serán los únicos a los que el usuario podrá acceder cuando no haya una conexión a internet.
 * @returns {JSX.Element} Componente Descargados.
 */
const Descargados = () => {
  const { isReady, textosSubSecciones: descargados } = useObtenerSubsecciones('descargados');
  const globalState = useContext(store);
  const { state } = globalState;
  const [librosCacheados, setLibrosCacheados] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [idLibroEliminar, setIdLibroEliminar] = useState();
  const history = useHistory();
  //Micromodal eliminar
  const [message, setMessage] = useState('');
  const [description, setDescription] = useState('');
  const [funcionMicromodalEliminar, setFuncionMicromodalEliminar] = useState();


  const handleDownloadedBooks = async () => {
    let arrayTemp = [];
    setIsLoading(true);
    await getAllFromIndexDB("tipo", "metadatos").then(function (results) {
      results.forEach((result) => {
        if (arrayTemp.indexOf(result.idLibro) === -1) {
          let fechaVencimientoDescargaTemp = new Date();
          let fechaHoy = new Date();
          fechaVencimientoDescargaTemp.setDate(
            result.fecha.getDate() + diasVencimientoDescarga + 1
          );
          if (
            fechaVencimientoDescargaTemp > fechaHoy &&
            result.username === state.username
          ) {
            getAllFromIndexDB("tipo", "portada").then(function (
              resultsPortada
            ) {
              resultsPortada.forEach((resultPortada) => {
                if (resultPortada.idLibro === result.idLibro) {
                  arrayTemp.push(result.idLibro);
                }
              });
              setLibrosCacheados(arrayTemp);
            });
          } else {
            eliminarLibro(result.idLibro);
          }
        }
      })
    })
      .catch(error => console.log(error))
      .finally(() => {
        setIsLoading(false);
      })
  }
  useEffect(() => {
    if (state.sesionActiva !== "SI") {
      history.push("/login");
    }
  }, [state.sesionActiva])


  useEffect(() => {
    handleDownloadedBooks();
    return () => {
      setIsLoading(true);
    }
  }, [state.username]);


  useEffect(() => {
    //console.log("libros Descargados : ", librosCacheados, "de ", librosMaxDescargados);
    if (librosCacheados.length > librosMaxDescargados) {
      eliminarLibro(librosMaxDescargados[0]);
    }
  }, [librosCacheados]);


  const eliminarLibro = () => {
    let arrayTemp = [];
    getAllFromIndexDB("idLibro", idLibroEliminar).then(function (results) {
      results.forEach((result) => {
        if (arrayTemp.indexOf(result.url) === -1) {
          arrayTemp.push(result.url);
        }
      });
      arrayTemp.forEach((urlABorrar) => {
        //console.log("Borrando", urlABorrar);
        deleteDB(urlABorrar);
      });
    });


    let nuevoArrayCacheados = [];
    librosCacheados.forEach((elemento) => {
      if (elemento != idLibroEliminar) {
        //console.log("agregando ", elemento);
        nuevoArrayCacheados.push(elemento);
      }
    });
    //console.log("nuevoArray ", nuevoArrayCacheados);
    setLibrosCacheados(nuevoArrayCacheados);
  };


  const eliminarTodosLosLibros = () => {
    clearDB();
    setLibrosCacheados([]);
  };

  const mostrarMensajeEliminarTodo = () => {
    MicroModal.show('modal-eliminar-descarga', {
      awaitCloseAnimation: true
    });
    setMessage(descargados?.modal_descarga?.mensaje || "¿Estás seguro?");
    setDescription(descargados?.modal_descarga?.todas_las_descargas || "Estás a punto de borrar todas las descargas por lo que no podrás leer estos libros cuando estés sin conexión.");
    setFuncionMicromodalEliminar("todos");
  }


  const mostrarMensajeEliminarLibro = (data) => {
    MicroModal.show('modal-eliminar-descarga', {
      awaitCloseAnimation: true
    });
    setMessage(descargados?.modal_descarga?.mensaje || "¿Estás seguro?");
    setDescription(`${descargados?.modal_descarga?.borrar_libro?.descripcion_uno || 'Estás a punto de borrar'} "${data.titulo}". ${descargados?.modal_descarga?.borrar_libro?.descripcion_dos || 'por lo que no podrás leerlo cuando estés sin conexión.'}`);
    setFuncionMicromodalEliminar("unLibro")
    setIdLibroEliminar(data.idLibro)
  }

  return (
    <>
      <>
        <div className="focusable-secondary main-container">
          <div className="container pt-5">
            <div className="surface--bg container main-content p-3 p-lg-5 mx-auto ">
              <h2 className="descargados-title mb-3">{descargados?.descargas?.titulo || 'Descargas'}</h2>
              <div className="d-flex flex-wrap justify-content-md-between ">
                <div className="col-md-6 mb-4 mb-lg-0">
                  <p className="download-text onbackground-third--color">
                    {descargados?.descargas?.descripcion || 'En esta sección podrás ver los libros que has descargado para visualizar sin conexión'}
                  </p>
                </div>


                {(() => {
                  if (librosCacheados.length === 0 && navigator.onLine) {
                    return (
                      <>
                        <Link to="/home" className="focusable-red btn btn-primary px-3 px-sm-4 btn-primary--lower col-md-5 px-0 d-flex justify-content-md-end align-items-md-center">
                          {descargados?.descargas?.ver_mas_libros || 'Ver más libros para descargar'} {" "}
                          <i className="fas fa-arrow-right"></i>
                        </Link>
                      </>
                    );
                  }
                })()}

                {librosCacheados.length > 0 &&(
                  <div className="d-flex px-0 col-md-6 justify-content-lg-end align-items-center mt-4 mt-md-0">
                    <button
                      className="focusable-red btn btn-eliminar"
                      onClick={() => {
                        // eliminarTodosLosLibros()
                        mostrarMensajeEliminarTodo()
                      }}
                    >
                      {descargados?.descargas?.boton_eliminar || 'Eliminar todas mis descargas'}
                      <i className="far fa-trash-alt ml-2"></i>
                    </button>
                  </div>
                )}
                <div className="col mb-4 mb-lg-0">
                  <p className="download-alert mt-3 onbackground-third--color">
                    {descargados?.descargas?.alerta || 'Estos libros solo se quedaran guardados en este dispositivo'}
                  </p>
                </div>

              </div>

              {isLoading ?
                <>
                  <div className="row mx-0 relative" style={{ minHeight: "800px" }}>
                    <div
                      className={`statistics-preloader`}
                    ></div>
                  </div>
                </>
                :
                <>
                  {librosCacheados.length > 0 ?
                    <>
                      <div className="d-flex flex-wrap justify-content-between mt-5 align-items-center">
                        <p tabIndex={0} className="focusable-secondary secondary-item mb-0 total-libros">
                          <span className="number">
                            {librosCacheados.length}/{librosMaxDescargados}
                          </span>{" "}
                          {descargados?.descargas?.libros_descargados || 'libros descargados'}
                        </p>
                        {navigator.onLine && librosCacheados.length < librosMaxDescargados && (
                          <Link to="/home">
                            <button className="btn btn-primary px-4 mt-3 mt-md-0 btn-primary--lower">
                              {descargados?.descargas?.ver_mas_libros || 'Ver más libros para descargar'} {" "}
                              <i className="fas fa-arrow-right"></i>
                            </button>
                          </Link>
                        )}
                      </div>
                      {librosCacheados.map((idLibro, i) => (
                        <LibroOffline
                          key={`item${idLibro}`}
                          idLibro={`${idLibro}`}
                          mostrarMensajeEliminarLibro={mostrarMensajeEliminarLibro}
                          eliminarLibro={eliminarLibro}
                        />
                      ))}
                    </>
                    :
                    <>
                      <>
                        <h2 className="text-center secondary-item my-5">
                          {descargados?.descargas?.no_has_descargado?.titulo || 'Parece que no has descargado nada aún.'}
                        </h2>
                        <div className="col-md-9 col-lg-8 mb-4 mx-auto d-flex flex-column align-items-center">
                          <div className="col-md-8 col-lg-6 mb-4 d-flex justify-content-center">
                            <img
                              src="/img/descarga_libros.png"
                              alt="Niña leyendo"
                              className="img-fluid mx-auto"
                            />
                          </div>
                          {(() => {
                            if (navigator.onLine) {
                              return (
                                <p className="onbackground-primary--color text-center px-0 col-sm-8 col-md-10 col-lg-8 col-xl-7 texto-sin-descargas">
                                  {descargados?.descargas?.no_has_descargado?.descripcion_uno || 'Los libros que descargues aparecen aquí.'}
                                </p>
                              )
                            } else {
                              return (
                                <p className="onbackground-primary--color text-center px-0 col-sm-11 col-md-12 col-lg-10 col-xl-9 texto-sin-descargas">
                                  {descargados?.descargas?.no_has_descargado?.descripcion_dos || 'Te invitamos a descargar alguno de nuestros libros cuando vuelvas a tener conexión a internet para poder disfrutar de nuestros contenidos de manera offline.'}
                                </p>
                              )
                            }
                          })()}
                        </div>
                      </>
                    </>
                  }
                </>
              }

            </div>
          </div>
        </div>
        {isReady &&
          <ModalConfirmacionEliminarDescargas
            message={message}
            description={description}
            funcionEliminarDescarga={funcionMicromodalEliminar === "todos" ? eliminarTodosLosLibros : eliminarLibro}
            textoBotones={descargados?.modal_descarga}
          />
        }
      </>
    </>
  );
};

export default Descargados;
