import React, { useEffect, useState, useRef } from "react";
import { GENERAL_URL_API } from "../components/Store";
import getAjax from "../js/utilities/getAjax";
import EntradasBlog from "./EntradasBlog";
import BlogAccordion from "./BlogAccordion";
import BlogTags from "./BlogTags";
import FormularioSuscripcion from "./FormularioSuscripción";
import getUrlParam from "../js/utilities/getUrl";
import { useHistory, useLocation } from "react-router-dom";
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";
import { Icon } from "@iconify/react";

/**
 * Este componente no recibe props. Las funciones principales son obtener la información del acordeon y actualizar el filtro de tag. Este renderiza la página principal de blog y hace uso de los componentes BlogTags, FormularioSuscripcion, BlogAccordion y EntradasBlog, a los cuales se les pasa los valores requeridos para su funcionamiento. 
 * @returns Blog
 */
const Blog = () => {
  const { isReady, textosSubSecciones: textosBlog } = useObtenerSubsecciones('blog');

  //Estados del componente
  const [isLoading, setIsLoading] = useState(true);
  const [blogInformation, setBlogInformation] = useState({});
  const { tags, entradas, acordeon } = blogInformation;
  const [searchItem, setSearchItem] = useState("");
  const [tagItem, setTagItem] = useState(
    getUrlParam("tags") !== undefined
      ? decodeURIComponent(getUrlParam("tags"))
      : ""
  );

  /**
   * Esta función ajusta el objeto que contiene la información del acordeon.
   * @param {Object} acordeonData - objeto que contiene arreglos de objetos con la información del acordeon por fechas
   * @returns {finalData : Object} 
   */
  const transformAcordeon = (acordeonData) => {
    const finalData = {};
    for (var i in acordeonData) {
      for (var a in acordeonData[i]) {
        finalData[`${a} ${i}`] = acordeonData[i][a];
      }
    }
    return finalData;
  };
  useEffect(() => {
    setIsLoading(true);
    let object;
    if (searchItem === "" && tagItem === "") {
      object = {};
    } else {
      object = searchItem !== "" ? { sch: searchItem } : { tags: tagItem };
    }
    getAjax(`${GENERAL_URL_API}/assets/listaBlogs`, object, (response) => {
      let information = JSON.parse(response);
      information.acordeon = transformAcordeon(information.acordeon);
      setBlogInformation(information);
      setIsLoading(false);
    });
  }, [searchItem, tagItem]);

  const mainSearchBlog = useRef();

  /**
   * Esta funcion actualiza el filtro por tag 
   * @param {string} tag -valor de filtro por tag
   */
  const searchTagFunction = (tag) => {
    setSearchItem("");
    setTagItem(tag);
  };

  const location = useLocation();
  const history = useHistory();

  return (
    <>
      {isLoading && (
        <>
          <div
            className="secondary-inverse--color d-flex justify-content-center align-items-center" style={{height:'400px'}}>
            <Icon icon="icomoon-free:spinner9" width={60} className={'spinner-rotation'} />
          </div>
        </>
      )}
      {!isLoading && (
        <>
          {isReady &&
            <div className="blog-contenedor">
              <div className="main-title blog-bg">
                <div className="container h-100">
                  <div className="row align-items-center h-100">
                    <div className="col-12 col-lg-6">
                      <h1 className="text-title onsecondary--color">Makeblog</h1>
                    </div>
                    <div className="col-12 col-lg-6 onsecondary--color">
                      <p>{textosBlog?.blog?.titulo || 'Actividades y reseñas de nuestros libros'}</p>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="blog-container mx-auto py-5 px-3 px-lg-5">
                <div className="col-lg-6 mx-auto mb-5">
                  <form
                    className="form-inline mb-0"
                    onSubmit={(e) => {
                      e.preventDefault();
                      setTagItem("");
                      setSearchItem(mainSearchBlog.current.value);
                      history.push(`${location.pathname}`);
                    }}
                  >
                    <input
                      className="search-input form-control surface--bg  onbackground-fourth--color form-control-sm mr-2 col-10 col-sm-11 col-lg-10 focusable-red"
                      type="text"
                      defaultValue={searchItem}
                      placeholder={textosBlog?.blog?.buscador || "Búsqueda por título, autor, editorial"}
                      aria-label="Search"
                      ref={mainSearchBlog}
                      name="sch"
                    />
                    <button className="btn p-0 focusable-secondary" type="submit" aria-label={'Buscar el texto introducido'}>
                      <i
                        className="search-icon fas fa-search onbackground-third--color"
                        aria-hidden="true"
                        id="search-icon"
                      ></i>
                    </button>
                  </form>
                </div>
                <h2 className="secondary-item text-center secondary-inverse--color">
                  {textosBlog?.blog?.subtitulo || 'Mostrando todas las actividades y reseñas'}
                </h2>
                <div className="row py-4">
                  <aside className="col-lg-3 px-lg-4 show-lg">
                    <button
                      className="btn btn-secondary my-4 d-block col-12 focusable-red"
                      onClick={() => {
                        setSearchItem("");
                        setTagItem("");
                      }}
                    >
                      {textosBlog?.botones?.ver_entradas || 'Ver todas las entradas'}
                    </button>
                    <BlogTags tags={tags} setTagItem={searchTagFunction} tituloTag={textosBlog?.tags?.titulo || 'Explora por tags'} />
                    <FormularioSuscripcion textosBlog={textosBlog} />
                    <BlogAccordion acordeon={acordeon} />
                  </aside>
                  <EntradasBlog entradas={entradas} textosBlog={textosBlog} />
                </div>
              </div>
            </div>
          }
        </>
      )}
    </>
  );
};

export default Blog;
