export const BIBLIOTECAS_DATA = [
  {
    paquete: [
      {
        carpeta: 'editoriales2',
        img: 'editorial-circulo-abierto.png',
      },
      {
        carpeta: 'instituciones',
        img: 'Breteau.png',
      },
      {
        carpeta: 'bibliotecas',
        img: 'Queens.png',
      },
      {
        carpeta: 'bibliotecas',
        img: 'Comfenalco Valle.png',
      },
      {
        carpeta: 'bibliotecas',
        img: 'Berkeley.png',
      },
      {
        carpeta: 'bibliotecas',
        img: 'RBPC.png',
      },
      {
        carpeta: 'bibliotecas',
        img: 'ministerio-de-educacion.png',
      },
      {
        carpeta: 'bibliotecas',
        img: 'comfamiliarHuila.png',
      },
    ]
  },
  {
    paquete: [
      {
        carpeta: 'bibliotecas',
        img: 'comfama.png',
      },
      {
        carpeta: 'bibliotecas',
        img: 'Fresno.png',
      },
      {
        carpeta: 'instituciones',
        img: 'Javeriana.png',
      },
      {
        carpeta: 'bibliotecas',
        img: 'casaliteraturaperuana.jpg',
      },
      {
        carpeta: 'bibliotecas',
        img: 'denver.jpg',
      },
      {
        carpeta: 'bibliotecas',
        img: 'austin.png',
      },
      {
        carpeta: 'bibliotecas',
        img: 'Banrep.png',
      },
      {
        carpeta: 'bibliotecas',
        img: 'Charlotte.png',
      },
    ]
  },
  {
    paquete: [
      {
        carpeta: 'bibliotecas',
        img: 'Bibliored.png',
      },
      {
        carpeta: 'bibliotecas',
        img: 'Boston.png',
      },
      {
        carpeta: 'bibliotecas',
        img: 'cajacopi.png',
      },
      {
        carpeta: 'bibliotecas',
        img: 'whittier.png',
      },
      {
        carpeta: 'bibliotecas',
        img: 'ComfenalcoAnt.png',
      },
      {
        carpeta: 'bibliotecas',
        img: 'ComfenalcoAtl.png',
      },
      {
        carpeta: 'bibliotecas',
        img: 'comfenalcoQuin.png',
      },
      {
        carpeta: 'bibliotecas',
        img: 'Wigberto.png',
      },
    ]
  },
  {
    paquete: [
      {
        carpeta: 'bibliotecas',
        img: 'Fundalectura.png',
      },
      {
        carpeta: 'bibliotecas',
        img: 'Miami.png',
      },
      {
        carpeta: 'bibliotecas',
        img: 'LosAngeles.png',
      },
      {
        carpeta: 'bibliotecas',
        img: 'pflugerville.png',
      },
      {
        carpeta: 'bibliotecas',
        img: 'piloto.png',
      },
      {
        carpeta: 'bibliotecas',
        img: 'LBPL.png',
      },
      {
        carpeta: 'bibliotecas',
        img: 'Comfamiliar Risaralda.png',
      },
      {
        carpeta: 'bibliotecas',
        img: 'epm.png',
      },
    ]
  },
  {
    paquete: [
      {
        carpeta: 'bibliotecas',
        img: 'comfacauca.png',
      },
      {
        carpeta: 'bibliotecas',
        img: 'w-j-niederkorn-library.png',
      },
      {
        carpeta: 'bibliotecas',
        img: 'colsubsidio.png',
      },
      {
        carpeta: 'bibliotecas',
        img: 'u-los-andes.png',
      },
      {
        carpeta: 'bibliotecas',
        img: 'secretaria-de-educacion.png',
      },
      {
        carpeta: 'bibliotecas',
        img: 'uptc.png',
      },
      {
        carpeta: 'bibliotecas',
        img: 'upb.png',
      },
      {
        carpeta: 'bibliotecas',
        img: 'compensar.png',
      }
    ]
  },
]