import React from "react";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { Titles } from "../../../components/Titles";
import { StaticBagde } from "../../components/badge";
/**
 * Componente de titulo principal para que es colegios
 * @returns {JSX.Element} Componente TitlePage.
 */
export const TitlePage = () => {
	const lang = useDefaultLangByCountry({ country: "us", defaultLang: "en" });
	const textoColegios = useTranslations({ section: "que_es_colegios", lang: lang });

	return (
		<>
			<StaticBagde />
			<Titles
				hexBackground="#82559E"
				color="onsecondary--color"
				title={textoColegios?.miga_de_pan?.colegios || "Colegios"}
			/>
		</>
	);
};
