import React, { createContext, useEffect, useReducer, useState } from "react";
import { useLocation } from "react-router-dom";
import { ModalNavegadorRecomendado } from "../components/modals/ModalNavegadorRecomendado";
import { useConexion } from "../hooks/useConexion";
import { PermisosAPI } from "../services/api/Permisos";
import { Plataforma } from "../services/plataforma";
import { CancelRequestController } from "../util/cancel-request-controller";
import { PWAconsole } from "../util/service-worker-messges";
import { useDevice } from "../hooks/use-device";
// Ayuda a identificar si las características de PWA y ServiceWorker son compatibles con el navegador.
const propiedadesPwa = {
	serviceWorkerSupported: "serviceWorker" in navigator,
	indexedDbSupported:
		window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB,
	cacheSupported: "caches" in window,
	// pushSupported: 'serviceWorker' in navigator && 'PushManager' in window,
	// bgSyncSupported: 'serviceWorker' in navigator && 'SyncManager' in window,
	// periodicSyncSupported: 'serviceWorker' in navigator ,
	// storageSupported: 'storage' in navigator && 'StorageManager' in window,
	// persistentStorageSupported: navigator.storage && navigator.storage.persist,
	// fileApiSupported: ('File' in window && 'FileReader' in window && 'FileList' in window && 'Blob' in window),
	// btSupported: 'bluetooth' in navigator,
	// mediaDevicesSupported: 'mediaDevices' in navigator,
	// geoSupported: "geolocation" in navigator,
};

//------------------------------------------------------

const estadoInicial = {
	serviceWorkerActivado: false,
	serviceWorkerInstalado: false,
	clienteHabilitado: false, // indica si el cliente tiene activado o no el PWA
	compatible: false, // Indica si el service worker y PWA son compatibles con el navegador actual
	instalable: false, // Indica si la aplicación PWA ya se puede descargar
	appInstalada: false,
	actualizacionInstalable: false, // indica si ya es posible aplicar la actualización encontrada
	promptEvent: null, // evento que se dispara para descargar la aplicación si es posible
	dispatch: ({ type, payload }) => {}, // actualizador del estado
};
//------------------------------------------------------

const reducer = (state, action) => {
	switch (action.type) {
		case "CHANGE_VALUE":
			return {
				...state,
				[action?.payload?.property]: action?.payload?.value,
			};
		default:
			return state;
	}
};
//------------------------------------------------------
/**
 * Se usa en toda la aplicación a través del hook de react 'useContext', permite modificar y obtener el estado actual  del service worker entre otras características que se mencionarán a continuación.
 */
export const ModoPWAContext = createContext(estadoInicial);
//------------------------------------------------------
/**
 * Es el proveedor del contexto 'ModoPWAContext' su función es muy importante, internamente se realiza muchas verificaciones en la cual se encuentra la verificación de compatibilidad, del estado del service worker, si el cliente tiene habilitada la funcionalidad de libros offline o no y también permite que cualquier componente que este dentro del proveedor tenga acceso al contexto y a sus cambios de estado, asi como tambíen podrá modificar su estado a través de la función 'dispatch'que ofrece el mismo contexto.
 */
export const PWAProvider = ({ children }) => {
	const conexion = useConexion();
	const [state, dispatch] = useReducer(reducer, estadoInicial);
	const location = useLocation();
	const [recommendedBrowserModalVisible, setRecommendedBrowserModalVisible] = useState(false);
	const device = useDevice();

	window.debugpwa && PWAconsole.log("Current state", state);

	const pwaSoportado = () => {
		const valoresPropiedadesPwa = Object.values(propiedadesPwa);
		const soportado = valoresPropiedadesPwa.every((value) => Boolean(value) === true);
		return soportado;
	};

	const detectarCompatibilidad = async () => {
		const esSoportado = pwaSoportado();
		dispatch({
			type: "CHANGE_VALUE",
			payload: {
				property: "compatible",
				value: esSoportado,
			},
		});
	};

	const estaInstalada = async () => {
		// For iOS
		if (window.navigator.standalone) return true;

		// For Android
		if (window.matchMedia("(display-mode: standalone)").matches) return true;

		// If neither is true, it's not installed
		return false;
	};

	const detectarModoPwa = async () => {
		const abortController = new CancelRequestController();
		const estaHabilitado = await PermisosAPI.pwaHabilitado({
			abortSignal: abortController.signal,
		}).catch((err) => {
			PWAconsole.error(err);
		});

		if (conexion?.online === false) {
			const estadoLocal = localStorage.getItem("pwaState")
				? JSON.parse(localStorage.getItem("pwaState"))
				: {};
			dispatch({
				type: "CHANGE_VALUE",
				payload: {
					property: "clienteHabilitado",
					value: estadoLocal.clienteHabilitado || false,
				},
			});
		} else {
			dispatch({
				type: "CHANGE_VALUE",
				payload: {
					property: "clienteHabilitado",
					value: estaHabilitado,
				},
			});
		}
	};

	const validacionesPersistentes = () => {
		detectarCompatibilidad();
		estaInstalada()
			.then((value) => {
				dispatch({
					type: "CHANGE_VALUE",
					payload: { property: "appInstalada", value: value },
				});
			})
			.catch((err) => PWAconsole.error("Error checking if app is installed ", err));
	};

	// decide si debe mostrar o no el modal de navegador recomendado, se muestra solo sl el navegador no es compatible con pwa.
	const handleRecommendedBrowserModal = () => {
		const localValue = localStorage.getItem("modal-navegador-recomendado");
		const show = localValue ? JSON.parse(localValue)?.mostrar : true;
		const navegadorCompatible = Plataforma.esRecomendada({
			navegador: Plataforma.obtenerNavegador(),
			os: Plataforma.obtenerOS(),
		});
		if (!device.isMobile()) {
			setRecommendedBrowserModalVisible(show && !navegadorCompatible);
		}
	};

	useEffect(() => {
		const handleInstallPrompt = (e) => {
			const userAgent = navigator.userAgent.toLowerCase();
			const isDesktop =
				/(win|mac|linux)/.test(userAgent) &&
				!/(android|webos|iphone|ipad|ipod|blackberry|windows phone)/.test(userAgent);

			if (isDesktop) {
				PWAconsole.info("App installation available");
				e.preventDefault();
				dispatch({
					type: "CHANGE_VALUE",
					payload: {
						property: "promptEvent",
						value: e,
					},
				});
				dispatch({
					type: "CHANGE_VALUE",
					payload: {
						property: "instalable",
						value: true,
					},
				});
			}
		};

		const globalInstallPrompt = window?.pwaInstallPrompt;

		if (globalInstallPrompt?.loaded === true) {
			handleInstallPrompt(globalInstallPrompt?.event);
		} else {
			window.addEventListener("beforeinstallprompt", handleInstallPrompt);
		}

		window.addEventListener("appinstalled", (event) => {
			PWAconsole.info("App installed!");
			dispatch({
				type: "CHANGE_VALUE",
				payload: { property: "instalable", value: false },
			});
		});
	}, []);

	useEffect(() => {
		if (conexion.online === false) {
			const estadoLocal = JSON.parse(localStorage.getItem("pwaState"));
			dispatch({
				type: "CHANGE_VALUE",
				payload: {
					property: "clienteHabilitado",
					value: estadoLocal?.clienteHabilitado || false,
				},
			});
		}
	}, [conexion.online]);

	useEffect(() => {
		const timer = setInterval(() => {
			validacionesPersistentes();
		}, 2000);
		return () => {
			clearInterval(timer);
		};
	}, []);

	useEffect(() => {
		detectarModoPwa();
	}, [location.pathname, conexion?.online]);

	useEffect(() => {
		if (conexion?.online) {
			localStorage.setItem(
				"pwaState",
				JSON.stringify({
					clienteHabilitado: state?.clienteHabilitado,
				})
			);
		}
	}, [conexion.online, state?.clienteHabilitado]);

	useEffect(() => {
		// mostrar información de la plataforma y si es compatible.
		const infoPlataforma = Plataforma.obtenerPlataforma();
		const navegadorCompatible = Plataforma.esRecomendada({
			navegador: Plataforma.obtenerNavegador(),
			os: Plataforma.obtenerOS(),
		});
		PWAconsole.info(
			"Current browser",
			infoPlataforma.navegador.nombre,
			infoPlataforma.navegador.version
		);
		PWAconsole.info("Current OS", infoPlataforma.os.nombre, infoPlataforma.os.version);
		PWAconsole.info("Is recommended platform?", navegadorCompatible);
		// si el navegador no cumple con el soporte necesario, se desplegará un modal recomendado navegadores.
	}, [
		state?.compatible,
		state?.clienteHabilitado,
		state?.instalable,
		recommendedBrowserModalVisible,
	]);

	useEffect(() => {
		handleRecommendedBrowserModal();
	}, []);

	return (
		<ModoPWAContext.Provider
			value={{
				serviceWorkerActivado: state?.serviceWorkerActivado,
				serviceWorkerInstalado: state?.serviceWorkerInstalado,
				clienteHabilitado: state?.clienteHabilitado,
				compatible: state?.compatible,
				instalable: state?.instalable,
				appInstalada: state?.appInstalada,
				actualizacionInstalable: state?.actualizacionInstalable,
				promptEvent: state?.promptEvent,
				dispatch,
			}}
		>
			<>
				<ModalNavegadorRecomendado
					visible={recommendedBrowserModalVisible}
					setVisible={setRecommendedBrowserModalVisible}
				/>

				{/* {
                    mobileAppMode===false&&
                    conexion.online &&
                    state?.clienteHabilitado &&
                    state?.compatible &&
                    <NotificacionEstadoPWAconfig
                        configurado={
                            state?.serviceWorkerActivado &&
                            state?.serviceWorkerInstalado 
                        }
                    />
                } */}
				{children}
			</>
		</ModoPWAContext.Provider>
	);
};
