
import axios from "axios"
import { GENERAL_URL_API } from "../../components/Store";


/**
 * Este servicio devuelve los libros con base a los filtros que se pasen como parametro.
 * @param {{abortSignal:AbortSignal, data:{}}} param0 
 * @returns {Promise<{
    "nombreContacto": String,
    "correoContacto": String,
    "telefonoContacto":String,
    "link1Mostrar": String,
    "link2Mostrar": String,
}>} retorna un objeto con los libros encontrados
 */
const obtenerLibros = async ({abortSignal=null, data})=>{
    try {
        axios.defaults.baseURL = GENERAL_URL_API;
        axios.defaults.withCredentials = true;

        const fullData = {
          sch:data?.sch || '',
          tema:data?.tema || '',
          edad:data?.edad || '',
          genero:data?.genero || '',
          nivel:data?.nivel || '',
          tiempo:data?.tiempo || '',
          actividad:data?.actividad || '',
          libroPremiado:data?.libroPremiado || '',
          libroAccesible:data?.libroAccesible || '',
          dificultadLectora:data?.dificultadLectora || '',
          editoriales:data?.editoriales || '',
          orderType:data?.orderType || '',
          orderBy:data?.orderBy || '',
          duracion:data?.duracion || '',
          maxDuracion:data?.maxDuracion || '',
          minDuracion:data?.minDuracion || '',
          ignoreStats:data?.ignoreStats || false
        }
        const params =Object.entries(fullData)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&')

        const response = await axios({
          method: 'GET',
          url: `assets/busqueda?${params}`,
          signal: abortSignal || null,
          headers:{'Content-Type':'application/x-www-form-urlencoded'},
        })
        const datos = response.data;
        if (datos === 'Error'){
          return new Error(datos);
        }
        return  datos?.libros || [];
    
      } catch (error) {
        return Promise.reject(error);
      }
    
}
/**
 * Este servicio devuelve un arreglo con los arreglos de generos, materias y tags.
 * @param {{abortSignal:AbortSignal, data:{}}} param0 
 * @returns {Promise<{
    "generos": Array<string>,
    "materias": Array<string>,
    "tags":Array<string>,
}>} retorna un objeto con los arreglos mencioandos.
 */
const obtenerBusquedaAvanzada = async ({abortSignal=null})=>{
    try {
        axios.defaults.baseURL = GENERAL_URL_API;
        axios.defaults.withCredentials = true;

        const response = await axios({
          method: 'POST',
          url: `assets/busquedaAvanzada`,
          signal: abortSignal || null,
          headers:{'Content-Type':'application/x-www-form-urlencoded'},
        })
        const datos = response.data;
        if (datos === 'Error'){
          return new Error(datos);
        }
        return  {
          generos:Array.isArray(datos?.Generos?.[0])?datos?.Generos?.[0]:[],
          materias:Array.isArray(datos?.Materias?.[0])?datos?.Materias?.[0]:[],
          tags:Array.isArray(datos?.Tags?.[0])?datos?.Tags?.[0]:[],
        };
    
      } catch (error) {
        return Promise.reject(error);
      }
    
}

export const BusquedaAPI = {
  obtenerLibros,
  obtenerBusquedaAvanzada
}