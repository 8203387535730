import React from "react";
import SkeletonHomeGeneral from './SkeletonHomeGeneral/index';
import SkeletonHomeIndividual from "./SkeletonHomeIndividual";

const SkeletonHome = (props) => {
    const { cantidadLibros, isLogged, sesionActiva} = props;


    if (sesionActiva === "SI" && cantidadLibros <= 40) {
        return  <SkeletonHomeIndividual/>
    } else {
        return <SkeletonHomeGeneral isLogged={isLogged}/>
    }
}

export default SkeletonHome;
