import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode } from "swiper";

import { Titles } from "../../components/Titles";
import { TabPanel } from "../../components/shared/tabpanel";
import { Subsections } from "./components/subsections";
import { MAKEMAKE_CLIENTS } from "./data/clients";

import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../hooks/use-translations";

/**
 * Este componente tab panel carga la tienda para instituciones
 */
export const PlanInstituciones = () => {
    const lang = useDefaultLangByCountry({ country: "us", defaultLang: "en" });
    const textos = useTranslations({ section: "tienda_makemake", lang: lang });

    const history = useHistory();
    const location = useLocation();
    const tabPanelIds = {
        cita: "/tienda/institucional/cita",
        demo: "/tienda/institucional/demo",
        brochure: "/tienda/institucional/brochure",
        contactanos: "/tienda/institucional/contactanos",
    };

    const clients = [...MAKEMAKE_CLIENTS.classics, ...MAKEMAKE_CLIENTS.planLector];

    /*define cual es el tab panel (tienda) que se debe mostrar en pantalla.*/
    const [currentTabPanel, setCurrentTabPanel] = useState(tabPanelIds.cita);

    useEffect(() => {
        /**
            dependiendo de la ruta del navegador si coincide con algún id 
            de los tab panel, entonces muestra el panel respectivo, 
            si no es así mostrará por defecto el de tienda institucional
         */
        if (Object.values(tabPanelIds).includes(location.pathname)) {
            setCurrentTabPanel(location.pathname);
        } else {
            setCurrentTabPanel(tabPanelIds.cita);
        }
    }, [location]);

    /**
     * cambia la ruta actual, debería llevar a la ruta de una tienda especifica.
     * Si la ruta coincide con el id de algún tab panel se mostrará ese panel automaticamente,
     * de no existir se mostrará por defecto el tab de tienda institucional.
     */
    const tabPanelNavigateTo = (path) => {
        history.push(path);
    };


    return (
        <>
            <Titles
                hexBackground="#FFD103"
                color="onprimary--color"
                title={textos?.tienda?.instituciones?.titulo || 'Contáctanos'}
                darkShapes
                description={textos?.tienda?.instituciones?.subtitulo || 'MakeMake ofrece suscripciones a planes de lectura para colegios y bibliotecas.'}
            />
            <div className="plan-instituciones">
                <div className="container instituciones-content">
                    <div>
                        <div>
                            <TabPanel.Box currentPanelId={currentTabPanel} className="d-lg-flex">
                                <TabPanel.Tabs className={"wrapper-tabs col-lg-4 col-xl-3 pt-lg-5 px-0"} >
                                    <TabPanel.Tab tabPanel={tabPanelIds.cita} className={"mt-5"}>
                                        <button
                                            className={`focusable-red tab ${currentTabPanel === tabPanelIds.cita ? "current" : ""}`}
                                            onClick={() => setCurrentTabPanel(tabPanelIds.cita)}
                                        >
                                            {textos?.tabpanel?.institucional1 || "Agenda una cita"}
                                            {
                                                currentTabPanel === tabPanelIds.cita &&
                                                <Icon icon="iconamoon:arrow-right-2-bold" width={25} className="d-none d-lg-block"/>
                                            }
                                        </button>
                                    </TabPanel.Tab>
                                    <TabPanel.Tab tabPanel={tabPanelIds.demo}>
                                        <button
                                            className={`focusable-red tab ${currentTabPanel === tabPanelIds.demo ? "current" : ""}`}
                                            onClick={() => tabPanelNavigateTo(tabPanelIds.demo)}
                                        >
                                            {textos?.tabpanel?.institucional2 || "Solicita un demo"}
                                            {
                                                currentTabPanel === tabPanelIds.demo &&
                                                <Icon icon="iconamoon:arrow-right-2-bold" width={25} className="d-none d-lg-block"/>
                                            }
                                        </button>
                                    </TabPanel.Tab>
                                    <TabPanel.Tab tabPanel={tabPanelIds.brochure}>
                                        <button
                                            className={`focusable-red tab ${currentTabPanel === tabPanelIds.brochure ? "current" : ""}`}
                                            onClick={() => tabPanelNavigateTo(tabPanelIds.brochure)}
                                        >
                                            {textos?.tabpanel?.institucional3 || "Descarga un brochure informativo"}
                                            {
                                                currentTabPanel === tabPanelIds.brochure &&
                                                <Icon icon="iconamoon:arrow-right-2-bold" width={25} className="d-none d-lg-block"/>
                                            }
                                        </button>
                                    </TabPanel.Tab>
                                    <TabPanel.Tab tabPanel={tabPanelIds.contactanos}>
                                        <button
                                            className={`focusable-red tab ${currentTabPanel === tabPanelIds.contactanos ? "current" : ""}`}
                                            onClick={() => tabPanelNavigateTo(tabPanelIds.contactanos)}
                                        >
                                            {textos?.tabpanel?.institucional4 || "Contáctanos"}
                                            {
                                                currentTabPanel === tabPanelIds.contactanos &&
                                                <Icon icon="iconamoon:arrow-right-2-bold" width={25} className="d-none d-lg-block" />
                                            }
                                        </button>
                                    </TabPanel.Tab>
                                </TabPanel.Tabs>

                                <TabPanel.Panel
                                    panelId={tabPanelIds.cita}
                                    className={'col-lg-8 col-xl-9 mt-5 mt-lg-0 instituciones-panel'}
                                >
                                    <Subsections.Cita />
                                </TabPanel.Panel>

                                <TabPanel.Panel
                                    panelId={tabPanelIds.demo}
                                    className={'col-lg-8 col-xl-9 mt-5 mt-lg-0 instituciones-panel'}
                                >
                                    <Subsections.Demo />
                                </TabPanel.Panel>

                                <TabPanel.Panel
                                    panelId={tabPanelIds.brochure}
                                    className={'col-lg-8 col-xl-9 mt-5 mt-lg-0 instituciones-panel'}
                                >
                                    <Subsections.Brochure />
                                </TabPanel.Panel>

                                <TabPanel.Panel
                                    panelId={tabPanelIds.contactanos}
                                    className={'col-lg-8 col-xl-9 mt-5 mt-lg-0 instituciones-panel'}
                                >
                                    <Subsections.Contactanos />
                                </TabPanel.Panel>
                            </TabPanel.Box>
                        </div>

                    </div>
                </div>

                <div className="instituciones-logos d-lg-flex align-items-center px-lg-5 py-5 py-lg-4">
                    <div className=" col-lg-6 col-xl-3">
                        <p className="font-weight-bold text-center text-lg-left">
                            {textos?.destacado?.descripcion || 'Las bibliotecas y colegios más destacados de América Latina y Estados Unidos prefieren a MakeMake'}
                        </p>
                    </div>
                    <div className=" col-lg-6 col-xl-9">
                        <Swiper
                            className="p-2"
                            style={{
                                overflowX: "hidden",
                                pointerEvents: "none",
                                mixBlendMode: "multiply"
                            }}
                            slidesPerView={"auto"}
                            autoplay={{
                                delay: 1,
                                disableOnInteraction: false,
                            }}
                            speed={2 * 1000}
                            loop={true}
                            freeMode={true}
                            direction="horizontal"
                            modules={[Autoplay, FreeMode]}
                        >
                            {clients.map((client, i) => {
                                return (
                                    <SwiperSlide
                                        key={i}
                                        className="d-flex justify-content-center align-items-center mx-2 p-0"
                                        style={{
                                            minWidth: 150,
                                            maxWidth: 150,
                                            height: "auto",
                                            pointerEvents: "none",
                                        }}
                                    >
                                        <img
                                            key={i}
                                            style={{
                                                minWidth: 150,
                                                maxWidth: 150,
                                                height: "auto",
                                                mixBlendMode: "multiply"
                                            }}
                                            src={client?.imgUrl}
                                            alt="client-image"
                                        />
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </div>
                </div>

            </div>
        </>
    )
}