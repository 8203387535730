export const MORE_PLANS = [
    {
        imgUrl: '/img/que-es/home/more-plans/casa.png',
        moreInfoUrl: '/tienda/individual',
        title: {
            default: 'Plan casa',
            translationPath: 'casa.titulo'
        },
        description: {
            default: 'Ahora podrás disfrutar de tus libros favoritos en tu casa',
            translationPath: 'casa.descripcion'
        },
        features: [
            {
                description: {
                    default: 'Personaliza tu colección',
                    translationPath: 'casa.caracteristica1'
                },
                tooltip: null
            },
            {
                description: {
                    default: 'Elige planes de hasta 30 libros',
                    translationPath: 'casa.caracteristica2'
                },
                tooltip: null
            },
            {
                description: {
                    default: 'Acceso único ilimitado',
                    translationPath: 'casa.caracteristica3'
                },
                tooltip: null
            },
           /*  {
                description: {
                    default: 'Plataforma accesible para personas con baja visiblidad',
                    translationPath: 'casa.caracteristica4'
                },
                tooltip: null
            },
            {
                description: {
                    default: 'Compatible con teléfonos, tabletas y computadores',
                    translationPath: 'casa.caracteristica5'
                },
                tooltip: null
            }, */
            // {
            //     description:{
            //         default:'Descargable para facilitar la lectura sin datos',
            //         translationPath:'casa.caracteristicas5'
            //     },
            //     tooltip:{
            //         default:'Tooltip',
            //         translationPath:''
            //     }
            // },
        ]

    },
    {
        imgUrl: '/img/que-es/home/more-plans/cursos.png',
        moreInfoUrl: '/tienda/cursos',
        title: {
            default: 'Academia MakeMake',
            translationPath: 'academia.titulo'
        },
        description: {
            default: 'Educación complementaria para cualquier persona en cualquier lugar',
            translationPath: 'academia.descripcion'
        },
        features: [
            {
                description: {
                    default: 'Cursos de diferentes temáticas para públicos diversos',
                    translationPath: 'academia.caracteristica1'
                },
                tooltip: null
            },
            {
                description: {
                    default: 'Videos y material visual de apoyo',
                    translationPath: 'academia.caracteristica2'
                },
                tooltip: null
            },
        ]

    }
];

