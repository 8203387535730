import React, { useRef } from "react";
import { useEffect } from "react";
import { useAdvancedSearch } from "../../hooks/advanced-search/use-advanced-search";
import { Header } from "./header";
import { Footer } from "./footer";
import { useHistory } from "react-router-dom";
import { AdvancedSearchItems, THEME_CARD_COLORS } from "./item-cards";
import { useAdvancedSearchContentData } from "../../hooks/advanced-search/use-advanced-search-content-data";
import { Icon } from "@iconify/react";
import { useContext } from "react";
import { store } from "../Store";
import useObtenerSubsecciones from "../../js/translations/useObtenerSubsecciones";
import _ from "lodash";
import { useUserBooks } from "../../hooks/useUserBooks";

/**
 * Componente principal del menú de búsqueda avanzada,
 * se encarga de renderizar el Header, Footer, y el contenido donde se muestran todos los items (generos, materias, niveles de lecturas, tags, entre otros.)
 * @returns {React.Component}
 */
export const AdvancedSearch = () => {
    const {textosSubSecciones:textos} = useObtenerSubsecciones('busqueda_avanzada');
    const advancedSearch = useAdvancedSearch();
    const visible = advancedSearch?.state?.visible;
    const data = useAdvancedSearchContentData();
    const history = useHistory();
    const ref = useRef(null);
    const globalState = useContext(store);
    const triggerButton = globalState?.state?.advancedSearch?.triggerButton;
    const userBooksData = useUserBooks();


    const hide = () => {
        advancedSearch.setVisible(false);
    }
    useEffect(() => {
        if (visible) {
            document.getElementsByTagName('html')[0].style.overflowY = "hidden";
        } else {
            document.getElementsByTagName('html')[0].style.overflowY = "auto";
        }
    }, [visible])

    const handleOutsideClick = (event) => {
        if(triggerButton?.current?.contains(event.target)) return;
        if (ref.current && !ref.current.contains(event.target)) {
          advancedSearch.setVisible(false);
        }
      };
    
      useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
    
        return () => {
          document.removeEventListener('click', handleOutsideClick);
        };
      }, [triggerButton]);


    return (<div ref={ref} onClick={(e) => e.target === e.currentTarget && hide()} className={`advanced-search ${visible ? 'visible' : 'hidden'}`}>
        {advancedSearch?.state?.loadingContent && userBooksData.loading ?
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                <Icon icon="icomoon-free:spinner9" width={60} className={'spinner-rotation secondary-inverse--color'} />
            </div>
            :
            <section className="menu container">
                <Header />
                <section className="content p-3 px-4">
                    <div className="row p-0 m-0 main-section">
                        <p className="section-title col-12 p-0 py-2">{_.get(textos, `content.seccion.titulo.mas_buscado`) || 'Lo más buscado'}</p>
                        <section className="row p-0 m-0 testing-border col-12">
                            <section className="row  p-0 m-0 testing-border col-12 col-sm-8">
                                <section className="testing-border col-12 p-0 m-0">
                                    <div className="row py-2 h-fit p-0 m-0">
                                        {data?.mostSearched?.filter(item => item?.type === 'genero' || item?.type === 'tema').map((genre, i) => {
                                            return <div 
                                            key={i}
                                            className="h-fit col-4 col-md-2 mr-3 pb-3">
                                                <AdvancedSearchItems.DefaultItemCard
                                                    key={i}
                                                    imgPath={genre?.imgPath}
                                                    name={_.get(textos, genre?.name?.translationPath) || genre?.name?.defaultValue}
                                                    onClick={() => {
                                                        history.push({ pathname: '/busqueda', search: `?${genre?.type}=${genre?.id}` })
                                                        advancedSearch.setVisible(false);
                                                    }} />
                                            </div>
                                        })}
                                    </div>
                                </section>
                                <section className="testing-border col-12 p-0 m-0">
                                    <div className="row testing-border col-md-12 p-0 m-0">
                                        <div className="col-12 my-2 col-md testing-border">
                                            <AdvancedSearchItems.CollectionCard
                                                backgroundColor={'#FFD103'}
                                                color={'#000'}
                                                name={_.get(textos, 'content.seccion.curadurias.colecciones') || 'Colecciones'}
                                                onClick={() => {
                                                    history.push('/curadurias/colecciones');
                                                    hide();
                                                }} />
                                        </div>
                                        <div className="col-12 my-2 col-sm testing-border">
                                            <AdvancedSearchItems.CollectionCard
                                                backgroundColor={'#4A90E2'}
                                                color={'#fff'}
                                                name={_.get(textos, 'content.seccion.curadurias.series') || 'Series'}
                                                onClick={() => {
                                                    history.push('/curadurias/series');
                                                    hide();
                                                }} />
                                        </div>
                                        <div className="col-12 my-2 col-sm testing-border">
                                            <AdvancedSearchItems.CollectionCard
                                                backgroundColor={'#EA5A18'}
                                                color={'#fff'}
                                                name={_.get(textos, 'content.seccion.curadurias.paises') || 'Países'}
                                                onClick={() => {
                                                    history.push('/curadurias/paises');
                                                    hide();
                                                }} />
                                        </div>
                                    </div>
                                </section>
                            </section>
                            <section className="row  p-0 m-0 testing-border col-12 col-sm-4">
                                <div className="testing-border col-12 p-0 m-0">
                                    <div className="row">
                                        {data?.readingLevel?.map((nivel, i) => {
                                            return <div 
                                            key={i}
                                            className="mb-2 col-12">
                                                <AdvancedSearchItems.ReadingLevelCard
                                                    key={i}
                                                    imgPath={nivel?.imgPath}
                                                    name={_.get(textos, nivel?.name?.translationPath) || nivel?.name?.defaultValue}
                                                    onClick={() => {
                                                        history.push({ pathname: '/busqueda', search: `?${nivel?.type}=${nivel?.id}` })
                                                        advancedSearch.setVisible(false);
                                                    }} />
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </section>
                        </section>
                        <p className="section-title col-12 p-0 py-2">{_.get(textos, `content.seccion.titulo.temas`) || 'Temas'}</p>
                        <section className="row p-0 m-0 testing-border col-12">
                            <div className="testing-border col-12 p-0 m-0">
                                <div className="d-flex flex-wrap justify-content-md-between">
                                    {
                                        data?.tags1?.map((theme, i) => {
                                            return <div key={i} className="">
                                                <AdvancedSearchItems.ThemeCard key={i}
                                                    name={theme}
                                                    hexColor={THEME_CARD_COLORS[i]}
                                                    shapeNumber={i+1}
                                                    onClick={() => {
                                                        history.push({ pathname: '/busqueda', search: `?sch=${theme}` })
                                                        advancedSearch.setVisible(false);
                                                    }} />
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                            <div className="testing-border col-12 p-0 m-0 py-3">
                                <div className="d-flex flex-wrap">
                                    {
                                        data?.tags2?.map((cloud, i) => {
                                            return <AdvancedSearchItems.CloudTag key={i}
                                                name={cloud} onClick={() => {
                                                    history.push({ pathname: '/busqueda', search: `?sch=${cloud}` })
                                                    advancedSearch.setVisible(false);
                                                }} />
                                        })
                                    }
                                </div>
                            </div>
                        </section>
                        <section className="row p-0 m-0 testing-border col-12">
                            <section className="row  p-0 m-0 testing-border col-12 col-sm-6">
                                <div className="row">
                                    {data?.genres?.map((genre, i) => {
                                        return <div key={i} className="h-fit col-4 col-sm-6 col-md-3 p-3">
                                            <AdvancedSearchItems.DefaultItemCard
                                                key={i}
                                                imgPath={genre?.imgPath}
                                                name={_.get(textos, genre?.name?.translationPath) || genre?.name?.defaultValue}
                                                onClick={() => {
                                                    history.push({ pathname: '/busqueda', search: `?${genre?.type}=${genre?.id}` })
                                                    advancedSearch.setVisible(false);
                                                }} />
                                        </div>
                                    })}
                                </div>
                            </section>
                            <section className="row pl-3 pl-0 pl-md-3  p-0 m-0 testing-border col-12 col-sm-6">
                                <div className="row p-0 m-0">
                                    {data?.subjects?.map((subject, i) => {
                                        return <div 
                                        key={i}
                                        className="col-12 col-md-6 p-1">
                                            <AdvancedSearchItems.SubjectCard
                                                key={i}
                                                imgPath={subject?.imgPath}
                                                name={_.get(textos, subject?.name?.translationPath) || subject?.name?.defaultValue}
                                                onClick={() => {
                                                    history.push({ pathname: '/busqueda', search: `?${subject?.type}=${subject?.id}` })
                                                    advancedSearch.setVisible(false);
                                                }} />
                                        </div>
                                    })}
                                </div>
                            </section>
                        </section>
                    </div>
                </section>
                <Footer />
            </section>
        }
    </div>)
}


