import React, { useContext } from "react"
import { useEffect, useState } from "react";
import { useRef } from "react";
import { useLocation } from "react-router-dom";
import { ModoPWAContext } from "../contexts/ModoPwaContext"
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";
import { Icon } from '@iconify/react'

/**
 * Componente de botón de actualización, este componente se llama y se muestra desde el componente de Application.
 * 
 * @param {Object} props - Propiedades del componente.
 * @param {Function} props.onClickButton - Función para manejar el clic del botón.
 * @returns {JSX.Element} Componente UpdateButton.
 */
export const UpdateButton = ({ onClickButton }) => {
    const { textosSubSecciones: textos } = useObtenerSubsecciones('home');
    const pwaContext = useContext(ModoPWAContext);
    const location = useLocation();
    const buttonRef = useRef(null);
    const [hideButton, setHideButton] = useState(false);

    useEffect(() => {
        if (pwaContext.actualizacionInstalable) {
            buttonRef.current && buttonRef.current.focus();
        }
    }, [pwaContext.actualizacionInstalable])

    return (
        <>
            {
                pwaContext.actualizacionInstalable &&
                !hideButton &&
                location.pathname.search("visor") === -1 &&
                <div>
                    <div role={'alert'} aria-live={"assertive"} aria-label={textos?.actualizacion?.texto || "Actualización disponible"}
                    className="d-flex justify-content-end primary--bg"></div>
                    <div
                        className="focusable-primary secondary-inverse--bordered-actualizar surface--bg onsecondary--color boton-actualizar px-4 pt-2 pb-2"
                    >
                        <div className="d-flex flex-column">
                            <div className="d-flex flex rows align-items-center justify-content-center" >
                                <Icon icon = "dashicons:update" className="secondary-inverse--color" fontSize = "30px"
                                 />
                                <p className="mb-0 secondary-inverse--color px-2">
                                    {textos?.actualizacion?.titulo || "Actualización disponible"}
                                </p>
                            </div>
                            <div className="d-flex flex rows align-items-center justify-content-around p-2" >
                                <button
                                    className='focusable-secondary green-inverse--bg boton-omitir'
                                    onClick={() => onClickButton && onClickButton()}>
                                        <p className="mb-0 onprimary-inverse--color">
                                        {textos?.actualizacion?.actualizar || "Actualizar"}
                                        </p>
                                </button>
                                <button
                                    className='focusable-secondary red-inverse--bg boton-omitir '
                                    onClick={() => setHideButton(true)}>
                                        <p className="mb-0 surface--color">
                                            {textos?.actualizacion?.omitir || "Omitir"}
                                        </p>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </>
    )
}