import React, { useState, useEffect, useContext } from "react";
import getAjax from '../js/utilities/getAjax';
import { GENERAL_URL_API, ASSETS_URL } from '../components/Store.js';
import LibroColeccion from "../coleccion/LibroColeccion";
import { cambiarSaltoLinea } from "../js/functions/cambiarSaltoLinea";
import { useParams } from "react-router-dom";
import SkeletonFichaEditorial from './../skeletons/SkeletonFichaEditorial';
import useObtenerTraducciones from "../js/translations/useObtenerTraducciones";
import { AccesibilidadContext } from "../contexts/AccesibilidadContext";

/**
 * Componente que muestra la ficha de una editorial, en donde se muestra información de la editorial y un listado de libros que están vinculados.
 *
 * @component
 * @example
 * return <FichaEditorial />
 */
const FichaEditorial = () => {
  const [isLoading, setIsloading] = useState(true);
  const [datosEditorial, setDatosEditorial] = useState('');
  const [currenTab, setCurrentTab] = useState('resena')
  const accesibilidadState = useContext(AccesibilidadContext);

  const { nombreEditorial } = useParams();

  const { isReady, textosSeccion: textoEditorial } = useObtenerTraducciones('ficha_editorial');

  useEffect(() => {
    /*let nombreColeccion = id.split('-').join(' ');
    let capitalizeNombre = nombreColeccion.charAt(0).toUpperCase() + nombreColeccion.slice(1);*/
    getAjax(`${GENERAL_URL_API}/assets/datosEditorial`, { nombreEditorial: nombreEditorial }, response => {
      setDatosEditorial(JSON.parse(response));
      setIsloading(false);
    })

  }, [nombreEditorial])

  const librosEditorial = () => {
    if (datosEditorial.libros !== undefined) {
      var libro = []
      for (var i = 0; i < datosEditorial.libros.length; i++) {
        var num = i;
        libro.push(
          <LibroColeccion
            clase={'col-6 col-sm-4 col-md-3 col-lg-2 mb-4'}
            item={datosEditorial.libros[num]}
            key={datosEditorial.libros[i].idLibro}
          />
        )
      }
      return libro
    }
  }

  return (
    <>
      {(() => {
        if (isLoading) {
          return (
            <SkeletonFichaEditorial />
          )
        } else {
          return (
            <>
              {isReady &&
                <div className="main-container">
                  <div className={`${accesibilidadState.temaActual === 'alto-contraste'&&'background--bg '} coleccion-container`} style={{ backgroundColor: accesibilidadState.temaActual === 'clasico'&&datosEditorial.color1 }}>
                    <div className={`${accesibilidadState.temaActual === 'alto-contraste'&&'background--bg '} coleccion-background coleccion-editorial`} style={{ background: `${accesibilidadState.temaActual === 'clasico'&&datosEditorial.color2} url('/img/background.png') 50%/cover fixed repeat` }}></div>
                    <div className="container pb-lg-5">
                      <div className="row pt-5 mb-5">
                        <div className="col-lg-6 mb-5 mb-lg-0">
                          <h1 tabIndex={0} className={`focusable-secondary ${accesibilidadState.temaActual === 'alto-contraste'&&'onbackground-primary--color'}`} style={{ color: accesibilidadState.temaActual === 'clasico'&&datosEditorial.colorLetra }}>{datosEditorial.nombreEditorial}</h1>
                          <div className="row justify-content-end mt-4 mx-0">
                            <a className="focusable-secondary" href={datosEditorial.linkEditorial} target="_blank">
                              <img alt={`Logo de la editorial '${datosEditorial.nombreEditorial}'`} className="img-fluid" src={`${ASSETS_URL}${datosEditorial.logo}`} />
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6 pl-lg-5 ">
                          <ul role={"tablist"} className="row nav-menu p-0 m-0 menu-tabs nav-menu-noresponsive">
                            <li role={"tab"}  >
                              <button className={`w-100 h-100 focusable-secondary px-4 py-2 mr-3 ${currenTab === 'resena' ? 'nav-menu--active' : ''}`} data-target="editorial-resena" onClick={() => setCurrentTab('resena')}>
                              {textoEditorial?.resena ||'Reseña'}
                              </button>
                            </li>
                            
                            {datosEditorial.premios !== '' && datosEditorial.premios !== null &&
                              <li role={"tab"}>
                                <button className={`w-100 h-100 focusable-secondary px-4 py-2 ${currenTab === 'premios' ? 'nav-menu--active' : ''}`} data-target="editorial-premios" onClick={() => setCurrentTab('premios')}>

                                  {textoEditorial?.premios || 'Premios'}
                                </button>
                              </li>
                            }
                          </ul>
                          <div className="fichalibro-data fichalibro-data--text fichaeditorial p-4 p-sm-3 p-lg-5">

                            {(() => {
                              if (currenTab === 'resena') {
                                return (
                                  <div className="data--tab tab-active pr-4" id="editorial-resena">
                                    <div className="container-tabs">
                                      {(() => {
                                        if (datosEditorial.descripcion !== null) {
                                          return (
                                            <>
                                              {cambiarSaltoLinea("\n", datosEditorial.descripcion)}
                                            </>
                                          )
                                        } else {
                                          return ""
                                        }
                                      })()}
                                      <div className="row justify-content-end mt-4 mx-0">
                                        <a href={datosEditorial.linkEditorial} rel="noopener noreferrer" target="_blank"><button type="submit" className="focusable-secondary web-page btn btn-light px-4 mt-3">{textoEditorial?.web || 'Página web'} <i className="fas fa-external-link-alt ml-2"></i></button></a>
                                      </div>
                                    </div>
                                  </div>
                                )
                              } else {
                                return (
                                  <div className="data--tab tab-active pr-4" id="editorial-premios">
                                    <div className="container-tabs">
                                      {cambiarSaltoLinea("\n", datosEditorial.premios)}
                                    </div>
                                  </div>
                                )
                              }
                            })()}

                          </div>
                        </div>
                      </div>
                      <div className="mt-5 pb-5 mt-lg-0 align-items-end">
                        <u tabIndex={0} role={"list"} aria-label={'Libros'} className="focusable-secondary row">
                          {librosEditorial()}
                        </u>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </>
          )
        }
      })()}
    </>
  )
}

export default FichaEditorial;