import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const SkeletonHeaderSinLogin = () => {
  return (
    <>
      <SkeletonTheme color="#d8d3d3" highlightColor="#e8e8e8">
      <div className="header-skeleton skeleton-background px-2">
        <div className="row align-items-center justify-content-between pb-1">
          <div className="col-6 d-lg-none">
            <Skeleton height={40} width={40} />
          </div>
          <div className={`col-lg-5 d-none d-lg-flex align-items-center`}>
            <Skeleton  count={2} height={40} width={130} className="mx-1"/>
          </div>
          <div className="col-6 col-lg-7 h-100 d-flex justify-content-end justify-content-lg-between align-items-center">
            <div className="header-logo">
              <Skeleton className="" height={40} width={"100%"} />
            </div>
            <div className="d-none d-lg-flex mx-3 mx-lg-0">
            <Skeleton count={3} height={40} width={130} className="mx-1"/>
            </div>
          </div>
        </div>
      </div>
    </SkeletonTheme>
    </>
  )
}

export default SkeletonHeaderSinLogin;