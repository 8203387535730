import React from "react";
import useObtenerSubsecciones from "../../js/translations/useObtenerSubsecciones";
import { Img } from "../../components/shared/img";

/**
 * Componente que muestra las características comerciales de MakeMake.
 * @returns {JSX.Element} Elemento JSX.
 */
export const MakeMakeFeatures = () => {
  const { textosSubSecciones: textos } = useObtenerSubsecciones('home');
  const featuresText = textos?.sin_login?.info_home || {};
  return (
    <>
      <div
        role={"group"} tabIndex={0} aria-label={'MakeMake Classics Info'}
        className="focusable-secondary d-flex col-md-10 mx-auto  mt-5 mt-lg-0 pb-3 py-lg-5 flex-wrap contenedor-caracteristicas justify-content-around">
        <div className="col-5 col-lg-3 d-flex flex-column mb-4 mb-lg-0 px-0">
          <div className="col-md-6 mx-auto mb-3 d-flex justify-content-center align-items-center">
            <Img
              tabIndex={0}
              alt={`${featuresText?.editoriales?.parte_uno} ${featuresText?.editoriales?.parte_dos}`}
              src="./img/home/editoriales-2.png"
              className="focusable-secondary w-100 h-100"
              width={80}
              height={80}
            />
          </div>
          <div>
            <p className="text-center text-break">
              <b className="font-weight-bold">+{featuresText?.editoriales?.parte_uno}</b>
              <br />
              {featuresText?.editoriales?.parte_dos}
            </p>
          </div>
        </div>

        <div className="col-5 col-lg-3 d-flex flex-column mb-4 mb-lg-0 px-0">
          <div className="col-md-6 mx-auto mb-3 d-flex justify-content-center align-items-center">
            <Img
              tabIndex={0}
              alt={`${featuresText?.interactivos?.parte_uno} ${featuresText?.interactivos?.parte_dos} ${featuresText?.interactivos?.parte_tres}`}
              src="./img/home/interactivos-2.png"
              className="focusable-secondary img-fluid"
              width={80}
              height={80}
            />
          </div>
          <div>
            <p className="text-center text-break">
              {featuresText?.interactivos?.parte_uno} <b className="font-weight-bold">{featuresText?.interactivos?.parte_dos}</b>
              <br />
              {featuresText?.interactivos?.parte_tres}
            </p>
          </div>
        </div>

        <div className="col-5 col-lg-3 d-flex flex-column mb-4 mb-lg-0 px-0">
          <div className="col-md-6 mx-auto mb-3 d-flex justify-content-center align-items-center">
            <Img
              tabIndex={0}
              alt={`${featuresText?.diversidad?.parte_uno} ${featuresText?.diversidad?.parte_dos} ${featuresText?.diversidad?.parte_tres}`}
              src='./img/home/diversidad-2.png'
              className="focusable-secondary img-fluid"
              width={80}
              height={80}
            />
          </div>
          <div>
            <p className="text-center text-break">
              <b className="font-weight-bold">{featuresText?.diversidad?.parte_uno}</b> {featuresText?.diversidad?.parte_dos}
              <br />
              {featuresText?.diversidad?.parte_tres}
            </p>
          </div>
        </div>

        <div className="col-5 col-lg-3 d-flex flex-column mb-4 mb-lg-0 px-0">
          <div className="col-md-6 mx-auto mb-3 d-flex justify-content-center align-items-center">
            <Img
              tabIndex={0}
              alt={`${featuresText?.acompanamiento?.parte_uno} ${featuresText?.acompanamiento?.parte_dos}`}
              src="./img/home/guias-2.png"
              className="focusable-secondary img-fluid"
              width={80}
              height={80}
            />
          </div>
          <div>
            <p className="text-center text-break">

              <b className="font-weight-bold">{featuresText?.acompanamiento?.parte_uno}</b>
              <br />
              {featuresText?.acompanamiento?.parte_dos}
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
