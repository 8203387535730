import React from "react";
import useIsMobile from "../../js/utilities/useIsMobile";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useRef } from "react";
import { useAdvancedSearch } from "../../hooks/advanced-search/use-advanced-search";
import { useEffect } from "react";
import { useContext } from "react";
import { store } from "../Store";
import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../hooks/use-translations";

/**
 * Componente que renderiza los botones de explorar y buscar. Estos se utilizan en el header general.
 * @param {Function} onClickExplore
 * @param {Function} onClickSearch
 * @param {string} colorLetra - Indica el color de letra y background de la institución
 * @returns ExplorarYbuscar
 */
export const ExplorarYbuscar = ({ onClickExplore, onClickSearch, colorLetra }) => {
	const isMobile = useIsMobile();
	const advancedSearchButtonRef = useRef(null);
	const location = useLocation();
	const isHome = location.pathname === "/home";
	const lang = useDefaultLangByCountry({ country: "us", defaultLang: "en" });
	const textosInterfaz = useTranslations({ section: "header", lang: lang, array: true });
	const { explorar, buscar } = textosInterfaz || {};
	const advancedSearch = useAdvancedSearch();
	const globalState = useContext(store);
	const isIndividualUser =
		globalState?.state?.cantidadLibros < 40 && globalState?.state?.cantidadLibros >= 0;
	const history = useHistory();

	useEffect(() => {
		if (advancedSearchButtonRef.current) {
			globalState.dispatch({
				type: "CHANGE",
				path: "advancedSearch.triggerButton",
				value: advancedSearchButtonRef,
			});
		}
	}, [advancedSearchButtonRef]);

	return (
		<div
			className={`d-lg-flex mx-3 mx-lg-0 align-items-center width-explore ${
				!isMobile && "h-100"
			}`}
		>
			<Link
				to={"/home"}
				onClick={() => onClickExplore && onClickExplore()}
				className={`focusable-secondary ${
					isMobile ? "btn-responsive" : `btn-explorar ${isHome && "btn-explorar-activo"}`
				}`}
				style={{ color: colorLetra, borderColor: colorLetra }}
			>
				<svg
					version="1.1"
					id="Capa_1"
					xmlns="http://www.w3.org/2000/svg"
					xlink="http://www.w3.org/1999/xlink"
					x="0px"
					y="0px"
					viewBox="0 0 31 29.8"
					style={{ enable: "new 0 0 31 29.8" }}
					space="preserve"
					className="icono-home"
				>
					<path
						style={{ fill: colorLetra }}
						className="st0"
						d="M16,5.3c-0.3-0.2-0.7-0.2-0.9,0L0.7,13c-0.9,0.5-0.6,1.9,0.5,1.9h5.1c0.6,0,1.1,0.5,1,1.2L5.4,28.3
	c-0.1,0.6,0.4,1.2,1,1.2h18.2c0.6,0,1.1-0.6,1-1.2L23,16.2c-0.1-0.6,0.3-1.2,1-1.2h5.9c1,0,1.4-1.4,0.5-1.9L16,5.3z M12.5,20.2
	c0-0.6,0.4-1,1-1h4c0.6,0,1,0.4,1,1v9h-6V20.2z"
					/>
					<path
						style={{ fill: colorLetra }}
						className="st1"
						d="M28.7,3.5l-5.3-2.4c-0.1-0.1-0.3,0-0.3,0.2V6l5.6-2.1C28.8,3.8,28.8,3.6,28.7,3.5z"
					/>
					<path
						style={{ strokeWidth: colorLetra, fill: colorLetra }}
						className="st2"
						d="M23.1,11V6 M23.1,6V1.3c0-0.1,0.2-0.2,0.3-0.2l5.3,2.4c0.2,0.1,0.2,0.3,0,0.4L23.1,6z"
					/>
				</svg>
				{explorar}
			</Link>
			{!isIndividualUser && navigator.onLine && (
				<button
					ref={advancedSearchButtonRef}
					className={`focusable-secondary ${
						isMobile ? "btn-responsive" : "btn-header-gris"
					}`}
					onClick={() => {
						history.push("/busqueda");
						onClickSearch && onClickSearch();
						advancedSearch.setVisible(!advancedSearch?.state?.visible);
					}}
				>
					<i className="search-icon fas fa-search mr-2" aria-hidden="true"></i>
					{buscar ? buscar : "Buscar"}
				</button>
			)}
		</div>
	);
};
