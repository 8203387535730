import React, { useContext } from "react"
import { AccesibilidadContext, TemasDisponibles } from "../../contexts/AccesibilidadContext"
import { Img } from "../shared/img"
import PropTypes from 'prop-types';

/**
 * Componente generico para mostrar items del menú de búsqueda avanzada el cual muestra una imagen, el titulo, y recibe un parametro onClick.
 * @returns {React.Component}
 */
 const DefaultItemCard = ({ imgPath, name, onClick }) => {
    return <button className="focusable-secondary default-item-card d-flex flex-column align-items-center" onClick={() => onClick && onClick()}>
        <Img className="item-card-image" src={imgPath} alt={`genre-${name}`} />
        <p>{name}</p>
    </button>
}

DefaultItemCard.propTypes = {
    /**
     * Ruta de la imagen que se va a renderizar.
     */
    imgPath:PropTypes.string,
    /**
     * Nombre del item (se mostrará como título del item)
     */
    name:PropTypes.string,
    /**
     * Función que se ejecuta cuando se hace click en el componente.
     */
    onClick:PropTypes.func,
}

/**
 * Componente  para mostrar niveles de lectura  en el menú de búsqueda avanzada el cual muestra una imagen, el titulo, y recibe un parametro onClick.
 * @returns {React.Component}
 */
const ReadingLevelCard = ({ imgPath, name, onClick }) => {
    return <button className="focusable-secondary reading-level-card d-flex" onClick={() => onClick && onClick()}>
        <Img className="item-card-image h-100 self-start" src={imgPath} alt={`genre-${name}`} />
        <p className="p-2 name">{name}</p>
    </button>
}
ReadingLevelCard.propTypes = {
    /**
     * Ruta de la imagen que se va a renderizar.
     */
    imgPath:PropTypes.string,
    /**
     * Nombre del item (se mostrará como título del item)
     */
    name:PropTypes.string,
    /**
     * Función que se ejecuta cuando se hace click en el componente.
     */
    onClick:PropTypes.func,
}


/**
 * Componente  para mostrar materias en el menú de búsqueda avanzada el cual muestra una imagen, el titulo, y recibe un parametro onClick.
 * @returns {React.Component}
 */
const SubjectCard = ({ imgPath, name, onClick }) => {
    return <button className="focusable-secondary subject-card d-flex justify-content-between align-items-center p-2" onClick={() => onClick && onClick()}>
        <Img className="item-card-image self-start" src={imgPath} alt={`subject-${name}`} />
        <p className="flex-grow-0 px-1 p-2 name text-left">{name}</p>
    </button>
}

SubjectCard.propTypes = {
    /**
     * Ruta de la imagen que se va a renderizar.
     */
    imgPath:PropTypes.string,
    /**
     * Nombre del item (se mostrará como título del item)
     */
    name:PropTypes.string,
    /**
     * Función que se ejecuta cuando se hace click en el componente.
     */
    onClick:PropTypes.func,
}

/**
 * Son los colores disponibles que se usan en el componente de ThemeCard
 */
export const THEME_CARD_COLORS = [
    '#E373FF',
    '#B2AE1B',
    '#4A90E2',
    '#EA5A18',
    '#8EC045',
]
/**
 * Componente  para mostrar los tags de nivel 1 en el menú de búsqueda avanzada
 * @returns {React.Component}
 */
const ThemeCard = ({ name, onClick, hexColor, shapeNumber }) => {
    const acc = useContext(AccesibilidadContext);
    hexColor = hexColor ? hexColor : 'var(--secondary-color)'
    const background = acc?.temaActual === TemasDisponibles.clasico?.id ? hexColor : 'var(--background-color)';
    return <button
        className="focusable-secondary theme-card d-flex justify-content-center align-items-center p-2 m-2"
        style={{ backgroundColor: background }}
        onClick={() => onClick && onClick()}>
        <p className="p-2 name">
            {
                acc.temaActual === TemasDisponibles.clasico?.id &&
                <>
                    <img src={`/img/theme-card-shape-${shapeNumber >=1&&shapeNumber<=5?shapeNumber:1}.png`} className="shape" alt="shape"></img>
                    <img src="/img/theme-card-particles.png" className="shape" alt="shape"></img>
                </>

            }
            <span className="name">{name}</span>
        </p>
    </button>
}

ThemeCard.propTypes = {
    /**
     * color que tendrá el fondo (debajo del shape)
     */
    hexColor:PropTypes.string,
      /**
     * Número del shape que se quiere mostrar, del 1 al 5
     */
    shapeNumber:PropTypes.number,
    /**
     * Nombre del item (se mostrará como título del item)
     */
    name:PropTypes.string,
    /**
     * Función que se ejecuta cuando se hace click en el componente.
     */
    onClick:PropTypes.func,
}

/**
 * Componente  para mostrar los tags de nivel 2 en el menú de búsqueda avanzada
 * @returns {React.Component}
 */
const CloudTag = ({ name, onClick }) => {
    return <button className="focusable-secondary cloud-tag m-2 p-3" onClick={() => onClick && onClick()}>
        {name}
    </button>
}

CloudTag.propTypes = {
    /**
     * Nombre del item (se mostrará como título del item)
     */
    name:PropTypes.string,
    /**
     * Función que se ejecuta cuando se hace click en el componente.
     */
    onClick:PropTypes.func,
}

/**
 * Componente  para mostrar curadurias (colecciones, series y países) menú de búsqueda avanzada
 * @returns {React.Component}
 */
const CollectionCard = ({ backgroundColor, color, name, onClick }) => {
    const acc = useContext(AccesibilidadContext);
    backgroundColor = acc?.temaActual === TemasDisponibles?.clasico?.id?backgroundColor:'var(--background-color)';
    color = acc?.temaActual === TemasDisponibles?.clasico?.id?color:'var(--onbackground-primary)';

    return <button 
    className="focusable-secondary collection-card d-flex justify-content-center align-items-center p-2" 
    style={{backgroundColor, color}}
    onClick={() => onClick && onClick()}>
        {name}
    </button>
}
CollectionCard.propTypes = {
    /**
     * Color que tendrá el fondo
     */
    backgroundColor:PropTypes.string,
      /**
     * Color de la fuente
     */
    color:PropTypes.string,
    /**
     * Nombre del item (se mostrará como título del item)
     */
    name:PropTypes.string,
    /**
     * Función que se ejecuta cuando se hace click en el componente.
     */
    onClick:PropTypes.func,
}

export const AdvancedSearchItems={
    CloudTag,
    DefaultItemCard,
    ReadingLevelCard,
    ThemeCard,
    SubjectCard,
    CollectionCard
}


