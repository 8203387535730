import React, { useContext, useEffect, useState, useRef } from "react";
import { store } from "../Store";
import MicroModal from "micromodal";
import { Icon } from "@iconify/react";
import { AccesibilidadContext } from "../../contexts/AccesibilidadContext";
import useObtenerSubsecciones from "../../js/translations/useObtenerSubsecciones";
import { DatosPanelAPI } from "../../services/api/DatosPanel";
import { CancelRequestController } from "../../util/cancel-request-controller";

/**
Componente ModalCompartir - Modal para compartir el link de un libro.
@param {object} props - Propiedades del componente
@param {string} props.idModal - ID del modal
@param {object} props.libro - Información del libro
@returns {JSX.Element} Elemento JSX que representa el componente ModalCompartir
**/
export const ModalCompartir = (props) => {
    const { isReady, textosSubSecciones: traducciones } = useObtenerSubsecciones('ficha_libro');
    const { idModal, libro } = props;
    const [copiado, setCopiado] = useState(false);
    const [link, setLink] = useState(``);
    let temporizadorTextoCopiado = null;
    const globalState = useContext(store);
    const { dispatch, state } = globalState;
    const [cargando, setCargando] = useState(true);

    const refCopiarPegar = useRef(null);
    const accebilidadState = useContext(AccesibilidadContext);
    const [esTemaClasico, setEsTemaClasico] = useState(true);

    const copiarLink = () => {
        setCopiado(true);
        if (Boolean(temporizadorTextoCopiado)) {
            clearTimeout(temporizadorTextoCopiado);
            temporizadorTextoCopiado = null;
        }
        temporizadorTextoCopiado = setTimeout(() => {
            setCopiado(false);
        }, 3000);
        navigator.clipboard.writeText(link);
    }

    useEffect(() => {
        if (refCopiarPegar.current) {
            refCopiarPegar.current.focus();
        }
    }, [])

    useEffect(() => {
        let abortController = new CancelRequestController();
        const generarLink = async () => {
            setCargando(false);
            const infoInvitacion = {
                libroLink:encodeURI(libro?.link),
                libro: encodeURIComponent(libro?.titulo || libro?.title || libro?.name || libro?.nombre),
                institucion:encodeURIComponent(state?.datosHeader?.institucion),
                linkPlataforma:encodeURI(state?.linkAcceso || 'https://makemake.com.co')
            }
            setLink(`${window?.location?.origin}${infoInvitacion.libroLink}?invitacionLectura=true&nombreLibro=${infoInvitacion.libro}&institucion=${infoInvitacion.institucion}&linkPlataforma=${infoInvitacion.linkPlataforma}`)
        }
        generarLink();

        return () => {
            abortController.abort();
        }
    }, [libro, state?.datosHeader?.institucion, state?. state?.linkAcceso])


    useEffect(() => {
        let tema = accebilidadState?.temaActual !== 'clasico' ? false : true;
        setEsTemaClasico(tema);
    }, [accebilidadState])

    const cerrarModal = () => {
        MicroModal.close(idModal, {
            awaitCloseAnimation: true
        });
    }

    return (
        <>
            <div className="micromodal-slide" id={idModal || ''} aria-hidden="true">
                <div className="modal__overlay" data-micromodal-close>
                    <div
                        className="modal__container background--bg"
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby={'modal-titulo-compartir'}
                        aria-describedby={'modal-dialogo-compartir'}
                    >
                        <div className="d-flex flex-row-reverse">
                            <button
                                aria-label="Cerrar ventana de dialogo"
                                className="close-button onbackground-primary--color focusable-red"
                                data-micromodal-close
                                onClick={() => cerrarModal()}
                            >
                                <Icon icon={'mdi:close-thick'} width={35} />
                            </button>
                        </div>

                        <div className="d-flex flex-column justify-content-center align-items-center">
                            {isReady === false || cargando ?
                                <>
                                    <Icon icon="icomoon-free:spinner9" width={50} className={'secondary-inverse--color icon spinner-rotation'} />
                                </>
                                :
                                <>
                                    <h2
                                        id="modal-titulo-compartir"
                                        className="font-quicksand font-weight-bold onbackground-third--color"
                                    >
                                        {`${traducciones?.modal?.compartir?.titulo || 'Copia y pega'}`}
                                    </h2>
                                    <p
                                        id="modal-dialogo-compartir"
                                        className="font-quicksand m-2 onbackground-third--color"
                                    >
                                        {`${traducciones?.modal?.compartir?.descripcion || 'Para compartir este libro con tu comunidad'}`}
                                    </p>
                                    <div className="share-box onbackground-primary--color mt-2">
                                        <input
                                            className={`share-input background--bg  onbackground-primary--color ${!esTemaClasico && 'onbackground-primary--bordered'}`}
                                            type="text"
                                            contentEditable={false}
                                            value={link} disabled />
                                        <button
                                            ref={refCopiarPegar}
                                            aria-label="Copiar link"
                                            className="copy-button  focusable-red"
                                            onClick={() => copiarLink()}
                                        >
                                            <Icon icon={'clarity:copy-line'} width={30} />
                                        </button>

                                    </div>
                                    <p
                                        aria-live="assertive"
                                        className={`font-quicksand m-2 font-weight-bold ${esTemaClasico ? 'green--color' : 'ongreen--color'}`}
                                    >
                                        {copiado ? traducciones?.modal?.compartir?.texto_copiado || '¡Link copiado!' : ' '}
                                    </p>
                                </>
                            }


                        </div>
                    </div>
                </div>
            </div>
        </>)
}