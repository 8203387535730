import { useEffect, useState } from "react";
import { ADVANCED_SEARCH_ITEMS_DATA } from "../../components/advanced-search/data/items-data";
import { CancelRequestController } from "../../util/cancel-request-controller";
import { BusquedaAPI } from "../../services/api/Busqueda";
import _ from "lodash";
import { useContext } from "react";
import { store } from "../../components/Store";

/**
Este hook devuelve un objeto con arreglos que contienen la información que se muestra en el menú de búsqueda avanzada. * 
Cada objeto perteneciente a los arreglos trae la siguiente estructura como mínimo:
  @example 
  {
        id: string,
        type:string,
        name: {
            defaultValue: string,
            translationPath:string
        },
        imgPath:string,
    }

 * @returns {{
 mostSearched: object[];
    genres: object[];
    subjects: object[];
    readingLevel: object[];
    tags1: object[];
    tags2: object[];
  }}
 */
export const useAdvancedSearchContentData = () => {
    const [mostSearched, setMostSearched] = useState([]);
    const [genres, setGenres] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [readingLevel, setReadingLevel] = useState([]);
    const [loading, setLoading] = useState(true);
    const [tags1, setTags1] = useState(['Animales en extinción', 'Exploración', 'Ecologia', 'Geologia', 'Animales']);
    const [tags2, setTags2] = useState(['Ecologia', 'Animales', 'Animales en extinción', 'Exploración', 'Medio ambiente']);
    const globalState = useContext(store);

    const fetchData = async ({ abortSignal }) => {
        setLoading(true);
        globalState.dispatch({
            type:'CHANGE',
            path:'advancedSearch.loadingContent',
            value:true
        })
        await BusquedaAPI.obtenerBusquedaAvanzada({ abortSignal })
            .then(result => {
                console.log('fetch',result);
                const userGenres = result?.generos;
                const userSubjets = result?.materias;
                const tags = result?.tags;
                const middleTagsIndex = Math.ceil(tags.length / 2);
                const tags1 = _.shuffle(tags.splice(0, middleTagsIndex)).splice(0,5);   
                const tags2 = _.shuffle(tags.splice(-middleTagsIndex)).splice(0,10);
                const DATA = [...ADVANCED_SEARCH_ITEMS_DATA];
                const filteredGenres = [...ADVANCED_SEARCH_ITEMS_DATA.filter(item => item?.type === 'genero' && userGenres.includes(item?.id))];
                const filteredSubjects = [...ADVANCED_SEARCH_ITEMS_DATA.filter(item => item?.type === 'tema' && userSubjets.includes(item?.id))]; 
                const _mostSearched = [...filteredGenres, ...filteredSubjects].filter(item => item?.mostSearched).sort((a, b) => a?.position - b?.position);
                const _readingLevel = DATA.filter(item => item?.type === 'nivel');
                const _genres = [...filteredGenres].filter(item => item?.type === 'genero' && !item?.mostSearched);
                const _subjects = [...filteredSubjects].filter(item => item?.type === 'tema' && !item?.mostSearched);
                setMostSearched(_mostSearched);
                setReadingLevel(_readingLevel);
                setGenres(_genres);
                setSubjects(_subjects);
                setTags1(tags1);
                setTags2(tags2);
            })
            .catch(error => console.log(error))
            .finally(() => {
                globalState.dispatch({
                    type:'CHANGE',
                    path:'advancedSearch.loadingContent',
                    value:false
                })
            })
    }
    useEffect(() => {
        let abortController = new CancelRequestController();
        fetchData({abortSignal:abortController.signal})
       return ()=>{
        abortController.abort();
       }
    }, [])


    return {
        mostSearched,
        genres,
        subjects,
        readingLevel,
        tags1,
        tags2
    };
}