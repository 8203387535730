import React, { createContext } from "react";
import { useEffect } from "react";
import { useState } from "react";

/**
 * 
Es un objeto en el cual se define los temas que tiene actualmente MakeMake (PWA), el objeto debe ser usado como referencia y no para modificarse en el ciclo de la aplicación (tipo constante).
 */
export const TemasDisponibles = {
    clasico:{
        id:'clasico',
        nombre:'Clasico'
    },
    altoContraste:{
        id:'alto-contraste',
        nombre:'Alto contraste'
    }
}

/**
 * Es un objeto en el cual se define los tipos de fuentes que tiene actualmente MakeMake (PWA), el objeto debe ser usado como referencia y no para modificarse en el ciclo de la aplicación (tipo constante).
 */
export const FuentesDisponibles = {
    predeterminado:{
        id:'predeterminado',
        nombre:'Predeterminado'
    },
    arial:{
        id:'arial',
        nombre:'Arial'
    },
    timesNewRoman:{
        id:'times-new-roman',
        nombre:'Times New Roman'
    },
    dyslexic:{
        id:'dyslexic',
        nombre:'Dyslexic'
    },
}

const ConfigPorDefecto = ({
    temaActual: TemasDisponibles.clasico.id,
    tamanioFuente: 20,
    fuente: FuentesDisponibles.predeterminado.id,
})

/**
 * Se usa en toda la aplicación a través del hook de react 'useContext', permite modificar y obtener el estado actual de el contexto de la configuración de accesibilidad.
 */
export const AccesibilidadContext = createContext({
    temaActual: '',
    tamanioFuente: 0,
    fuente: '',
    actualizarTema: (tema) => { },
    actualizarTamanioFuente: (tamanio) => { },
    actualizarFuente: (nombreFuente) => { },
})

/**
Es el proveedor del contexto 'AccesibilidadContext' cualquier componente que este dentro del proveedor tendrá acceso al contexto y a sus cambios de estado, asi como tambíen podrá modificar su estado con las funciones que ofrece el contexto (actualizarTema, actualizarTamanioFuente, actualizarFuente).
 */
export const AccesibilidadProvider = ({ children }) => {
    const [temaActual, setTemaActual] = useState(ConfigPorDefecto.temaActual);
    const [tamanioFuente, setTamanioFuente] = useState(ConfigPorDefecto.tamanioFuente);
    const [fuente, setFuente] = useState(ConfigPorDefecto.fuente);

    const actualizarFuente = (nombreFuente) => {
        setFuente(nombreFuente);
        document.body.dataset.font = nombreFuente || '';
    }
    const actualizarTema = (tema) => {
        setTemaActual(tema);
        document.body.dataset.theme = tema;
    }

    const actualizarTamanioFuente = (tamanio) => {
        document.body.classList.forEach(c=>{
            if(c.includes('fontSize')){
              document.body.classList.remove(c);
            }
          })
          const size = `fontSize${tamanio}`
          document.body.classList.add(size);
        setTamanioFuente(tamanio);
    }

    const guardarLocalmente = () => {
        localStorage.setItem('accesibilidadConfig', JSON.stringify({
            temaActual,
            tamanioFuente,
            fuente
        }))
    }

    const cargarConfiguracionLocal = () => {
        try {
            const localConfig = localStorage.getItem('accesibilidadConfig') ? JSON.parse(localStorage.getItem('accesibilidadConfig')) : false;
            if (localConfig) {
                actualizarTema(localConfig?.temaActual || ConfigPorDefecto.temaActual);
                actualizarTamanioFuente(localConfig?.tamanioFuente || ConfigPorDefecto.tamanioFuente);
                actualizarFuente(localConfig?.fuente || ConfigPorDefecto.fuente);
            }else{
                console.log('Cargando configuración de accesibilidad predeterminada.');
            }
        } catch (error) {
            console.log('Error cargando configuración de accesibilidad', error);
        }

    }

    useEffect(() => {
        cargarConfiguracionLocal();
    }, [])

    useEffect(() => {
        guardarLocalmente();
    }, [temaActual, tamanioFuente, fuente])

    return (
        <AccesibilidadContext.Provider value={{
            temaActual,
            tamanioFuente,
            fuente,
            actualizarFuente,
            actualizarTamanioFuente,
            actualizarTema,
        }}>
            <>
                {children}
            </>
        </AccesibilidadContext.Provider>
    )
}