/**
 * Arreglo de objetos con los planes de lectura que se ofrecen en la tienda de MakeMake (Lectura Makemake)
 */
export const LECTURA_MAKEMAKE_DATA = [
    {
        id: 'convivencia-bienestar',
        nombre: {
            default: 'Convivencia y bienestar',
            translationPath: 'individual.lecturas_makemake.convivencia_bienestar.titulo'
        },
        descripcion: {
            default: '20 lecturas seleccionadas por profesionales para trabajar la diversidad, el conflicto, los cambios, las emociones y las diferencias con chicos entre los 3 y 10 años de edad. Incluye detalladas guías de lectura por cada libro.',
            translationPath: 'individual.lecturas_makemake.convivencia_bienestar.descripcion'
        },
        pdfUrl: '/document/planes-lectura/plan-lectura-2020-convivencia-bienestar.pdf',
        libros:[
            {
                "idlibro": "MM0369",
                "title": "Cuando el mundo era así",
                "portada":"https://makemake.com.co/fichas/MM0369/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM0369/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM0369/cuando-el-mundo-era-asi"
            },
            {
                "idlibro": "MM0372",
                "title": "¡No se aburra!",
                "portada":"https://makemake.com.co/fichas/MM0372/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM0372/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM0372/no-se-aburra"
            },
            {
                "idlibro": "MM0353",
                "title": "Mi primer Atlas",
                "portada":"https://makemake.com.co/fichas/MM0353/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM0353/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM0353/mi-primer-atlas"
            },
            {
                "idlibro": "MM1062",
                "title": "El baño no fue siempre así",
                "portada":"https://makemake.com.co/fichas/MM1062/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM1062/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM1062/el-bano-no-fue-siempre-asi"
            },
            {
                "idlibro": "MM0061",
                "title": "Eloísa y los bichos",
                "portada":"https://makemake.com.co/fichas/MM0061/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM0061/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM0061/eloisa-y-los-bichos"
            },
            {
                "idlibro": "MM1767",
                "title": "Griten a los cuatro vientos: los niños tienen derechos",
                "portada":"https://makemake.com.co/fichas/MM1767/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM1767/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM1767/griten-a-los-cuatro-vientos-los-ninos-tienen-derechos"
            },
            {
                "idlibro": "MM0375",
                "title": "Ecología hasta en la sopa",
                "portada":"https://makemake.com.co/fichas/MM0375/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM0375/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM0375/ecologia-hasta-en-la-sopa"
            },
            {
                "idlibro": "MM0635",
                "title": "Mi papá estuvo en la selva",
                "portada":"https://makemake.com.co/fichas/MM0635/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM0635/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM0635/mi-papa-estuvo-en-la-selva"
            },
            {
                "idlibro": "MM0402",
                "title": "Mi cuaderno de Haikus",
                "portada":"https://makemake.com.co/fichas/MM0402/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM0402/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM0402/mi-cuaderno-de-haikus"
            },
            {
                "idlibro": "MM0636",
                "title": "Pequeñas historias de grandes pintores",
                "portada":"https://makemake.com.co/fichas/MM0636/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM0636/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM0636/pequenas-historias-de-grandes-pintores"
            },
            {
                "idlibro": "MM0391",
                "title": "Auxilio, Socorro. Historia de un malentendido",
                "portada":"https://makemake.com.co/fichas/MM0391/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM0391/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM0391/auxilio-socorro-historia-de-un-malentendido"
            },
            {
                "idlibro": "MM1798",
                "title": "Puro Pelo. Más conectados, menos comunicados",
                "portada":"https://makemake.com.co/fichas/MM1798/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM1798/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM1798/puro-pelo-mas-conectados-menos-comunicados"
            },
            {
                "idlibro": "MM0211",
                "title": "La otra orilla",
                "portada":"https://makemake.com.co/fichas/MM0211/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM0211/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM0211/la-otra-orilla"
            },
            {
                "idlibro": "MM1987",
                "title": "Hola, salut, ciao, hello",
                "portada":"https://makemake.com.co/fichas/MM1987/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM1987/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM1987/hola-salut-ciao-hello"
            },
            {
                "idlibro": "MM0025",
                "title": "Circo Piccadilly",
                "portada":"https://makemake.com.co/fichas/MM0025/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM0025/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM0025/circo-piccadilly"
            },
            {
                "idlibro": "MM0399",
                "title": "La tortuga gigante",
                "portada":"https://makemake.com.co/fichas/MM0399/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM0399/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM0399/la-tortuga-gigante"
            },
            {
                "idlibro": "MM0165",
                "title": "Historias para leer en los labios del silencio",
                "portada":"https://makemake.com.co/fichas/MM0165/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM0165/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM0165/historias-para-leer-en-los-labios-del-silencio"
            },
            {
                "idlibro": "MM0575",
                "title": "Rosa tengo miedo...",
                "portada":"https://makemake.com.co/fichas/MM0575/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM0575/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM0575/rosa-tengo-miedo"
            },
            {
                "idlibro": "MM0539",
                "title": "Pequeña historia de un desacuerdo. Ciudadanía para niños",
                "portada":"https://makemake.com.co/fichas/MM0539/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM0539/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM0539/pequena-historia-de-un-desacuerdo-ciudadania-para-ninos"
            },
            {
                "idlibro": "MM0644",
                "title": "La gallina y el monstruo",
                "portada":"https://makemake.com.co/fichas/MM0644/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM0644/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM0644/la-gallina-y-el-monstruo"
            }
        ],
        epayco: {
            nombre:'Lecturas MakeMake - Convivencia y bienestar',
            descripcion:'Lecturas MakeMake - Convivencia y bienestar',
            precio: {
                usd: 27.99,
                cop: 126500
            },
            descuento:{
                activado:false,
                usd:{
                    cantidad:'10%',
                    precio:25.19
                },
                cop:{
                    cantidad:'10%',
                    precio:113850
                }
            },
        }
    },
    {
        id: 'exploracion-conocimiento',
        nombre: {
            default: 'Exploración y conocimiento',
            translationPath: 'individual.lecturas_makemake.exploracion_conocimiento.titulo'
        },
        descripcion: {
            default: '20 lecturas seleccionadas por profesionales para trabajar la curiosidad, los viajes, las tradiciones orales, la historia y los cambios con chicos entre los 3 y 10 años de edad. Incluye detalladas guías de lectura por cada libro.',
            translationPath: 'individual.lecturas_makemake.exploracion_conocimiento.descripcion'
        },
        pdfUrl: '/document/planes-lectura/plan-lectura-2021-exploracion-conocimiento.pdf',
        libros:[
            {
                "idlibro": "MM0811",
                "title": "¡Qué hambre la del hombrecito!",
                "portada":"https://makemake.com.co/fichas/MM0811/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM0811/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM0811/que-hambre-la-del-hombrecito"
            },
            {
                "idlibro": "MM0989",
                "title": "Termitas",
                "portada":"https://makemake.com.co/fichas/MM0989/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM0989/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM0989/termitas"
            },
            {
                "idlibro": "MM0112",
                "title": "Mil orejas",
                "portada":"https://makemake.com.co/fichas/MM0112/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM0112/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM0112/mil-orejas"
            },
            {
                "idlibro": "MM0408",
                "title": "Vamos por partes",
                "portada":"https://makemake.com.co/fichas/MM0408/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM0408/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM0408/vamos-por-partes"
            },
            {
                "idlibro": "MM0938",
                "title": "Palo Palito Eh",
                "portada":"https://makemake.com.co/fichas/MM0938/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM0938/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM0938/palo-palito-eh"
            },
            {
                "idlibro": "MM1026",
                "title": "La cucharita, historia de una canción",
                "portada":"https://makemake.com.co/fichas/MM1026/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM1026/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM1026/la-cucharita-historia-de-una-cancion"
            },
            {
                "idlibro": "MM0894",
                "title": "Te cuento del camino lo que vi",
                "portada":"https://makemake.com.co/fichas/MM0894/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM0894/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM0894/te-cuento-del-camino-lo-que-vi"
            },
            {
                "idlibro": "MM0899",
                "title": "Las cavernarias y el templo escondido",
                "portada":"https://makemake.com.co/fichas/MM0899/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM0899/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM0899/las-cavernarias-y-el-templo-escondido"
            },
            {
                "idlibro": "MM0429",
                "title": "Insectopedia",
                "portada":"https://makemake.com.co/fichas/MM0429/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM0429/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM0429/insectopedia"
            },
            {
                "idlibro": "MM0858",
                "title": "¡Ayúdame si puedes!",
                "portada":"https://makemake.com.co/fichas/MM0858/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM0858/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM0858/ayudame-si-puedes"
            },
            {
                "idlibro": "MM0516",
                "title": "La madre que llovía",
                "portada":"https://makemake.com.co/fichas/MM0516/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM0516/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM0516/la-madre-que-llovia"
            },
            {
                "idlibro": "MM1052",
                "title": "Una llegada extraordinaria",
                "portada":"https://makemake.com.co/fichas/MM1052/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM1052/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM1052/una-llegada-extraordinaria"
            },
            {
                "idlibro": "MM0182",
                "title": "Corazón de león",
                "portada":"https://makemake.com.co/fichas/MM0182/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM0182/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM0182/corazon-de-leon"
            },
            {
                "idlibro": "MM1077",
                "title": "Los direfentes",
                "portada":"https://makemake.com.co/fichas/MM1077/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM1077/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM1077/los-direfentes"
            },
            {
                "idlibro": "MM0063",
                "title": "En mis sueños",
                "portada":"https://makemake.com.co/fichas/MM0063/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM0063/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM0063/en-mis-suenos"
            },
            {
                "idlibro": "MM0499",
                "title": "Las visiones fantásticas",
                "portada":"https://makemake.com.co/fichas/MM0499/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM0499/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM0499/las-visiones-fantasticas"
            },
            {
                "idlibro": "MM0406",
                "title": "Un diamante en el fondo de la tierra",
                "portada":"https://makemake.com.co/fichas/MM0406/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM0406/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM0406/un-diamante-en-el-fondo-de-la-tierra"
            },
            {
                "idlibro": "MM1296",
                "title": "Todos se burlan",
                "portada":"https://makemake.com.co/fichas/MM1296/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM1296/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM1296/todos-se-burlan"
            },
            {
                "idlibro": "MM0838",
                "title": "Alexander von Humboldt, un explorador científico en América",
                "portada":"https://makemake.com.co/fichas/MM0838/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM0838/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM0838/alexander-von-humboldt-un-explorador-cientifico-en-america"
            },
            {
                "idlibro": "MM1297",
                "title": "El viaje de los elefantes",
                "portada":"https://makemake.com.co/fichas/MM1297/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM1297/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM1297/el-viaje-de-los-elefantes"
            }
        ],
        epayco: {
            nombre:'Lecturas MakeMake - Exploración y conocimiento',
            descripcion:'Lecturas MakeMake - Exploración y conocimiento',
            precio: {
                usd: 27.99,
                cop: 126500
            },
            descuento:{
                activado:false,
                usd:{
                    cantidad:'10%',
                    precio:25.19
                },
                cop:{
                    cantidad:'10%',
                    precio:113850
                }
            },
        }
    },
    {
        id: 'sociedad-identidad',
        nombre: {
            default: 'Sociedad e identidad',
            translationPath: 'individual.lecturas_makemake.sociedad_identidad.titulo'
        },
        descripcion: {
            default: '20 lecturas seleccionadas por profesionales para trabajar la identidad, la inclusión, los territorios, las relaciones sociales, los conflictos y las migraciones con chicos entre los 3 y 10 años de edad. Incluye detalladas guías de lectura por cada libro.',
            translationPath: 'individual.lecturas_makemake.sociedad_identidad.descripcion'
        },
        pdfUrl: '/document/planes-lectura/plan-lectura-2022-sociedad-identidad.pdf',
        libros:[
            {
                "idlibro": "MM1355",
                "title": "Una bolita plateada",
                "portada":"https://makemake.com.co/fichas/MM1355/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM1355/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM1355/una-bolita-plateada"
            },
            {
                "idlibro": "MM1603",
                "title": "Mamá adivina",
                "portada":"https://makemake.com.co/fichas/MM1603/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM1603/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM1603/mama-adivina"
            },
            {
                "idlibro": "MM0924",
                "title": "Los mil años de Pepe Corcueña",
                "portada":"https://makemake.com.co/fichas/MM0924/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM0924/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM0924/los-mil-anos-de-pepe-corcuena"
            },
            {
                "idlibro": "MM1042",
                "title": "Decisión para valientes",
                "portada":"https://makemake.com.co/fichas/MM1042/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM1042/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM1042/decision-para-valientes"
            },
            {
                "idlibro": "MM1581",
                "title": "Un mundo nuevo",
                "portada":"https://makemake.com.co/fichas/MM1581/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM1581/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM1581/un-mundo-nuevo"
            },
            {
                "idlibro": "MM1628",
                "title": "¡Ya crecí!",
                "portada":"https://makemake.com.co/fichas/MM1628/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM1628/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM1628/ya-creci"
            },
            {
                "idlibro": "MM0065",
                "title": "Érase una vez un espacio",
                "portada":"https://makemake.com.co/fichas/MM0065/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM0065/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM0065/erase-una-vez-un-espacio"
            },
            {
                "idlibro": "MM1459",
                "title": "Yo, activista",
                "portada":"https://makemake.com.co/fichas/MM1459/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM1459/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM1459/yo-activista"
            },
            {
                "idlibro": "MM0426",
                "title": "Mi pequeña fábrica de cuentos",
                "portada":"https://makemake.com.co/fichas/MM0426/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM0426/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM0426/mi-pequena-fabrica-de-cuentos"
            },
            {
                "idlibro": "MM2163",
                "title": "Lluvia de verbos",
                "portada":"https://makemake.com.co/fichas/MM2163/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM2163/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM2163/lluvia-de-verbos"
            },
            {
                "idlibro": "MM1465",
                "title": "Marina, la sepulturera de algas",
                "portada":"https://makemake.com.co/fichas/MM1465/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM1465/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM1465/marina-la-sepulturera-de-algas"
            },
            {
                "idlibro": "MM1656",
                "title": "Somos los bichitos",
                "portada":"https://makemake.com.co/fichas/MM1656/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM1656/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM1656/somos-los-bichitos"
            },
            {
                "idlibro": "MM0645",
                "title": "Antonia va al río",
                "portada":"https://makemake.com.co/fichas/MM0645/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM0645/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM0645/antonia-va-al-rio"
            },
            {
                "idlibro": "MM1432",
                "title": "Un violín migrante",
                "portada":"https://makemake.com.co/fichas/MM1432/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM1432/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM1432/un-violin-migrante"
            },
            {
                "idlibro": "MM0133",
                "title": "Rumbo al Planeta J",
                "portada":"https://makemake.com.co/fichas/MM0133/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM0133/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM0133/rumbo-al-planeta-j"
            },
            {
                "idlibro": "MM1358",
                "title": "A-L-X El nuevo vecino",
                "portada":"https://makemake.com.co/fichas/MM1358/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM1358/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM1358/a-l-x-el-nuevo-vecino"
            },
            {
                "idlibro": "MM0515",
                "title": "¿Mi vecino es un monstruo?",
                "portada":"https://makemake.com.co/fichas/MM0515/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM0515/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM0515/mi-vecino-es-un-monstruo"
            },
            {
                "idlibro": "MM1375",
                "title": "Las cinco cuadras",
                "portada":"https://makemake.com.co/fichas/MM1375/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM1375/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM1375/las-cinco-cuadras"
            },
            {
                "idlibro": "MM1053",
                "title": "El restaurante siniestro",
                "portada":"https://makemake.com.co/fichas/MM1053/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM1053/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM1053/el-restaurante-siniestro"
            },
            {
                "idlibro": "MM1614",
                "title": "¡Yum! La comida en todos los sentidos",
                "portada":"https://makemake.com.co/fichas/MM1614/art/portadaOptim.jpg",
                "portadathumb":"https://makemake.com.co/fichas/MM1614/art/portadaThumb.jpg",
                "webUrl": "/fichas/MM1614/yum-la-comida-en-todos-los-sentidos"
            }
        ],
        epayco: {
            nombre:'Lecturas MakeMake - Sociedad e identidad',
            descripcion:'Lecturas MakeMake - Sociedad e identidad',
            precio: {
                usd: 27.99,
                cop: 126500
            },
            descuento:{
                activado:false,
                usd:{
                    cantidad:'10%',
                    precio:25.19
                },
                cop:{
                    cantidad:'10%',
                    precio:113850
                }
            },
        }
    },
]