import React, { useEffect, useState } from "react";
import { BaseModal } from "./base-modal"
import useObtenerSubsecciones from "../../js/translations/useObtenerSubsecciones";
import { Icon } from "@iconify/react";
import normalize from "../../js/utilities/normalizeString";

/**
Componente Modal que muestra el video tutorial del tipo de libro
@param {object} props - Propiedades del componente
@param {string} props.visible - Indica si se debe mostrar o no el modal
@param {function} props.setVisible - Función la cual actualiza el estado de visibilidad
@param {string} props.typeBook - Indica el tipo de libro
@returns {JSX.Element} Elemento JSX que representa el componente modal
*/
export const BookTypeModal = ({ visible, setVisible, typeBook }) => {
  const { isReady, textosSubSecciones: visor } =
    useObtenerSubsecciones("visor");
  const [video, setVideo] = useState('libros_interactivos');

  //Cambia el indicador del tipo de video
  useEffect(() => {
    const type = normalize(typeBook);
    if (type) {
      if (type === "epub") {
        setVideo('libros_epub')
      } else if (type === "pasapaginas") {
        setVideo('libros_graficos')
      } else if (type === "comic") {
        setVideo('libros_comic')
      } else {
        setVideo('libros_interactivos')
      }
    }
  }, [typeBook])

  return (
    <>
      {isReady &&
        <BaseModal
          windowClassName={'default-modal-window moda_tipo_visor'}
          visible={visible}
          setVisible={setVisible}
          backdropClose={true}
        >
          <div className="d-flex justify-content-end">
            <button
              aria-label="Cerrar ventana de dialogo"
              className="close-button onbackground-primary--color focusable-red"
              onClick={() => setVisible(false)}
            >
              <Icon icon={'mdi:close-thick'} width={35} />
            </button>
          </div>


          <h2 className="font-quicksand font-weight-bold onbackground-third--color text-center mb-3">
            {visor?.tipo_libros?.[video]?.titulo || 'titulo modal'}
          </h2>

          <iframe
            src={visor?.tipo_libros?.[video]?.url}
            title={visor?.tipo_libros?.[video]?.nombre}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; picture-in-picture; fullscreen" allowFullScreen
            className='focusable-secondary videoInstruccion'
          ></iframe>
        </BaseModal>
      }
    </>
  )
}