import React, { useRef } from "react";
import { NavigationButtons } from "./navigation-buttons";
import { Icon } from "@iconify/react";

/**
 * Componente de fila de la página de inicio.
 * @param {Object} props - Props del componente.
 * @param {React.Element} props.titleElement - Elemento JSX que representa el título de la fila.
 * @param {React.Element[]} props.children - Elementos JSX hijos de la fila.
 * @param {Object} props.containerProps - Props adicionales para el contenedor de la fila.
 * @returns {JSX.Element} Elemento JSX que representa la fila de la página de inicio.
 */
export const HomeRow = ({ titleElement, children, containerProps }) => {
    const prevButtonRef = useRef(null);
    const nextButtonRef = useRef(null);
    const childrenWithProps = React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {
                navigationButtons: {
                    prevButtonRef,
                    nextButtonRef,
                }
            });
        }
    })

    return (
        <div
            {...containerProps}
            className={`home-row w-full px-2 py-4 ${containerProps?.className && containerProps?.className }`}
            >
            <div className="d-flex flex-column flex-md-row flex-wrap justify-content-center w-100 justify-content-md-between align-items-md-center pl-4 my-3">
                {titleElement}
                <NavigationButtons nextButtonRef={nextButtonRef} prevButtonRef={prevButtonRef} />
            </div>
            <div className="">
                {childrenWithProps}
            </div>
        </div>
    )
}

/**
 * Componente de título de fila genérico de la página de inicio.
 * @param {Object} props - Props del componente.
 * @param {string} props.text - Texto del título.
 * @returns {JSX.Element} Elemento JSX que representa el título de fila genérico de la página de inicio.
 */
export const GenericHomeRowTitle = ({text})=>{
    return <h3 className="onbackground-primary--color d-flex justify-content-md-center align-items-md-center" style={{fontWeight:'bold'}}>
        <Icon icon="solar:star-bold" className="mr-2" width={30}/>
        {text}
    </h3>

}