import React from "react";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { Titles } from "../../../components/Titles";
import { StaticBagde } from "../../components/badge";

/**
 * Componente de titulo principal para que es bibliotecas
 * @returns {JSX.Element} Componente TitlePage.
 */
export const TitlePage = () => {
	const lang = useDefaultLangByCountry({ country: "us", defaultLang: "en" });
	const textosBibliotecas = useTranslations({ section: "que_es_biblioteca", lang: lang });

	return (
		<>
			<StaticBagde />
			<Titles
				hexBackground="#4A90E2"
				color="onsecondary--color"
				title={textosBibliotecas?.titulo?.texto || "Bibliotecas"}
			/>
		</>
	);
};
